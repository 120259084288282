/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, createReducer, on } from '@ngrx/store';
import {
    INetwork,
    INetworkDevice,
    INetworkListResponse,
} from '@shared/interfaces';
import * as NetworkActions from './network.actions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INetworkState {
    device_list: INetworkDevice[];
    network_list: INetwork[];
    active_wifi_network: INetwork | {};
}

export const networkInitialState: INetworkState | INetworkListResponse = null;

const reducer = createReducer(
    networkInitialState,
    on(NetworkActions.refreshNetworkStatus, (state, payload) => ({
        ...state,
        device_list: [...payload.device_list],
        active_wifi_network: { ...payload.active_wifi_network },
    })),
    on(NetworkActions.refreshNetworkList, (state, payload) => ({
        ...state,
        device_list: [...payload.device_list],
        network_list: [...payload.network_list],
    }))
);
// eslint-disable-next-line @typescript-eslint/ban-types
export function NetworkReducer(
    state: INetworkState | undefined,
    action: Action
): INetworkState {
    return reducer(state, action);
}
