import { IVideoCall, IVideoCallSharingDevice } from '@/shared/interfaces';

export const TEST_SHARING_DEVICE_DATA: IVideoCallSharingDevice = {
    deviceId: 'test device id',
    title: '',
    hasVideo: false,
    hasAudio: false,
    isStethoscope: false,
};
export const TEST_VIDEO_DATA: IVideoCall = {
    allowSnapshot: false,
    allowStethRecording: false,
    isSharing: false,
    isRecording: false,
    takeSnapshot: false,
    sharingDevice: TEST_SHARING_DEVICE_DATA,
};
