import { Component, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IBatteryStatus } from '@shared/interfaces';
import { selectBatteryState } from '@/shared/storage/selectors/battery.selectors';

@Component({
    selector: 'battery-manager',
    templateUrl: './battery-manager.component.html',
})
export class BatteryManagerComponent implements OnDestroy {
    @Input() showPercentage = true;
    @Input() showBatteryIcon = true;
    public battery: IBatteryStatus;
    public isConnented = false;
    public pathClassRequired = false;
    public batteryClass = 'icon icon-ic_battery';

    private _subscriptions: Subscription[] = [];

    constructor(private _store: Store) {
        this.updateBatteryPercentage();
    }

    updateBatteryPercentage(): void {
        this._subscriptions.push(
            this._store
                .select(selectBatteryState)
                .subscribe((batteryState: IBatteryStatus) => {
                    this.battery = batteryState;
                    this.updateBatteryClass();
                })
        );
    }

    updateBatteryClass() {
        let batteryClass = 'icon icon-ic_battery';
        if (this.battery) {
            if (this.battery.acPowerConnected) {
                batteryClass += '_charging';
            }
            const roundedLvl =
                Math.round(Math.round(this.battery.level) / 5) * 5;

            if (roundedLvl === 100) {
                batteryClass += '_full';
            } else {
                batteryClass += `_${roundedLvl}`;
            }

            batteryClass += '-24px';

            if (roundedLvl < 100) {
                batteryClass += ' layered';
                this.pathClassRequired = true;
            }
        }
        this.batteryClass = batteryClass;
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) =>
            subscription.unsubscribe()
        );
    }
}
