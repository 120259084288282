import { IWaitingRoomState } from '@/shared/interfaces';
import { IFrom } from '@/shared/interfaces/service-line/service-line-detail.interface';
import { IServiceLine } from '@/shared/interfaces/service-line/service-line.interface';
export const TEST_FORMS: IFrom[] = [{}];
export const TEST_SERVICE_LINE_DATA: IServiceLine = {
    appointment_read_only: true,
    code: 'behavioral_health',
    color: 'primary',
    created_at: '1616172303901',
    description: 'Behavioral Health',
    fee: null,
    fk_payment_settings_name: null,
    hide_attachments_clinical_portal: false,
    hide_attachments_patient_portal: false,
    is_ambra_enabled: false,
    is_completed_note_enabled: null,
    is_dischargenotes_enabled: false,
    is_eprescribe_enabled: true,
    is_medical_history_enabled: true,
    is_ocg_assign_to_first: null,
    is_ocg_integration_enabled: false,
    is_ocg_select_provider: null,
    is_patient_link_disabled: false,
    is_pcp_search_enabled: false,
    is_pharmacy_enabled: false,
    is_phone_call_enabled: false,
    is_survey_enabled_for_clinical_portal: false,
    is_survey_enabled_for_patient_portal: false,
    is_video_call_enabled: true,
    is_visit_modality_enabled: true,
    name: 'Behavioral Health Service Line',
    patient_notification_enabled: false,
    service_line_id: 'behavioral_health',
    service_line_type_id: 'behavioral-health',
    settings: null,
    updated_at: '1616172303901',
    walk_in: 'Walk In Available',
    workflow_id: 'behavioral_health',
};

export const TEST_WAITING_ROOM_DATA: IWaitingRoomState = {
    practise: undefined,
    serviceLine: TEST_SERVICE_LINE_DATA,
};
