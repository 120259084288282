import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
    asyncScheduler,
    combineLatest,
    Subscription,
    throwError,
    TimeoutError,
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    distinctUntilKeyChanged,
    filter,
    observeOn,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Queue } from '@/shared/queue/queue';
import { ERROR_MESSAGES } from '@/shared/constants';
import {
    selectConfig,
    selectIsInternetConnected,
    selectIsFleetConnected,
    selectIsAuthenticated,
} from '@/shared/storage/selectors';
import { IEndpointConfiguration } from '@/shared/interfaces';

@Injectable({ providedIn: 'root' })
export class FleetLoggerService implements OnDestroy {
    private _continue = true;
    private _loggerQueue = new Queue();
    private _fleetUrl: string;
    private _prevErr: string;
    private _subscriptions: Subscription[] = [];
    private _isFleetApiOnline: boolean;
    private _isAuthenticated: boolean;

    constructor(private _httpClient: HttpClient, private _store: Store) {
        this._subscriptions.push(
            this._store
                .select(selectConfig)
                .pipe(
                    filter((state) => state !== null),
                    distinctUntilKeyChanged('fleet_url')
                )
                .subscribe((config: IEndpointConfiguration) => {
                    this._fleetUrl = config?.fleet_url;
                })
        );

        this._subscriptions.push(
            combineLatest([
                this._store.select(selectIsAuthenticated),
                this._store.select(selectIsFleetConnected),
            ])
                .pipe(distinctUntilChanged())
                .subscribe(([isAuthenticated, isFleetConnected]) => {
                    this._isAuthenticated = isAuthenticated;
                    this._isFleetApiOnline = isFleetConnected;

                    this._isAuthenticated && this._isFleetApiOnline
                        ? !this._continue && this.startQueue()
                        : this.pauseQueue();
                })
        );
    }

    startQueue(): void {
        this._continue = true;
        this.init();
    }

    pauseQueue(): void {
        this._continue = false;
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((sub) => sub && sub.unsubscribe());
    }

    init(): void {
        if (this._continue) {
            const observable$ = this._loggerQueue.getMessageQueue();
            this._subscriptions.push(
                observable$
                    .pipe(
                        distinctUntilChanged(),
                        filter(() => this._continue),
                        observeOn(asyncScheduler, 3000)
                    )
                    .subscribe((logObject) => {
                        this.sendLogToFleet(logObject);
                    })
            );
        }
    }

    public registerInQueue(
        fleetLogType: string,
        message: string,
        detailedInfo: any
    ): void {
        this._loggerQueue.sendMessageQueue({
            fleetLogType,
            message,
            detailedInfo,
        });
    }

    sendLogToFleet(logObject: any): void {
        if (!logObject) {
            return;
        }
        const { fleetLogType, message: module } = logObject;
        let { detailedInfo: logContent } = logObject;
        logContent = logContent + ' ' + new Date().toLocaleString();
        const details = fleetLogType === 'info' ? fleetLogType : 'details';

        this._httpClient
            .post(
                `${this._fleetUrl}v1/apgar/${fleetLogType}`,
                {
                    module,
                    [details]: logContent,
                },
                {
                    headers: { skip: 'true' },
                }
            )
            .pipe(
                catchError((error) => {
                    // Handle timeout
                    if (error instanceof TimeoutError) {
                        return throwError(ERROR_MESSAGES.TIMEOUT_ERROR);
                    }
                    // Return other errors
                    return throwError(error);
                })
            )
            .subscribe({
                error: (sendLogErr) => {
                    const { statusText, status } = sendLogErr;
                    const stringifiedErr = JSON.stringify(sendLogErr);

                    if (statusText === ERROR_MESSAGES.HTTP_ERROR_NO_INTERNET) {
                        this._subscriptions.push(
                            this._store
                                .select(selectIsInternetConnected)
                                .pipe(distinctUntilChanged())
                                .subscribe((isInternetConnected) => {
                                    this._isAuthenticated &&
                                    isInternetConnected &&
                                    this._isFleetApiOnline
                                        ? this.startQueue()
                                        : this.pauseQueue();
                                })
                        );
                    }

                    if (
                        this._prevErr !== stringifiedErr &&
                        statusText !== ERROR_MESSAGES.TIMEOUT_ERROR &&
                        statusText !== ERROR_MESSAGES.UNKNOWN_ERROR &&
                        status !== 0 &&
                        status !== 401
                    ) {
                        this.registerInQueue(
                            'logs',
                            'logger.sendLog:retry',
                            stringifiedErr
                        );
                    }

                    this._prevErr = stringifiedErr;
                },
            });
    }
}
