import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import {
    selectScreenSaverPin,
    selectSettingsPin,
} from '@/shared/storage/selectors';

@Component({
    selector: 'app-pin-code',
    templateUrl: './pin-code.component.html',
})
export class PinCodeComponent implements OnInit, OnDestroy, AfterViewChecked {
    public showError = false;
    public pinInput = '';
    public pin = [];
    private _configuredPinCode: string;
    private _isAlive = true;
    private _timeoutId = null;

    @ViewChild('keyboardInput', { static: false })
    private _keyboardInput!: ElementRef;

    @Input() isLockScreen: boolean;
    @Output() onSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onErrorPin: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() onFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onBlur: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private _store: Store) {}

    ngOnInit() {
        this.setNumberInput();

        combineLatest([
            this._store.select(selectSettingsPin),
            this._store.select(selectScreenSaverPin),
        ])
            .pipe(takeWhile(() => this._isAlive))
            .subscribe(([settingsPin, lockScreenPin]) => {
                this._configuredPinCode = this.isLockScreen
                    ? lockScreenPin
                    : settingsPin;
            });
    }

    ngOnDestroy() {
        this._isAlive = false;
        this.closeKeyboard();
    }

    ngAfterViewChecked(): void {
        this.setFocus();
    }

    // TODO: Remove this after aw-keyboard fix (Keyboard will switch to letters after incorrect pin code input)
    setNumberInput() {
        const button: HTMLElement = document.querySelector(
            'button.keyboard-btn-switch-numbers'
        );

        button?.click();
    }

    setFocus(): void {
        setTimeout(() => {
            this._keyboardInput.nativeElement.focus();
        }, 0);
    }

    setBlur(): void {
        setTimeout(() => {
            this._keyboardInput.nativeElement.blur();
            this.closeKeyboard();
        }, 0);
    }

    // TODO: Remove this after aw-keyboard fix (Keyboard doesn't react on blur event)
    closeKeyboard(): void {
        const el = document.createElement('div');
        const body = document.querySelector('body');
        body.appendChild(el);
        el.click();
        body.removeChild(el);
    }

    deleteChar(): void {
        this.pin.pop();
        this.pinInput = '';
    }

    pinInputChanged(): void {
        if (this.pinInput.length === this.pin.length + 1) {
            const inputtedCharacter: number = parseInt(
                this.pinInput[this.pinInput.length - 1],
                10
            );
            if (isNaN(inputtedCharacter)) {
                this.pinInput = this.pinInput.slice(0, -1);
                return;
            }
            if (this.pinInput.length) {
                this.validatePin(inputtedCharacter);
            }
        } else if (this.pinInput.length === this.pin.length - 1) {
            this.pin.pop();
        }
    }

    validatePin(pinSymbol: number): void {
        if (pinSymbol >= 0 && pinSymbol <= 9) {
            this.pin.push(pinSymbol);
            if (this.pin.length >= 4) {
                if (this.pin.join('') === this._configuredPinCode) {
                    this.pin = [];
                    this.setBlur();
                    this.onSuccess.emit(true);
                } else {
                    this.pin = [];
                    this.showError = true;
                    this.onErrorPin.emit(false);
                }
                this.pinInput = '';
            }
        }

        clearTimeout(this._timeoutId);

        this._timeoutId = setTimeout(
            () => {
                this.showError = false;
            },
            5000,
            false
        );
    }
}
