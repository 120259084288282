// Shure Microphone
export const INTMTVKIT_AUDIO_INPUT = {
    label: '14ed:29b6',
    name: 'X2u Adapter Analog Stereo',
};

// Jabra Speaker
export const INTMTVKIT_AUDIO_OUTPUT = {
    label: '0b0e:0412',
    name: 'Jabra SPEAK',
};
