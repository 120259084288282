import { selectDeviceOnScreenKeyboardOpened } from '@/shared/storage/selectors/device-selectors';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-connectivity-tab',
    templateUrl: './connectivity-tab.component.html',
})
export class ConnectivityTabComponent {
    public isKeyboardOpened$ = this._store.select(
        selectDeviceOnScreenKeyboardOpened
    );

    constructor(private _store: Store) {}
}
