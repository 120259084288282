import { AppState } from '@/app.reducer';
import { createSelector } from '@ngrx/store';
import { IAuthState } from '../auth';

export const selectAuthState = (state: AppState): IAuthState => state.auth;

export const selectIsAuthenticated = createSelector(
    selectAuthState,
    (authState: IAuthState) => authState.isAuthenticated
);

export const selectIsWebsocketConnected = createSelector(
    selectAuthState,
    (authState: IAuthState) => authState.isWebsocketConnected
);

export const selectIsInternetConnected = createSelector(
    selectAuthState,
    (authState: IAuthState) => authState.isInternetConnected
);

export const selectConsumer = createSelector(
    selectAuthState,
    (authState: IAuthState) => authState.consumer
);

export const selectProvider = createSelector(
    selectAuthState,
    (authState: IAuthState) => authState.provider
);

export const selectTransferProvider = createSelector(
    selectAuthState,
    (authState: IAuthState) => authState.transferProvider
);

export const selectAuthData = createSelector(
    selectAuthState,
    (authState: IAuthState) => authState.authData || {}
);

export const selectIsFleetConnected = createSelector(
    selectAuthState,
    (authState: IAuthState) => authState?.isFleetConnected
);
