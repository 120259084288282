import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiService } from '@shared/services/api';
import { IRoom } from '@interfaces/device/room.interface';
import { IVideoCallRoom } from '@/shared/interfaces/api/video-call-input.interface';

declare let MediaRecorder: any;

@Injectable({
    providedIn: 'root',
})
export class AttachmentService {
    recordedBlobs: Blob;
    snapshotBlob: Blob;
    caseId = null;
    mediaRecorder = null;

    constructor(private _apiService: ApiService) {}

    clear() {
        delete this.snapshotBlob;
        delete this.recordedBlobs;
    }

    uploadFile(
        room: IRoom,
        fileName: string,
        endpointId: string
    ): Observable<any> {
        let contentType = 'video/mp4';
        let file: Blob[] | Blob = this.recordedBlobs;
        let contentSize = 0;
        let isStethRecording = true;
        if (this.snapshotBlob) {
            contentType = 'image/png';
            file = this.snapshotBlob;
            contentSize = this.snapshotBlob.size;
            isStethRecording = false;
        } else {
            contentSize = this.recordedBlobs.size;
        }

        return this._apiService
            .requestUpload(room.case_id, {
                content_type: contentType,
                endpointId,
            })
            .pipe(
                switchMap((requestUpload: any) => {
                    const options = {
                        headers: {
                            'Content-Type': contentType,
                        },
                    };
                    this._apiService
                        .putUpload(requestUpload.upload_url, file, options)
                        .subscribe();

                    const data = {
                        file_id: requestUpload.file_id,
                        content_size: contentSize,
                        name: fileName,
                        last_modified: new Date().getTime(),
                        is_steth_recording: isStethRecording,
                    };

                    return this._apiService.uploaded(
                        room.tag,
                        requestUpload.file_id,
                        data
                    );
                })
            );
    }

    startStethRecording(room: IRoom, stream: MediaStream) {
        this.clear();
        this.caseId = room.tag;
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) {
                this.recordedBlobs = event.data;
            }
        };
        this.mediaRecorder.start();
    }

    stopRecording() {
        if (this.mediaRecorder.state === 'recording') {
            this.mediaRecorder.stop();
        }
    }

    getStethRecordingInfo() {
        return {
            size: this.recordedBlobs.size,
            extension: 'mp4',
        };
    }

    getRecordedFileInfo(room: IVideoCallRoom) {
        const fileInfo = this.getStethRecordingInfo();
        const now = new Date();
        const monthDay = [now.getMonth() + 1, now.getDate()]
            .map((item) => (item > 10 ? item : `0${item}`))
            .join('_');
        const fileName = `Case${
            room.caseNumber
        }_Stethoscope_Recording_${monthDay}_${now.getFullYear()}_${now.getHours()}${now.getMinutes()}.${
            fileInfo.extension
        }`;

        const fileSize = this.getFileSizeText(fileInfo.size);

        return {
            fileName,
            fileSize,
        };
    }

    getFileSizeText(size: number) {
        const KB = 1024;
        const MB = 1024 * KB;
        const GB = 1024 * MB;
        let output = size / MB;
        let ext = 'MB';
        if (output < 0.5) {
            output = size / KB;
            ext = 'KB';
        } else if (output > 1000) {
            output = size / GB;
            ext = 'GB';
        }
        return `${output.toFixed(2)} ${ext}`;
    }

    takeSnapshot(
        room: IVideoCallRoom,
        presentationElem: HTMLVideoElement,
        canvasElem: HTMLCanvasElement
    ) {
        this.clear();
        this.caseId = room.tag;
        canvasElem.height = presentationElem.videoHeight;
        canvasElem.width = presentationElem.videoWidth;
        const ctx = canvasElem.getContext('2d');
        ctx.drawImage(presentationElem, 0, 0);
        canvasElem.toBlob((blob: Blob) => {
            this.snapshotBlob = blob;
        });
    }
}
