<div class="modal-content-wrapper">
    <div class="modal-heading no-border">
        <h4 class="flex items-center">
            <i class="material-icons large-material m-r2">power_settings_new</i
            >{{ title }}
        </h4>
    </div>
    <div class="modal-content">
        <p [innerHTML]="body"></p>
    </div>
    <div class="modal-footer py1" *ngIf="showFooter">
        <aw-button (onClick)="close()" styleClasses="btn btn-danger m-l2"
            >Dismiss</aw-button
        >
    </div>
</div>
