import {
    IAuthenticationResponse,
    IConsumerSettings,
} from '@/shared/interfaces';
import { createAction, props } from '@ngrx/store';
const AUTHENTICATE = 'AUTHENTICATE';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAILURE = 'AUTH_FAILURE';
const AUTH_SET_IS_WEBSOCKET_CONNECTED = 'AUTH_SET_IS_WEBSOCKET_CONNECTED';
const AUTH_SET_IS_INTERNET_CONNECTED = 'AUTH_SET_IS_INTERNET_CONNECTED';
const AUTH_SET_IS_FLEET_CONNECTED = 'AUTH_SET_IS_FLEET_CONNECTED';
const AUTH_SET_CONSUMER = 'AUTH_SET_CONSUMER';
const AUTH_SET_PROVIDER = 'AUTH_SET_PROVIDER';
const AUTH_SET_TRANSFER_PROVIDER = 'AUTH_SET_TRANSFER_PROVIDER';

export const authenticate = createAction(AUTHENTICATE);
export const authSuccess = createAction(
    AUTH_SUCCESS,
    props<{ authData: IAuthenticationResponse }>()
);
export const authFailure = createAction(AUTH_FAILURE);
export const authSetIsWebsocketConnected = createAction(
    AUTH_SET_IS_WEBSOCKET_CONNECTED,
    props<{ isWebsocketConnected: boolean }>()
);
export const authSetIsInternetConnected = createAction(
    AUTH_SET_IS_INTERNET_CONNECTED,
    props<{ isInternetConnected: boolean }>()
);
export const authSetConsumer = createAction(
    AUTH_SET_CONSUMER,
    props<{ consumer: IConsumerSettings }>()
);
export const authSetProvider = createAction(
    AUTH_SET_PROVIDER,
    props<{ provider: any }>()
);
export const authSetTransferProvider = createAction(
    AUTH_SET_TRANSFER_PROVIDER,
    props<{ transferProvider: any }>()
);
export const authSetIsFleetConnected = createAction(
    AUTH_SET_IS_FLEET_CONNECTED,
    props<{ isFleetConnected: boolean }>()
);
