import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import { Injectable } from '@angular/core';
import { IIpcResponseHandler } from '../../interfaces';
import { IpcObserverService } from './ipc-observer.service';

@Injectable({ providedIn: 'root' })
export class CameraResponseService implements IIpcResponseHandler {
    constructor(private _observerService: IpcObserverService) {}
    handleResponse(response): void {
        const { video: cameraResponse } = response;

        if (
            cameraResponse.command === SYSTEM_COMMANDS_TYPES.GET_CAMERA_LIST ||
            cameraResponse.command ===
                SYSTEM_COMMANDS_TYPES.GET_CAMERA_CAPABILITIES ||
            cameraResponse.command ===
                SYSTEM_COMMANDS_TYPES.GET_CAMERA_CURRENT_POSITION ||
            cameraResponse.command === SYSTEM_COMMANDS_TYPES.CAMERA_PTZ ||
            cameraResponse.command === SYSTEM_COMMANDS_TYPES.GET_CAMERA_PARAM
        ) {
            this._observerService.notifyChanges({
                command: cameraResponse.command,
                data: cameraResponse,
            });
        } else {
            this._observerService.notifyChanges({
                command: cameraResponse.command,
                data: cameraResponse,
            });
        }
    }
}
