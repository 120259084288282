/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, createReducer, on } from '@ngrx/store';
import * as EndpointConfigActions from './endpoint-config.actions';
import { IEndpointConfiguration } from '@shared/interfaces';

export const endpoinConfigInitialState: IEndpointConfiguration = null;

const reducer = createReducer(
    endpoinConfigInitialState,
    on(EndpointConfigActions.reloadEndpointConfig, (state, payload) => ({
        ...state,
        ...payload.endpointConfig,
    }))
);

// eslint-disable-next-line @typescript-eslint/ban-types
export function EndpointConfigReducer(
    state: IEndpointConfiguration | undefined,
    action: Action
): IEndpointConfiguration {
    return reducer(state, action);
}
