import { INetworkDevice, INetwork } from '@/shared/interfaces';
import { INetworkState } from '@/shared/storage';

export const TEST_NETWORK_DEVICE_LIST: INetworkDevice[] = [
    {
        general: {
            type: 'ETHERNET',
            hw_addr: '44:8E:12:03:A6:36',
            mtu: '1500',
            state: 'CONNECTED',
            connection: 'Wired connection 1',
            connection_path:
                '/org/freedesktop/NetworkManager/ActiveConnection/3',
        },
        ipv4: {
            gateway: '10.43.0.1',
            address: '10.43.80.219/16',
            dns: ['10.43.0.51', '10.42.0.15', '10.42.0.17'],
            domain: ['internal.avizia.com'],
        },
        ipv6: {
            gateway: '',
            address: [''],
        },
    },
    {
        general: {
            type: 'WIFI',
            hw_addr: '9C:DA:3E:8E:81:36',
            mtu: '1500',
            state: 'CONNECTED',
            connection: 'Panther',
            connection_path:
                '/org/freedesktop/NetworkManager/ActiveConnection/2',
        },
        ipv4: {
            gateway: '10.43.0.1',
            address: '10.43.80.151/16',
            dns: ['10.43.0.51', '10.42.0.15', '10.42.0.17'],
            domain: ['internal.avizia.com'],
        },
        ipv6: {
            gateway: '',
            address: [''],
        },
    },
];

export const TEST_NETWORK_LIST: INetwork[] = [
    {
        ssid: 'Amwell',
        ssid_hex: '416D77656C6C',
        bssid: 'E2\\55\\7D\\26\\5F\\51',
        mode: 'Infra',
        channel: 1,
        frequency: 2412,
        rate: 130,
        signal: 44,
        bars: 4,
        security: 'WPA2 802.1X',
        wpa_flags: '(none)',
        rsn_flags: 'pair_ccmp group_ccmp 802.1X',
        device: 'wlp1s0',
        active: false,
        in_use: false,
        dbus_path: '/org/freedesktop/NetworkManager/AccessPoint/199',
        needs_username: true,
        needs_password: true,
        stored: false,
        locked_band: '<BAND_AUTO | BAND_5G | BAND_2G>',
        available_band: 'BAND_AUTO',
    },
];

export const TEST_ACTIVE_WIFI_NETWORK: INetwork | {} = {
    ssid: 'DummyActiveNetwork',
    ssid_hex: '416D77656C6C',
    bssid: 'E2\\55\\7D\\26\\5F\\51',
    mode: 'Infra',
    channel: 1,
    frequency: 2412,
    rate: 130,
    signal: 44,
    bars: 4,
    security: 'WPA2 802.1X',
    wpa_flags: '(none)',
    rsn_flags: 'pair_ccmp group_ccmp 802.1X',
    device: 'wlp1s0',
    active: true,
    in_use: false,
    dbus_path: '/org/freedesktop/NetworkManager/AccessPoint/199',
    needs_username: true,
    needs_password: true,
    stored: true,
    locked_band: '<BAND_AUTO | BAND_5G | BAND_2G>',
    available_band: 'BAND_AUTO',
};

export const TEST_NETWORK_LIST_RESPONSE: INetworkState = {
    device_list: TEST_NETWORK_DEVICE_LIST,
    network_list: TEST_NETWORK_LIST,
    active_wifi_network: TEST_ACTIVE_WIFI_NETWORK,
};
