<div class="modal-content pt3 network-lost-modal center-align">
    <span class="icon icon-network-black layered m-t2">
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
    </span>
    <h3>Please wait</h3>
    <p class="m-b35">Lost network connectivity. Trying to reconnect…</p>
    <aw-spinner spinnerColor="spinner-blue-only" automationValue="spinner"> </aw-spinner>
</div>
