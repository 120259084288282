export enum DeviceActions {
    CameraChanged = 'CAMERA_CHANGED',
    MicrophoneChanged = 'MICROPHONE_CHANGED',
    TakeSnapShot = 'TAKE_SNAPSHOT',
    UploadFile = 'UPLOAD_FILE',
    StartRecording = 'START_RECORDING',
    StopRecording = 'STOP_RECORDING',
    StartVideoShare = 'START_VIDEO_SHARE',
    EndVideoShare = 'END_VIDEO_SHARE',
    SHARE_DEVICE_CHANGED = 'SHARE_DEVICE_CHANGED',
    SHARE_DEVICE_ERROR = 'SHARE_DEVICE_ERROR',
    OnScreenKeyboardChanged = 'ON_SCREEN_KEYBOARD_CHANGED',
}
