<footer class="sticky pt2 pb0">
    <div class="container fullwidth">
        <aw-button
            styleClasses="btn btn-large btn-simple on-gray waves-effect waves-light left"
            automationValue="cancel"
            (click)="cancel()"
            [disabled]="cancelDisabled"
        >
            Cancel
        </aw-button>
        <span class="module-title">
            <span class="icon icon-{{ serviceLineTypeId }}-black" automation="serviceLineTypeId"></span>
            <span class="gray-mid-text" automation="serviceLineName">{{ serviceLineName }}</span>
        </span>
        <aw-button
            *ngIf="!isNextProgressButton"
            styleClasses="btn btn-large waves-effect waves-light right m-l2 pr2"
            [disabled]="nextDisabled"
            automationValue="next"
            (click)="next()"
        >
            Next
            <i class="material-icons m-0">chevron_right</i>
        </aw-button>
        <aw-button
            *ngIf="isNextProgressButton"
            progressive="true"
            styleClasses="right m-l2 btn-large btn-primary"
            (click)="next()"
            automationValue="nextProgress"
            [disabled]="nextDisabled"
        >
            Next
            <i class="material-icons m-0">chevron_right</i>
        </aw-button>
        <aw-button
            styleClasses="btn btn-large btn-simple waves-effect on-gray waves-light right pl2"
            (click)="previous()"
            [disabled]="previousDisabled"
            automationValue="previous"
        >
            <i class="material-icons">chevron_left</i>
            Previous
        </aw-button>
    </div>
</footer>
