<div [formGroup]="dnsSettingsForm">
    <aw-input
        label="DNS 1"
        formControlName="dns1"
        (ngModelChange)="onChangeDNS()"
        styleClasses="js-keyboard-input js-non-capitalize"
    >
    </aw-input>
    <aw-input
        label="DNS 2"
        formControlName="dns2"
        (ngModelChange)="onChangeDNS()"
        styleClasses="js-keyboard-input js-non-capitalize"
    >
    </aw-input>
    <aw-input
        label="DNS 3"
        formControlName="dns3"
        (ngModelChange)="onChangeDNS()"
        styleClasses="js-keyboard-input js-non-capitalize"
    >
    </aw-input>
</div>
