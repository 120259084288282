import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
    INetwork,
    INetworkDevice,
    INetworkListResponse,
    INetworkStatuses,
} from '@shared/interfaces';
import { Observable, of, Subscription } from 'rxjs';
import { IpcService, StorageService } from '@shared/services';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StatisticCollector } from '@services/statistic-collector/statistic-collector.service';
import { catchError, first } from 'rxjs/operators';
import { LoggerService } from '@/shared/services/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import cloneDeep from 'lodash/cloneDeep';
import { IWifiParams } from '@interfaces/settings/wifi-params.interface';
import { SnackbarService } from '@aw-hospital/aw-components-lib/src/services/snackbar';
import { NetworkTypes, StatusTypes } from '@/shared/enums';
import { SettingsModalComponent } from '@/settings/settings-modal/settings-modal.component';
import { snackConfig } from '@/shared/constants/snack-bar';

@Component({
    selector: 'app-connection-settings',
    templateUrl: './connection-settings.component.html',
})
export class ConnectionSettingsComponent implements OnInit, OnDestroy {
    public NetworkTypes = NetworkTypes;
    public currentNetwork: string = NetworkTypes.WIFI;
    public connectedWifi: INetwork;
    public ethernetConnected = true;
    public wifiConnectedCl = '';
    public wireConnectedCl = '';
    public joinAnotherNetworkClicked = false;
    public selectedWifi: INetwork;
    public encryption = '';
    public hiddenSSID = '';
    public networkManager: INetworkListResponse;
    public refreshInProgress = false;
    public storedConnectionSettings = {};

    public items = {
        wifi: [],
        ethernet: [],
    };
    public ethernetStatus: INetworkDevice;
    public ethernetStatuses: INetworkStatuses = {
        ipv4Address: '',
        ipv6Address: '',
        ipv4Dns: '',
    };

    public wifiStatus: INetworkDevice;
    public hostName = '';
    public updateHostNameStatus = '';
    public lockedBand = '';

    public wifiParams: IWifiParams = {
        joinWifiStatus: '',
        joinEthernetStatus: '',
        wifiAdresess: '',
    };

    private _subscriptions: Subscription[] = [];
    private _isOnlineSub: Subscription;
    component: {};

    constructor(
        private _storageService: StorageService,
        private _snackbarService: SnackbarService,
        private _ipcService: IpcService,
        private _router: Router,
        private _statisticCollector: StatisticCollector,
        private _loggerService: LoggerService,
        private _cdr: ChangeDetectorRef,
        public dialogRefSettingModal: MatDialogRef<SettingsModalComponent>
    ) {}

    ngOnInit(): void {
        this._subscriptions.push(
            this._storageService.subscribeOnNetworkChange(
                (response: INetworkListResponse) => {
                    this.processWifiList(cloneDeep(response));
                }
            )
        );
    }

    ngOnDestroy(): void {
        if (this._isOnlineSub) {
            this._isOnlineSub.unsubscribe();
        }
        this._subscriptions.forEach(
            (subscription: Subscription) =>
                subscription && subscription.unsubscribe()
        );
    }

    onNetworkClick(activeTab: string): void {
        this.currentNetwork = activeTab;
        // when changing selected network type
        // close any open 'join via static IP' form
        // and reset state of 'join another network'
        this.joinAnotherNetworkClicked = false;

        switch (activeTab) {
            case NetworkTypes.WIFI:
                this._statisticCollector.saveEvent(
                    'No Network',
                    'Select Wi-Fi',
                    'Select Wi-Fi via button Wi-Fi'
                );
                break;
            case NetworkTypes.ETHERNET:
                this._statisticCollector.saveEvent(
                    'No Network',
                    'Select Wired',
                    'Select Wired via button Wired'
                );
                break;
            case NetworkTypes.CUSTOM:
                this._statisticCollector.saveEvent(
                    'No Network',
                    'Select My device',
                    'Select My device via button My device'
                );
                break;
            default:
                break;
        }
    }

    processWifiList(result): void {
        if (result && result.device_list) {
            this.networkManager = result;
            if (
                this.networkManager.network_list &&
                this.networkManager.network_list.length
            ) {
                // Show active network at the top, then alphabetically sort by ssid
                this.networkManager.network_list
                    .slice()
                    .sort((a, b) =>
                        a.active < b.active
                            ? 1
                            : a.active === b.active
                            ? a.ssid > b.ssid
                                ? 1
                                : -1
                            : -1
                    );
                this.items.wifi = this.networkManager.network_list;
                // just for check
                // this.networkManager.network_list[0].active = true;
                //
                this.connectedWifi = this.networkManager.network_list.find(
                    (item) => item.active === true
                );

                const wifiList = !!this.connectedWifi
                    ? this.connectedWifi
                    : { ...this.networkManager.network_list[0] };
                this.selectedWifi =
                    this.selectedWifi && this.selectedWifi.ssid
                        ? {
                              ...this.networkManager?.network_list.find(
                                  (nList) =>
                                      nList.ssid == this.selectedWifi.ssid
                              ),
                          }
                        : { ...wifiList };
            }

            this.wifiStatus = this.networkManager.device_list.find((device) => {
                return device.general.type === NetworkTypes.WIFI;
            });
            this.ethernetStatus = this.networkManager.device_list.find(
                (device) => {
                    return (
                        device.general.type === NetworkTypes.ETHERNET &&
                        device.general.connection === 'netplan-eth0'
                    );
                }
            );
            this._cdr.detectChanges();
            if (this.ethernetStatus) {
                this.ethernetStatuses.ipv4Address =
                    this.ethernetStatus.ipv4.address;
                this.ethernetStatuses.ipv6Address =
                    this.ethernetStatus.ipv6.address.join();
                this.ethernetStatuses.ipv4Dns =
                    this.ethernetStatus.ipv4.dns.join(', ');
            }
            this.ethernetConnected =
                this.ethernetStatus &&
                this.ethernetStatus.general.state === StatusTypes.CONNECTED;

            if (
                this.connectedWifi &&
                this.wifiStatus &&
                this.wifiStatus.general.state === StatusTypes.CONNECTED
            ) {
                this.wifiParams.wifiAdresess = this.wifiStatus.ipv4.address;
            }
        }
        this.refreshInProgress = false;

        if (!this.connectedWifi && !this.ethernetConnected) {
            this._ipcService
                .isOnline()
                .pipe(first())
                .pipe(catchError(this.handleError))
                .subscribe((response) => {
                    if (!(response && response.success)) {
                        this._router.navigateByUrl('/lost-connection');
                        this.dialogRefSettingModal.close();
                    }
                });
        }
    }

    onSelectedWifi(event) {
        this.selectedWifi = event;
    }

    handleError(err): Observable<any> {
        this._loggerService.error('ConnectionSettingsComponent', err);
        return of({});
    }

    showSnackbar(msg: string): void {
        this._loggerService.error('connectionSettingComponent', msg);
        this._snackbarService.show(snackConfig(msg));
    }
}
