import { IDevice, IMediaDevices } from '@/shared/interfaces';
import { FECC_ZOOM_DEFAULTS } from '../default-options';

export const TEST_MEDIA_DEVICE_DATA: IMediaDevices = {
    devices: [
        {
            kind: 'audioinput',
            isPeripheral: false,
            name: 'Audio Input Device',
            productId: '1AB2CD3EF',
            deviceId: '1AB2CD3EF',
            vendorId: '1AB2CD3EF',
            isFeccEnabled: true,
            defaultPlay: true,
            label: 'Phnx MT202',
        } as IDevice,
        {
            kind: 'videoinput',
            isPeripheral: false,
            name: 'Video Input Device',
            productId: '1AB2CD3EF',
            deviceId: '1AB2CD3EF',
            vendorId: '1AB2CD3EF',
            isFeccEnabled: true,
            label: 'Phnx MT202',
        } as IDevice,
        {
            kind: 'audiooutput',
            isPeripheral: false,
            name: 'Audio Output Device',
            productId: '1AB2CD3EF',
            deviceId: '1AB2CD3EF',
            vendorId: '1AB2CD3EF',
            isFeccEnabled: true,
            label: 'Phnx MT202',
        } as IDevice,
    ],
    selectedVideoInput: undefined,
    selfViewShown: false,
    isReady: false,
    zoomLevel: FECC_ZOOM_DEFAULTS.resetValue,
    headPhoneDetect: false,
    headSetMicDetect: false,
    stethLineDetect: false,
    allowSnapshots: false,
    allowStethRecording: false,
    isUploadingFromDevice: null,
    isCameraOff: true,
    ripleyBuiltInDevices: [
        {
            driverName: 'PCM2900C Audio CODEC Analog Stereo',
            kind: 'audioinput',
            allowGain: true,
        },
        {
            driverName: 'PCM2900C Audio CODEC Analog Mono',
            kind: 'audiooutput',
            allowGain: true,
        },
        {
            driverName: 'XMOS VocalFusion Spk (UAC1.0)',
            displayName: 'Built-in Mic',
            kind: 'audioinput',
            allowGain: true,
            vendorId: '20b1',
            productId: '0011',
        },
        {
            driverName: 'XMOS VocalFusion Spk (UAC1.0)',
            displayName: 'Built-in Speaker',
            kind: 'audiooutput',
            allowGain: true,
            vendorId: '20b1',
            productId: '0011',
            defaultPlay: true,
        },
        {
            driverName: 'PCM2912A Audio CODEC Analog Stereo',
            displayName: '3.5mm Headphone Output',
            kind: 'audiooutput',
            productId: '2912',
            vendorId: '08bb',
            allowGain: true,
        },
        {
            driverName: 'PCM2912A Audio Codec Mono',
            displayName: '3.5mm Mic Input',
            productId: '2912',
            vendorId: '08bb',
            kind: 'audioinput',
            allowGain: true,
        },
        {
            driverName: 'See3CAM_CU135 (2560:c1d1)',
            displayName: '4x PTZ Camera (Internal)',
            kind: 'videoinput',
            vendorId: '2560',
            productId: 'c1d1',
        },
    ],
};
