import { createAction, props } from '@ngrx/store';
import { IEndpointSettings } from '@shared/interfaces';

export enum EEndpoints {
    Set = '[Endpoint] Set',
}

export const setEndpoint = createAction(
    EEndpoints.Set,
    props<IEndpointSettings>()
);
