import { Observable, Subject } from 'rxjs';

/*
 * Queue  will be responsible to forward message from sender to subscriber with Subject.
 * Queue class is having _queue , enqueue and dequeue , clean up later if will not get any use cases
 */
export class Queue {
    private _queue: any[] = [];
    private _queueSubject$ = new Subject();

    enqueue(item): void {
        this._queue.push(item);
    }

    dequeue(): any {
        if (this._queue.length === 0) {
            return undefined;
        }
        return this._queue.shift();
    }

    sendMessageQueue(item): void {
        this._queueSubject$.next(item);
    }

    getMessageQueue(): Observable<any> {
        return this._queueSubject$.asObservable();
    }

    clearMessagesQueue(): void {
        this._queueSubject$.next();
    }
}
