import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralTabComponent } from '@/settings/general-tab/general-tab.component';
import { AudioVideoTabComponent } from '@/settings/audio-video-tab/audio-video-tab.component';
import { ConnectivityTabComponent } from './connectivity-tab/connectivity-tab.component';
import { ConnectionSettingsComponent } from './connectivity-tab/connection-settings/connection-settings.component';
import { NoNetworkComponent } from './no-network/no-network.component';
import { WifiComponent } from './connectivity-tab/connection-settings/wifi/wifi.component';
import { WiredComponent } from './connectivity-tab/connection-settings/wired/wired.component';
import { DeviceComponent } from './connectivity-tab/connection-settings/device/device.component';
import { SharedModule } from '@/shared/shared.module';
import { DnsConfigComponent } from './connectivity-tab/connection-settings/dns-config/dns-config.component';
import { StaticIpConfigComponent } from './connectivity-tab/connection-settings/static-ip/static-ip-config.component';

const settingComponents = [
    GeneralTabComponent,
    AudioVideoTabComponent,
    ConnectivityTabComponent,
    ConnectionSettingsComponent,
    NoNetworkComponent,
    WifiComponent,
    WiredComponent,
    DeviceComponent,
    DnsConfigComponent,
    StaticIpConfigComponent,
];

@NgModule({
    declarations: [...settingComponents],
    imports: [CommonModule, SharedModule],
    entryComponents: [...settingComponents],
    exports: [...settingComponents],
})
export class SettingsModule {}
