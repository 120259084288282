import { AppState } from '@/app.reducer';
import { createSelector } from '@ngrx/store';
import * as fromNetworkReducer from '../../../shared/storage/network/network.reducer';

export const selectNetworkState = (
    state: AppState
): fromNetworkReducer.INetworkState => state.network;

export const selectDeviceList = createSelector(
    selectNetworkState,
    (networkState: fromNetworkReducer.INetworkState) => networkState.device_list
);

export const selectNetworkList = createSelector(
    selectNetworkState,
    (networkState: fromNetworkReducer.INetworkState) =>
        networkState.network_list
);

export const selectActiveWifiNetwork = createSelector(
    selectNetworkState,
    (networkState: fromNetworkReducer.INetworkState) =>
        networkState.active_wifi_network
);
