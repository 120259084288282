import { createAction, props } from '@ngrx/store';

const SET_CANCEL_STATUS = 'SET_CANCEL_STATUS';
const SET_SERVICE_LINE = 'SET_SERVICE_LINE';
const SET_VISIT = 'SET_VISIT';
const SET_GENDER_OPTIONS = 'SET_GENDER_OPTIONS';
const SET_PROVIDER_ID = 'SET_PROVIDER_ID';
const SET_INTAKE_DATA = 'SET_INTAKE_DATA';
const UPDATE_SERVICE_LINE = 'UPDATE_SERVICE_LINE';
const UPDATE_INTAKE_DATA = 'SET_INTAKE_DATA';
const SET_CASE_ID = 'SET_CASE_ID';
const SET_PRACTISE = 'SET_PRACTISE';

export const setCancelStatus = createAction(
    SET_CANCEL_STATUS,
    props<{
        isCancelStatus: boolean;
    }>()
);

export const setServiceLine = createAction(
    SET_SERVICE_LINE,
    props<{
        serviceLine: any;
    }>()
);

export const setVisit = createAction(
    SET_VISIT,
    props<{
        visit: any;
    }>()
);

export const setGenderOptions = createAction(
    SET_GENDER_OPTIONS,
    props<{
        genderOptions: any[];
    }>()
);

export const setProviderId = createAction(
    SET_PROVIDER_ID,
    props<{
        providerId: number;
    }>()
);

export const setIntakeData = createAction(
    SET_INTAKE_DATA,
    props<{
        intakeData: any;
    }>()
);

export const updateServiceLine = createAction(
    UPDATE_SERVICE_LINE,
    props<{
        serviceLine: any;
    }>()
);

export const updateIntakeData = createAction(
    UPDATE_INTAKE_DATA,
    props<{
        intakeData: any;
    }>()
);

export const setCaseId = createAction(
    SET_CASE_ID,
    props<{
        caseId: number | string;
    }>()
);

export const setPractise = createAction(
    SET_PRACTISE,
    props<{
        practise: any;
    }>()
);
