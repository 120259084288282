import { Action, createReducer, on } from '@ngrx/store';

import { MediaDevicesActions } from './media-devices.actions';
import { IMediaDevices } from '@shared/interfaces';
import { FECC_ZOOM_DEFAULTS, KIND_OF_MEDIA_DEVICE } from '@shared/constants';

const initialState: IMediaDevices = {
    devices: [],
    selectedVideoInput: undefined,
    selfViewShown: false,
    isReady: false,
    zoomLevel: FECC_ZOOM_DEFAULTS.resetValue,
    headPhoneDetect: false,
    headSetMicDetect: false,
    stethLineDetect: false,
    allowSnapshots: false,
    allowStethRecording: false,
    isUploadingFromDevice: null,
    isCameraOff: true,
    ripleyBuiltInDevices: [
        {
            driverName: 'PCM2900C Audio CODEC Analog Stereo',
            kind: KIND_OF_MEDIA_DEVICE.AUDIO_INPUT,
            allowGain: true,
        },
        {
            driverName: 'PCM2900C Audio CODEC Analog Mono',
            kind: KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT,
            allowGain: true,
        },
        {
            driverName: 'XMOS VocalFusion Spk (UAC1.0)',
            displayName: 'Built-in Mic',
            kind: KIND_OF_MEDIA_DEVICE.AUDIO_INPUT,
            allowGain: true,
            vendorId: '20b1',
            productId: '0011',
        },
        {
            driverName: 'XMOS VocalFusion Spk (UAC1.0)',
            displayName: 'Built-in Speaker',
            kind: KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT,
            allowGain: true,
            vendorId: '20b1',
            productId: '0011',
            defaultPlay: true,
        },
        {
            driverName: 'PCM2912A Audio CODEC Analog Stereo',
            displayName: '3.5mm Headphone Output',
            kind: KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT,
            productId: '2912',
            vendorId: '08bb',
            allowGain: true,
        },
        {
            driverName: 'PCM2912A Audio Codec Mono',
            displayName: '3.5mm Mic Input',
            productId: '2912',
            vendorId: '08bb',
            kind: KIND_OF_MEDIA_DEVICE.AUDIO_INPUT,
            allowGain: true,
        },
        {
            driverName: 'See3CAM_CU135 (2560:c1d1)',
            displayName: '4x PTZ Camera (Internal)',
            kind: KIND_OF_MEDIA_DEVICE.VIDEO_INPUT,
            vendorId: '2560',
            productId: 'c1d1',
        },
    ],
};

const reducer = createReducer(
    initialState,
    on(MediaDevicesActions.setMediaDevices, (state, payload) => ({
        ...state,
        devices: payload.devices,
        isReady: true,
    })),
    on(MediaDevicesActions.setHeadPhoneDetect, (state, payload) => ({
        ...state,
        headPhoneDetect: payload.isConnected,
    })),
    on(MediaDevicesActions.setHeadSetMicDetect, (state, payload) => ({
        ...state,
        headSetMicDetect: payload.isConnected,
    })),
    on(MediaDevicesActions.setStethLineDetect, (state, payload) => ({
        ...state,
        stethLineDetect: payload.isConnected,
    })),
    on(MediaDevicesActions.setSelfView, (state, payload) => ({
        ...state,
        isSelfViewShown: payload.isSelfViewShown,
    })),
    on(MediaDevicesActions.setCameraZoomLevel, (state, payload) => ({
        ...state,
        zoomLevel: payload.zoomLevel,
    })),
    on(MediaDevicesActions.setAllowStethRecording, (state, payload) => ({
        ...state,
        allowStethRecording: payload.isAllow,
    })),
    on(MediaDevicesActions.setAllowSnapshots, (state, payload) => ({
        ...state,
        allowSnapshots: payload.isAllow,
    })),
    on(MediaDevicesActions.setIsUploadingFromDevice, (state, payload) => ({
        ...state,
        isUploadingFromDevice: payload.isUploading,
    }))
);

export function mediaDevicesReducer(
    state: IMediaDevices | undefined,
    action: Action
): IMediaDevices {
    return reducer(state, action);
}

export const mediaDevicesKey = 'mediaDevices';
