import { createAction, props } from '@ngrx/store';
import { IDevice } from '@shared/interfaces';

export enum EMediaDevices {
    Update = '[Media Devices] Update',
    Set = '[Media Devices] Set',

    SetSelectedVideoInput = '[Media Devices] Set Selected Video Input',

    UpdateHeadPhoneDetect = '[Media Devices] Update HeadPhoneDetect',
    SetHeadPhoneDetect = '[Media Devices] Set HeadPhoneDetect',

    UpdateHeadSetMicDetect = '[Media Devices] Update HeadSetMicDetect',
    SetHeadSetMicDetect = '[Media Devices] Set HeadSetMicDetect',

    UpdateStethLineDetect = '[Media Devices] Update StethLineDetect',
    SetStethLineDetect = '[Media Devices] Set StethLineDetect',

    SetAllowSnapshots = '[Media Devices] Set Allow Snapshots',
    SetAllowStethRecording = '[Media Devices] Set Allow Steth Recording',
    SetIsUploadingFromDevice = '[Media Devices] Set Is Uploading FromDevice',

    SetCameraZoomLevel = '[Media Devices] Set Camera Zoom Level',
    SetSelfView = '[Media Devices] Set Self View',
}

export const setMediaDevices = createAction(
    EMediaDevices.Set,
    props<{ devices: IDevice[] }>()
);

export const updateMediaDevices = createAction(EMediaDevices.Update);

export const updateHeadPhoneDetect = createAction(
    EMediaDevices.UpdateHeadPhoneDetect,
    props<{ isConnected: boolean }>()
);

export const setHeadPhoneDetect = createAction(
    EMediaDevices.SetHeadPhoneDetect,
    props<{ isConnected: boolean }>()
);

export const updateHeadSetMicDetect = createAction(
    EMediaDevices.UpdateHeadSetMicDetect,
    props<{ isConnected: boolean }>()
);

export const setHeadSetMicDetect = createAction(
    EMediaDevices.SetHeadSetMicDetect,
    props<{ isConnected: boolean }>()
);

export const updateStethLineDetect = createAction(
    EMediaDevices.UpdateStethLineDetect,
    props<{ isConnected: boolean }>()
);

export const setStethLineDetect = createAction(
    EMediaDevices.SetStethLineDetect,
    props<{ isConnected: boolean }>()
);

export const setSelfView = createAction(
    EMediaDevices.SetSelfView,
    props<{ isSelfViewShown: boolean }>()
);

export const setCameraZoomLevel = createAction(
    EMediaDevices.SetCameraZoomLevel,
    props<{ zoomLevel: number }>()
);

export const setAllowStethRecording = createAction(
    EMediaDevices.SetAllowStethRecording,
    props<{ isAllow: boolean }>()
);

export const setAllowSnapshots = createAction(
    EMediaDevices.SetAllowSnapshots,
    props<{ isAllow: boolean }>()
);

export const setIsUploadingFromDevice = createAction(
    EMediaDevices.SetIsUploadingFromDevice,
    props<{ isUploading: boolean }>()
);

export const MediaDevicesActions = {
    updateMediaDevices,
    setMediaDevices,

    updateHeadPhoneDetect,
    setHeadPhoneDetect,

    updateHeadSetMicDetect,
    setHeadSetMicDetect,

    updateStethLineDetect,
    setStethLineDetect,

    setSelfView,
    setCameraZoomLevel,

    setAllowStethRecording,
    setAllowSnapshots,
    setIsUploadingFromDevice,
};
