export const Otoscope = [
    `
    <div class="hslide-top items-start">
        <img class="" src="/assets/images/peripherals-devices/avizia_device_switch_on.png" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>
            Ensure the device is powered on by moving <br />
            the switch to on.
        </h3>
        <span class="hslide-step">1 of 5</span>
    </div>
    `,
    `
    <div class="hslide-top">
        <i class="slide-icon rotate180 material-icons">forward</i>
    </div>
    <div class="hslide-bottom">
        <h3>Click share to start sharing the video.</h3>
        <span class="hslide-step">2 of 5</span>
    </div>
    `,
    `
    <div class="hslide-top items-start">
        <img class="" src="/assets/images/peripherals-devices/avizia_otoscope_focus.png" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>Focus the image by twisting the focus knob.</h3>
        <span class="hslide-step">3 of 5</span>
    </div>
    `,
    `
    <div class="hslide-top items-start">
        <img class="" src="/assets/images/peripherals-devices/avizia_otoscope_brightness.png" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>Adjust the brightness via the brightness wheel.</h3>
        <span class="hslide-step">4 of 5</span>
    </div>
    `,
    `<div class="hslide-top">
        <i class="slide-icon rotate180 material-icons">forward</i>
    </div>
    <div class="hslide-bottom">
        <h3>
            Click the snapshot button on the interface <br />
            to upload a screenshot to this case.
        </h3>
        <span class="hslide-step">5 of 5</span>
    </div>
    `,
];
