import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { LoggerService } from '../logger';
import { ERROR_MESSAGES } from '@/shared/constants';
import { navigateToMain } from '@shared/storage/layout/layout.actions';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})
export class LogService {
    private _prevErr: string;
    constructor(
        private _router: Router,
        private _location: Location,
        private _loggerService: LoggerService,
        private _store: Store
    ) {}

    createErrorMessage(err): void {
        let errorDetails = err.__errorCode
            ? 'Error Code: ' +
              err.__errorCode.toString() +
              ' ' +
              err.__error.toString()
            : '';
        err.__fieldName
            ? (errorDetails += ' Field Name: ' + err.__fieldName.toString())
            : '';
        err.__reason
            ? (errorDetails += ' Reason: ' + err.__reason.toString())
            : '';
        err.__recoverySuggestion
            ? (errorDetails +=
                  ' Recovery Suggestion: ' +
                  err.__recoverySuggestion.toString())
            : '';

        if (err.__error && err.__error.stack) {
            err.__error.stack
                ? (errorDetails +=
                      ' Stack Trace: ' + err.__error.stack.toString())
                : '';
        }

        this.logErrorAndRedirect(errorDetails);
    }

    createPexipErrorMessage(err): void {
        const callDisconnected = 'Disconnected by another participant';
        const callEnded = 'Conference terminated by another participant';
        this.logErrorAndRedirect(JSON.stringify(err));
        if (err === callDisconnected || err === callEnded) {
            this._store.dispatch(navigateToMain());
        }
    }

    logErrorAndRedirect(
        errorDetails,
        failType = '',
        networkError = false
    ): void {
        const url = this._location.path();
        const log = {
            type: 'error',
            module: url + '_WEB',
            details: errorDetails,
        };

        if (this._prevErr !== errorDetails) {
            this._loggerService.error('logErrorAndRedirect', log);
        }
        this._prevErr = errorDetails;

        if (url.indexOf('videocomponents') < 0 && window.navigator.onLine) {
            this._router.navigate(['/error-generic'], {
                state: {
                    errorType: ERROR_MESSAGES.VIDEO_CALL_ERROR,
                    failType,
                    networkError,
                },
            });
        }
    }

    logError(errorDetails): void {
        const url = this._location.path();
        const log = {
            type: 'error',
            module: url + '_WEB',
            details: errorDetails,
        };
        this._loggerService.log('logError', log);
    }
}
