export const ENCRYPTION_TYPES = {
    None: {
        requireUsername: false,
        requirePassword: false,
    },
    WEP: {
        requireUsername: false,
        requirePassword: true,
    },
    'WPA/WPA2': {
        requireUsername: false,
        requirePassword: true,
    },
    'WPA/WPA2 ENTERPRISE': {
        requireUsername: true,
        requirePassword: true,
    },
};

export const ENCRYPTION_OPTIONS = [
    'None',
    'WEP',
    'WPA/WPA2',
    'WPA/WPA2 ENTERPRISE',
];
