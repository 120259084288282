import { Injectable } from '@angular/core';
import { IpcService } from '@shared/services/ipc';
import { LoggerService } from '@shared/services/logger';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '../session';

@Injectable({ providedIn: 'root' })
export class DeviceService {
    constructor(
        private _ipcService: IpcService,
        private _loggerService: LoggerService,
        private _http: HttpClient,
        private _sessionService: SessionService
    ) {}

    shutdown(): void {
        this._ipcService.shutdownDevice();
    }

    restart(): void {
        this._ipcService.restartDevice();
    }

    sendLogs(data: any): void {
        this._loggerService.log(data);
    }

    disconnectDevice(): void {
        //TO DO: disconnectDevice() from LocalService -- 26/08/21 - Need to TEST it and remove comment
        try {
            this._sessionService.deviceInstance.onCall = false;
            this._sessionService.deviceInstance.resetState();
        } catch (err) {
            this.sendLogs(err);
        }
    }

    getTokens(): Observable<any> {
        return this._http.get('https://localhost:1440/tokens');
    }
}
