/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { INetwork, INetworkDevice } from '@shared/interfaces';
const REFRESH_NETWORK_STATUS = 'REFRESH_NETWORK_STATUS';
const REFRESH_NETWORK_LIST = 'REFRESH_NETWORK_LIST';

export const refreshNetworkStatus = createAction(
    REFRESH_NETWORK_STATUS,
    props<{
        device_list: INetworkDevice[];
        active_wifi_network: INetwork | {};
    }>()
);

export const refreshNetworkList = createAction(
    REFRESH_NETWORK_LIST,
    props<{
        device_list: INetworkDevice[];
        network_list: INetwork[];
    }>()
);
