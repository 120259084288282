export const ENCRYPTION_TYPES = [
    {
        name: 'None',
        key_mgmt: '',
        eap: '',
        phase_2_auth: '',
        value: {
            requireUsername: false,
            requirePassword: false,
        },
    },
    {
        name: 'WEP',
        key_mgmt: 'none',
        eap: '',
        phase_2_auth: '',
        value: {
            requireUsername: false,
            requirePassword: true,
        },
    },
    {
        name: 'WPA/WPA2',
        key_mgmt: 'wpa-psk',
        eap: '',
        phase_2_auth: '',
        value: {
            requireUsername: false,
            requirePassword: true,
        },
    },
    {
        name: 'WPA/WPA2 ENTERPRISE',
        key_mgmt: 'wpa-eap',
        eap: 'peap',
        phase_2_auth: 'mschapv2',
        value: {
            requireUsername: true,
            requirePassword: true,
        },
    },
];
