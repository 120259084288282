import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private _http: HttpClient) {}

    /* Attachment functions START */
    requestUpload(caseId, data): Observable<any> {
        return this._http.post(`v10/case/${caseId}/files/request_upload`, data);
    }

    putUpload(url, file, options): Observable<any> {
        return this._http.put(url, file, options);
    }

    uploaded(caseId, fileId, data): Observable<any> {
        return this._http.post(
            `v10/case/${caseId}/files/${fileId}/uploaded`,
            data
        );
    }
    /* Attachment functions END */
}
