<router-outlet></router-outlet>

<aw-keyboard
    *ngIf="showKeyboard$ | async"
    styleClasses="gray-dark"
    buttonStyleClasses="waves-effect waves-gray"
    active="false"
    (activeChange)="handlerKeyboardOpened($event)"
    fieldSelector=".js-keyboard-input"
    nonCapitalizeFieldSelector=".js-non-capitalize"
    customNumberFieldSelector=".js-keyboard-input-number"
>
</aw-keyboard>

<wake-up-screen></wake-up-screen>
