import { Action, createReducer, on } from '@ngrx/store';
import { IEndpointSettings } from '@interfaces/settings/endpoint-settings.interface';
import * as EndpointActions from './endpoint-actions';

export const endpointInitialState: IEndpointSettings = null; // null

const reducer = createReducer(
    endpointInitialState,
    on(EndpointActions.setEndpoint, (state, payload) => ({
        ...state,
        ...payload,
    }))
);

export function EndpointReducer(
    state: IEndpointSettings | undefined,
    action: Action
): IEndpointSettings {
    return reducer(state, action);
}
