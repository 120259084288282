export const NONE_BATTERY_SHUTDOWN_TIMEOUT = 5 * 60 * 1000;
export const NONE_BATTERY_TONE = './assets/audio/low-battery-alert.wav';
export const LOCALHOST_URL = 'https://localhost:1440';
export const SSE_URL = 'https://localhost:1440/event';
export const DEFAULT_VOLUMES = {
    DEFAULT_VOLUME_C250: 20,
    DEFAULT_VOLUME_C500: 30,
    DEFAULT_VOLUME_INTMTV: 20,
};

export enum KIND_OF_MEDIA_DEVICE {
    VIDEO_INPUT = 'videoinput',
    AUDIO_INPUT = 'audioinput',
    AUDIO_OUTPUT = 'audiooutput',
}

// export const FECC_DEVICES = [
//     {
//         displayName: 'Bolin 10x PTZ Camera',
//         label: '2cb3:0201',
//         vendorId: '2cb3',
//         productId: '0201',
//     }, // Bolin_201
//     {
//         displayName: 'Bolin 10x PTZ Camera',
//         label: '2cb3:0202',
//         vendorId: '2cb3',
//         productId: '0202',
//     }, // Bolin_202
//     {
//         displayName: 'PTZOptics Camera',
//         label: '2e7e:0600',
//         vendorId: '2e7e',
//         productId: '0600',
//     }, // PTZOptics
//     {
//         displayName: 'Sony 12x PTZ Camera',
//         label: '054c:0b7f',
//         vendorId: '054c',
//         productId: '0b7f',
//     }, // Sony Camera
//     {
//         displayName: '20x PTZ Camera',
//         label: 'Cyt-FX4 (04b4:00f9)',
//         vendorId: '04b4',
//         productId: '00f9',
//     }, // Minrray Camera old driver name
//     {
//         displayName: '20x PTZ Camera',
//         label: 'minrray20 (04b4:00f9)',
//         vendorId: '04b4',
//         productId: '00f9',
//     }, // Minrray Camera 20X
//     {
//         displayName: '20x PTZ Camera',
//         label: 'Cyt-FX4 (04b4:04f2)',
//         vendorId: '04b4',
//         productId: '04f2',
//     }, // Minrray Camera 20X
//     {
//         displayName: '10x PTZ Camera',
//         label: 'minrray10 (04b4:00f9)',
//         vendorId: '04b4',
//         productId: '00f9',
//     }, // Minrray Camera 10X
//     {
//         displayName: '4x PTZ Camera (Internal)',
//         label: 'See3CAM_CU135',
//         vendorId: '2560',
//         productId: 'c1d1',
//     }, // ePTZ Camera 4X
// ];

export const BUILT_IN_DEVICES = [
    {
        driverName: 'USB 5.0M AF Camera (058f:3823)',
        displayName: 'Tablet Camera',
        kind: KIND_OF_MEDIA_DEVICE.VIDEO_INPUT,
    },
    {
        driverName: 'USB 2.0 Camera (038f:0541)',
        displayName: 'Built-in Camera',
        kind: KIND_OF_MEDIA_DEVICE.VIDEO_INPUT,
    },
    {
        driverName: 'Built-in Audio Analog Stereo',
        displayName: 'Built-in Microphone',
        kind: KIND_OF_MEDIA_DEVICE.AUDIO_INPUT,
    },
    {
        driverName: 'PCM2704C stereo audio DAC Analog Stereo',
        displayName: 'Cradle Speaker (Recommended)',
        kind: KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT,
        vendorId: '08bb',
        productId: '27c4',
        allowGain: true,
        defaultPlay: true,
    },
    {
        driverName: 'Built-in Audio Analog Stereo',
        displayName: 'Tablet Speaker',
        kind: KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT,
        vendorId: '8086',
        productId: '9d71',
        allowGain: true,
    },
    {
        driverName: 'Jabra SPEAK 410 USB',
        displayName: 'Jabra SPEAK 410 USB',
        kind: KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT,
        vendorId: '0b0e',
        productId: '0412',
        allowGain: true,
    },
];
