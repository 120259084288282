import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IWebSocketConfig } from '@interfaces/websocket';
import { WebsocketService } from './websocket.service';
import { config } from './websocket.config';
@NgModule({
    imports: [CommonModule],
    declarations: [],
    providers: [WebsocketService],
})
export class WebsocketModule {
    public static config(
        wsConfig: IWebSocketConfig
    ): ModuleWithProviders<WebsocketModule> {
        return {
            ngModule: WebsocketModule,
            providers: [{ provide: config, useValue: wsConfig }],
        };
    }
}
