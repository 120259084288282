/**
 * This file includes all global UI states that could be accessed in application.
 * Layout is parent for all UI state and will contain sub state eg . Modal , POPUP , ALERT etc.
 *
 */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AppState } from '@/app.reducer';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { createReducer, createSelector, on } from '@ngrx/store';
import * as layoutAction from './layout.actions';
import { AwToastOptionInterface } from '@aw-hospital/aw-components-lib/src/interfaces';

export interface ILayoutState {
    modal: IModalState;
    toast?: IToastState;

    appUpdate: IAppUpdate;
}
export interface IModalState {
    activeModalStack: IActiveModal[];
    isOpenSettingModal: boolean;
}
export interface IActiveModal {
    componentName: string;
    config?: MatDialogConfig;
    modalKey?: string;
    afterClosed?: (...args: any) => void;
    afterOpened?: (...args: any) => void;
    getDialogRef?: (dialogRef: MatDialogRef<any>) => void;
    getModalId?: (modalId: string) => void;
}

export interface IToastState {
    activeToastStack: IActiveToast[];
}
export interface IActiveToast {
    options?: AwToastOptionInterface;
    toastKey?: string;
    actionCallback?: (...args: any) => void;
    closeCallback?: (...args: any) => void;
}
export interface IAppUpdate {
    currentVersion: string;
    previousVersion: string;
    isAppUpdateAvailable?: boolean;
    intervalToCheckAppUpdate?: number;
    waitForAppUpdate: boolean;
}

export const layoutState: ILayoutState = {
    modal: {
        activeModalStack: [] as IActiveModal[],
        isOpenSettingModal: false,
    },
    appUpdate: {
        currentVersion: '',
        previousVersion: '',
        isAppUpdateAvailable: false,
        intervalToCheckAppUpdate: 5 * 60 * 1000, // Default 5min = 5*60*1000 to check update
        waitForAppUpdate: false,
    } as IAppUpdate,
    toast: {
        activeToastStack: [] as IActiveToast[],
    },
};

const reducer = createReducer(
    layoutState,
    on(layoutAction.addActiveModal, (state, payload) => {
        const newState = {
            ...state,
            modal: {
                ...state.modal,
                activeModalStack: [
                    payload.modal,
                    ...state.modal.activeModalStack,
                ],
            },
        };
        return newState;
    }),

    on(layoutAction.removeActiveModal, (state, payload) => ({
        ...state,
        modal: {
            ...state.modal,
            activeModalStack: state.modal.activeModalStack.filter(
                (modal: IActiveModal) =>
                    modal.modalKey !== payload.modal.modalKey
            ),
        },
    })),
    on(layoutAction.updateSettingModalStatus, (state, payload) => ({
        ...state,
        modal: {
            ...state.modal,
            isOpenSettingModal: payload.isOpenSettingModal,
        },
    })),
    on(layoutAction.addActiveToast, (state, payload) => {
        const newState = {
            ...state,
            toast: {
                ...state.toast,
                activeToastStack: [
                    { ...payload.toast },
                    ...state.toast.activeToastStack,
                ],
            },
        };
        return newState;
    }),

    on(layoutAction.removeActiveToast, (state, payload) => ({
        ...state,
        toast: {
            ...state.toast,
            activeToastStack: state.toast.activeToastStack.filter(
                (toast: IActiveToast) =>
                    toast.toastKey !== payload.toast.toastKey
            ),
        },
    })),

    on(layoutAction.setAppUpdate, (state, payload) => ({
        ...state,
        appUpdate: { ...payload.appUpdate },
    })),

    on(layoutAction.setIntervalToCheckAppUpdate, (state, payload) => ({
        ...state,
        appUpdate: {
            ...state.appUpdate,
            checkForAppUpdateInterval: payload.interval,
        },
    })),

    on(layoutAction.waitForAppUpdate, (state, payload) => ({
        ...state,
        appUpdate: {
            ...state.appUpdate,
            waitForAppUpdate: payload.waitForAppUpdate,
        },
    }))
);

export function LayoutReducer(
    state: ILayoutState | undefined,
    action: layoutAction.LayoutActions
): ILayoutState {
    return reducer(state, action);
}

export const selectLayoutState = (state: AppState): ILayoutState =>
    state.layout;
export const selectModalState = createSelector(
    selectLayoutState,
    (state: ILayoutState) => state.modal
);

export const selectActiveModalStack = createSelector(
    selectModalState,
    (state: IModalState) => state.activeModalStack
);
export const selectActiveModal = createSelector(
    selectModalState,
    (state: IModalState) => state.activeModalStack[0]
);
export const selectIsOpenSettingModal = createSelector(
    selectModalState,
    (state: IModalState) => state.isOpenSettingModal
);
export const selectToastState = createSelector(
    selectLayoutState,
    (state: ILayoutState) => state.toast
);
export const selectActiveToast = createSelector(
    selectToastState,
    (state: IToastState) => state.activeToastStack[0]
);

// Application Updates

export const selectAppUpdate = createSelector(
    selectLayoutState,
    (state: ILayoutState) => state.appUpdate
);
export const selectIsAppUpdateAvailable = createSelector(
    selectAppUpdate,
    (state: IAppUpdate) => state.isAppUpdateAvailable
);
export const selectIntervalToCheckAppUpdate = createSelector(
    selectAppUpdate,
    (state: IAppUpdate) => state.intervalToCheckAppUpdate
);
