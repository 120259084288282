import { Action, createReducer, on } from '@ngrx/store';
import { IDeviceInfo } from '@interfaces/device/device-info.interface';
import * as DeviceActions from './device-actions';

export const deviceInfoInitialState: IDeviceInfo = null;

const reducer = createReducer(
    deviceInfoInitialState,
    on(DeviceActions.updateDeviceInfo, (state, payload) => ({
        ...state,
        ...payload.deviceInfo,
    })),
    on(DeviceActions.updateDeviceInfoByKey, (state, payload) => ({
        ...state,
        [payload.key]: payload.value,
    })),
    on(DeviceActions.startVideoShare, (state, payload) => ({
        ...state,
        sharingVideo: true,
        deviceId: payload.deviceId,
        sharingTitle: payload.title,
    })),
    on(DeviceActions.endVideoShare, (state, payload) => ({
        ...state,
        sharingVideo: false,
        deviceId: payload.deviceId,
        sharingTitle: payload.title,
    })),
    on(DeviceActions.onScreenKeyboardChanged, (state, payload) => ({
        ...state,
        ...payload,
    }))
);

export function DeviceInfoReducer(
    state: IDeviceInfo | undefined,
    action: Action
): IDeviceInfo {
    return reducer(state, action);
}
