<div class="modal-h flex flex-column h100vh pin-screen" id="pinModal">
    <div
        class="dashboard-wrapper primary-light page-wrap pin-screen"
        [ngClass]="{ 'global-keyboard-padding': (isKeyboardOpened$ | async) }"
    >
        <div class="flex flex-column flex-1 items-center justify-center">
            <a
                automation="closeIcon"
                class="btn-back-revers primary-text flex items-center justify-center x-icon"
                (click)="gotoDashboard($event)"
            >
                <i class="material-icons primary-text m-r1 x-mark">close</i>
                Exit
            </a>
            <div class="rel settings-block">
                <h1 class="text-center">Settings</h1>
                <h2 class="text-center">Enter your Admin PIN number to access Settings</h2>
                <app-pin-code (onSuccess)="handlerSuccess()"></app-pin-code>
            </div>
        </div>
    </div>
</div>
