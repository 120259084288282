import { Injectable } from '@angular/core';
import { EndpointTypes } from '../enums';
import { FleetHandlerService } from '../services/fleet';
import { IpcService } from '../services/ipc';
import { StorageService } from '../services/storage';
import { LoggerService } from '../services/logger/logger.service';
import { MetricService } from '../services/metrics/metrics.service';
import { SessionService } from '../services/session';
import { AmwellCart250 } from './amwell-cart-250';
import { AmwellCart500 } from './amwell-cart-500';
import { AmwellTv } from './amwell-tv';
import { PTZAxisService } from '../services/ptz/ptz-axis.service';
import { PTZService } from '../services/ptz/ptz.service';

@Injectable({ providedIn: 'root' })
export class DeviceFactory {
    device: AmwellTv | AmwellCart250 | AmwellCart500;
    constructor(
        private _sessionService: SessionService,
        private _ipcService: IpcService,
        private _fleetHandlerService: FleetHandlerService,
        private _metricService: MetricService,
        private _loggerService: LoggerService,
        private _storageService: StorageService,
        private _ptzAxisService: PTZAxisService,
        private _ptzService: PTZService
    ) {}
    getDevice(): AmwellTv | AmwellCart250 | AmwellCart500 {
        let endpointTypeId = this._sessionService.getConfig().endpoint_type_id;
        if (!endpointTypeId) {
            endpointTypeId = EndpointTypes.ApgarCodec;
        }
        switch (endpointTypeId) {
            case EndpointTypes.ApgarCodec:
                this.device = new AmwellCart250(
                    this._fleetHandlerService,
                    this._ipcService,
                    this._sessionService,
                    this._metricService,
                    this._loggerService,
                    this._storageService,
                    this._ptzAxisService,
                    this._ptzService
                );
                break;
            case EndpointTypes.Intmtvkit:
                this.device = new AmwellTv(
                    this._fleetHandlerService,
                    this._ipcService,
                    this._sessionService,
                    this._metricService,
                    this._loggerService,
                    this._storageService,
                    this._ptzAxisService,
                    this._ptzService
                );
                break;
            case EndpointTypes.C500:
                this.device = new AmwellCart500(
                    this._fleetHandlerService,
                    this._ipcService,
                    this._sessionService,
                    this._metricService,
                    this._loggerService,
                    this._storageService,
                    this._ptzAxisService,
                    this._ptzService
                );
                break;
        }
        this.device.type = endpointTypeId.toLowerCase();
        return this.device;
    }

    getDeviceInstance(): AmwellTv | AmwellCart250 | AmwellCart500 {
        return this.device;
    }
}
