import { UpdateTypeEnum } from '@/shared/enums';
import { selectUpdateType } from '@/shared/storage/selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { IIpcResponseHandler } from '../../interfaces';
import { IpcService } from './ipc.service';

@Injectable({ providedIn: 'root' })
export class UpdaterResponseService implements IIpcResponseHandler {
    constructor(private _ipcService: IpcService, private _store: Store) {}

    handleResponse(response) {
        const { updater: updaterResponse } = response;
        const { command } = updaterResponse;
        switch (command) {
            case 'ROLLBACK':
                break;
            default:
                this.handleDownloadResponse(updaterResponse);
                break;
        }
    }

    handleDownloadResponse(updaterResponse) {
        const { state } = updaterResponse;
        switch (state) {
            case 'COMPLETE':
                this._store
                    .select(selectUpdateType)
                    .pipe(first())
                    .subscribe((updateType) => {
                        console.log('..download update is complete');
                        if (updateType === UpdateTypeEnum.advancedAuto) {
                            this._ipcService.restartDevice();
                        }
                    });
                break;
            default:
                break;
        }
    }
}
