import { Injectable } from '@angular/core';

import { IIpcResponseHandler } from '../../interfaces';

import { DefaultResponseService } from './default-response.service';
import { FileResponseService } from './file-response.service';
import { PowerResponseService } from './power-response.service';
import { SpineResponseService } from './spine-response.service';
import { UpdaterResponseService } from './updater-response.service';
import { NetworkResponseService } from './network-response.service';
import { SystemInfoResponseService } from './system-info-response.service';
import { PeripheralResponseService } from './peripheral-response.service';
import { AudioResponseService } from './audio-response.service';
import { UsbResponseService } from './usb-response.service';
import { CameraResponseService } from './camera-response.service';
import { LoggerService } from '../logger';
import { WebsocketService } from '@/websocket';
import { takeWhile } from 'rxjs/operators';
import { IPC_RESPONSE } from '@/shared/constants/system-command';

@Injectable({ providedIn: 'root' })
export class IpcResponseService {
    private _alive = true;
    constructor(
        private _webSocketService: WebsocketService,
        private _loggerService: LoggerService,
        private _updaterResponseService: UpdaterResponseService,
        private _powerResponseService: PowerResponseService,
        private _networkResponseService: NetworkResponseService,
        private _spineResponseService: SpineResponseService,
        private _fileResponseService: FileResponseService,
        private _systemInfoResponseService: SystemInfoResponseService,
        private _peripheralResponseService: PeripheralResponseService,
        private _audioResponseService: AudioResponseService,
        private _usbResponseService: UsbResponseService,
        private _defaultResponseService: DefaultResponseService,
        private _cameraResponseService: CameraResponseService
    ) {
        this._webSocketService
            .on()
            .pipe(takeWhile(() => this._alive))
            .subscribe((msg) => {
                try {
                    this._loggerService.log(IPC_RESPONSE, JSON.stringify(msg));
                    this.getResponseHandler(msg).handleResponse(msg);
                } catch (e) {
                    this._loggerService.log(
                        'IPCService.websocketservice.on',
                        e
                    );
                }
            });
    }

    getResponseHandler(type: any): IIpcResponseHandler {
        let response;
        if (type.updater) {
            response = this._updaterResponseService;
        } else if (type.power) {
            response = this._powerResponseService;
        } else if (type.network) {
            response = this._networkResponseService;
        } else if (type.spine_comms) {
            response = this._spineResponseService;
        } else if (type.file) {
            response = this._fileResponseService;
        } else if (type.system_info) {
            response = this._systemInfoResponseService;
        } else if (type.peripherals) {
            response = this._peripheralResponseService;
        } else if (type.audio) {
            response = this._audioResponseService;
        } else if (type.usb) {
            response = this._usbResponseService;
        } else if (type.video) {
            response = this._cameraResponseService;
        } else {
            response = this._defaultResponseService;
        }
        return response;
    }
}
