import { Component, OnInit } from '@angular/core';
import { IDeviceInfo } from '@shared/interfaces';
import { first } from 'rxjs/operators';
import { IpcService } from '@services/ipc';
import { LoggerService } from '@services/logger';
import { SnackbarService } from '@aw-hospital/aw-components-lib/src/services/snackbar';
import { RoamingThresholdTypes } from '@/shared/enums';
import { Store } from '@ngrx/store';
import { selectDeviceInfo } from '@/shared/storage/selectors/device-selectors';
import { CONNECTION_ERRORS } from '@/shared/constants';

@Component({
    selector: 'app-device',
    templateUrl: './device.component.html',
})
export class DeviceComponent implements OnInit {
    public deviceInfo: IDeviceInfo = {
        ethHwAddress: '',
        wifiHwAddress: '',
        hostName: '',
    };

    public roamingThreshold: string = RoamingThresholdTypes.MEDIUM;
    public roamingThresholdTypes = Object.values(RoamingThresholdTypes);

    constructor(
        private _ipcService: IpcService,
        private _snackbarService: SnackbarService,
        private _loggerService: LoggerService,
        private _store: Store
    ) {}

    ngOnInit() {
        this.getDeviceInfo();
    }

    getDeviceInfo() {
        this._store
            .select(selectDeviceInfo)
            .pipe(first())
            .subscribe((deviceInfo) => {
                this.deviceInfo.hostName = deviceInfo.hostName;
                this.deviceInfo.ethHwAddress = deviceInfo.ethHwAddress;
                this.deviceInfo.wifiHwAddress = deviceInfo.wifiHwAddress;
            });

        this._ipcService
            .getRoamingThreshold()
            .pipe(first())
            .subscribe((data) => {
                const {
                    roaming_threshold:
                        roamingThreshold = RoamingThresholdTypes.MEDIUM,
                } = data;
                this.roamingThreshold = roamingThreshold;
            });
    }

    onSaveMyDevice() {
        const changeHostName = new Promise((resolve, reject) => {
            this._ipcService
                .requestSetHostName(this.deviceInfo.hostName)
                .pipe(first())
                .subscribe((result) => {
                    return result.success
                        ? resolve(result)
                        : reject(CONNECTION_ERRORS.SET_HOSTNAME_FAILED);
                });
        });
        const changeThresholed = new Promise((resolve, reject) => {
            this._ipcService
                .setRoamingThreshold(this.roamingThreshold)
                .pipe(first())
                .subscribe((result) =>
                    result.success
                        ? resolve(result)
                        : reject(CONNECTION_ERRORS.SET_THRESHOLD_FAILED)
                );
        });
        Promise.all([changeHostName, changeThresholed])
            .then(() => {
                this._snackbarService.show({
                    description: 'Succesfully Updated',
                    style: 'success',
                });
            })
            .catch((errorMsg) => {
                this._loggerService.error('device component', errorMsg);
                this._snackbarService.show({
                    description: errorMsg,
                    style: 'danger',
                });
            });
    }
}
