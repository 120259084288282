import { IVideoCallRoom } from '@/shared/interfaces/api/video-call-input.interface';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IntmMediaDevicesService } from '../intm-media-devices';
import { DeviceService } from '../device';
import { LogService } from '../log';
import { MetricService } from '../metrics';
import { SessionService } from '../session';
import { VideoService } from '../video';
import {
    AUDIO_INPUT_CONSTRAINT,
    FECC_POSITION,
    VIDEO_INPUT_CONSTRAINT,
} from '@/shared/constants';
import { PTZService } from '../ptz/ptz.service';

declare const PexRTC: any;

@Injectable({ providedIn: 'root' })
export class IntmVideoService {
    public room: IVideoCallRoom = null;
    public pexRTCCall: any;
    public participantName: string;
    public stream: MediaStream;
    public imageShare: boolean;
    public selfViewShown: boolean;
    public zoomSent: string;
    public presentation$ = new Subject<{
        status: boolean;
        stream?: MediaStream;
    }>();
    private _subscriptions: Subscription[] = [];

    constructor(
        private _mediaDevicesService: IntmMediaDevicesService,
        private _sessionService: SessionService,
        private _videoService: VideoService,
        private _router: Router,
        private _logService: LogService,
        private _deviceService: DeviceService,
        private _metricService: MetricService,
        private _ptzService: PTZService
    ) {}

    /* istanbul ignore next */
    async startObserverCall(room: IVideoCallRoom) {
        this.zoomSent = null;
        let audioConstraint;
        let videoConstraint;

        const videoInput = this._mediaDevicesService.defaultVideoInput();
        const audioInput = this._mediaDevicesService.defaultAudioInput();

        if (audioInput) {
            audioConstraint = { ...AUDIO_INPUT_CONSTRAINT };
            audioConstraint.mandatory.sourceId = audioInput.deviceId;
        }

        if (videoInput) {
            videoConstraint = { ...VIDEO_INPUT_CONSTRAINT };
            videoConstraint.deviceId = videoInput.deviceId;
        }

        const subscription = timer(20000).subscribe(() => {
            if (this.zoomSent !== 'complete') {
                const subscription = this._videoService
                    .setHomePosition()
                    .pipe(
                        catchError((err) => {
                            this._logService.logError(err);
                            return Observable.throw(err);
                        })
                    )
                    .subscribe();
                this._subscriptions.push(subscription);
            }
        });
        this._subscriptions.push(subscription);

        const stream = await navigator.mediaDevices
            .getUserMedia({
                audio: audioConstraint || false,
                video: videoConstraint || false,
            })
            .catch((err) => {
                this.disconnect();
                this._logService.logError(err);
            });

        this.setRoom(room);

        this.pexRTCCall = new PexRTC({});

        this.pexRTCCall.turn_server = this.room.ice.iceServers[0];
        this.pexRTCCall.default_stun = this.room.ice.stunServers[0].urls;

        this.pexRTCCall.user_media_stream = stream;

        this.participantName = `endpoint:${
            this._sessionService.getEndpoint().endpoint_id
        }`;

        this.pexRTCCall.makeCall(
            this.room.conference_address,
            this.room.room_id,
            this.participantName,
            null,
            null,
            false
        );

        this._metricService.sendMetrics({
            call_state: '1',
        });

        this.pexRTCCall.onError = (err) => {
            this._logService.logError(err);
            this.disconnect();
        };

        this.pexRTCCall.onSetup = () => {
            this.pexRTCCall.connect(this.room.pin);
        };

        this.pexRTCCall.onConnect = (videoStream) => {
            this.stream = videoStream;
        };

        this.pexRTCCall.onDisconnect = (reason) => {
            const log = {
                type: 'trace',
                module: 'PEXIP_ONDISCONNECT_WEB',
                details: reason,
            };
            // this.ObservableService.callEnd();
            this._deviceService.sendLogs(log);
            this.disconnect();
        };

        this.pexRTCCall.onPresentation = (setting, presenter) => {
            if (setting && presenter.indexOf('siteuser') > -1) {
                this.pexRTCCall.getPresentation();
            } else {
                this.imageShare = false;
                this.selfViewShown = true;
            }
        };

        this.pexRTCCall.onPresentationConnected = (
            presentationStream: MediaStream
        ) => {
            this.presentation$.next({
                status: true,
                stream: presentationStream,
            });
        };

        this.pexRTCCall.onPresentationDisconnected = () => {
            this.presentation$.next({
                status: false,
            });
        };

        Object.assign(
            this.pexRTCCall,
            this._videoService.getApgarAutoHangupSetting()
        );
    }

    getVideoStream() {
        return this.stream;
    }

    sendMessage(message: string) {
        if (message.indexOf('ZOOM_CHANGED') > -1) {
            this.zoomSent = 'complete';
        }
        return this.pexRTCCall.sendChatMessage(message);
    }

    disconnect() {
        this._deviceService.disconnectDevice();
        this._ptzService.setFeccPosition(FECC_POSITION.PRIVACY);
        if (this.pexRTCCall) {
            this.pexRTCCall.clearLocalStream();
            if (this.pexRTCCall.user_media_stream) {
                const tracks =
                    this.pexRTCCall.user_media_stream.getTracks() as any[];
                tracks.forEach((t) => t.stop());
            }
        }
        this.removeRoom();
        this._metricService.sendMetrics({
            call_state: '0',
        });
        this._router.navigateByUrl('/intmhomescreen');
    }

    setRoom(room) {
        this.room = room;
    }

    getRoom() {
        return this.room;
    }

    removeRoom() {
        this.room = null;
    }
}
