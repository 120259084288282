import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionService } from '@/shared/services';

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
    constructor(private _sessionService: SessionService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const env =
            this._sessionService.deviceInfo &&
            this._sessionService.deviceInfo.environment;
        const port = env === 'local' ? ':2443' : '';

        if (
            request.url.includes('v10') ||
            request.url.includes('converge/token')
        ) {
            return next.handle(
                request.clone({
                    url: `https://one-api-${env}.avizia.com${port}/${request.url}`,
                })
            );
        }

        return next.handle(request);
    }
}
