import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { of, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class Aviziap2pService implements OnDestroy {
    public localStream: any;
    public p2pConnection: any;
    public offer: any;
    public remoteUserId: any;
    public dataChannel: Subject<any> = new Subject();
    public binaryDataChannel: Subject<any> = new Subject();
    public audio: any;
    public video: any;
    public constraints: any;
    public sendDataChannel: any;
    public readyToNegotiate: Subject<any>;
    public sendBinaryDataChannel: any;

    public userId: any; //= SessionService.getUserId();

    private _subscriptions: Array<Subscription> = [];

    constructor(private _http: HttpClient, private _router: Router) {}

    ngOnDestroy() {
        this._subscriptions.forEach((s) => s.unsubscribe());
    }

    getPreviewStream() {
        return this.localStream;
    }

    muteAudio() {
        const streams = this.p2pConnection.getLocalStreams();
        streams[0].getAudioTracks()[0].enabled =
            !streams[0].getAudioTracks()[0].enabled;
    }

    muteVideo() {
        const streams = this.p2pConnection.getLocalStreams();
        streams[0].getVideoTracks()[0].enabled =
            !streams[0].getVideoTracks()[0].enabled;
    }

    getLocalStreams() {
        const streams = this.p2pConnection.getLocalStreams();
        const video = document.getElementsByTagName('video');
        for (let i = streams.length; i > 0; i--) {
            video[i - 1].srcObject = streams[i - 1];
        }
    }

    removeLastStream() {
        const video = document.getElementsByTagName('video');
        const streams = this.p2pConnection.getLocalStreams();

        if (streams.length > 1) {
            video[streams.length - 1].srcObject = null;
        }
        let stream = streams[streams.length - 1];
        this.p2pConnection.removeStream(stream);
        stream.getTracks().forEach((track) => {
            track.stop();
        });
        stream = null;
        return of();
    }

    setMediaDevice(device) {
        this.audio = device.audio;
        this.video = device.video;
        return of();
    }

    getCurrentMediaDevice() {
        return {
            audioDeviceId: this.audio ? this.audio.deviceId : '',
            videoDeviceId: this.video ? this.video.deviceId : '',
        };
    }

    getConstraints(type = '') {
        const constraints = {
            audio: {} as any,
            video: {} as any,
        };

        this.audio
            ? (constraints.audio.deviceId = this.audio.deviceId)
            : (constraints.audio = false);
        this.video
            ? (constraints.video.deviceId = this.video.deviceId)
            : (constraints.video = false);

        switch (type) {
            case 'HD':
                constraints.video.width = {
                    exact: 1280,
                };
                constraints.video.height = {
                    exact: 720,
                };
                break;
            case 'QVGA':
                constraints.video.width = {
                    exact: 320,
                };
                constraints.video.height = {
                    exact: 240,
                };
                break;
            case 'VGA':
                constraints.video.width = {
                    exact: 640,
                };
                constraints.video.height = {
                    exact: 480,
                };
                break;
            case 'FULLHD':
                constraints.video.width = {
                    exact: 1920,
                };
                constraints.video.height = {
                    exact: 1080,
                };
                break;
            default:
                constraints.video.width = {
                    min: 640,
                    ideal: 1280,
                    max: 1920,
                };
                constraints.video.height = {
                    min: 480,
                    ideal: 720,
                    max: 1080,
                };
        }

        constraints.audio.echoCancellation = true;
        constraints.audio.googEchoCancellation = true;
        constraints.audio.googEchoCancellation2 = true;
        constraints.audio.googAutoGainControl = true;
        constraints.audio.googAutoGainControl2 = true;
        constraints.audio.googNoiseSuppression = true;
        constraints.audio.googNoiseSuppression2 = true;
        constraints.audio.googHighpassFilter = true;
        constraints.audio.googTypingNoiseDetection = true;

        constraints.video.googSuspendBelowMinBitrate = true;
        constraints.video.googImprovedWifiBwe = true;
        constraints.video.googSkipEncodingUnusedStreams = true;

        return of(constraints);
    }

    changeConstraint(constraint) {
        switch (constraint.type) {
            case 'video':
                this.p2pConnection.removeStream();
                this.p2pConnection.addStream(constraint);
                break;
        }
    }

    sendData(data) {
        this.sendDataChannel.send(JSON.stringify(data));
        return of();
    }

    sendBinaryData(data) {
        this.sendBinaryDataChannel.send(data);
        return of();
    }

    subscribeDataChannel(o) {
        return this.dataChannel.subscribe(o);
    }

    subscribeBinaryDataChannel(o) {
        return this.binaryDataChannel.subscribe(o);
    }

    disconnect() {
        return new Promise((resolve) => {
            const video = document.getElementById(
                'remotevideo'
            ) as HTMLMediaElement;
            video ? (video.src = null) : '';

            const streams = this.p2pConnection.getLocalStreams();
            for (let stream of streams) {
                this.p2pConnection.removeStream(stream);
                stream.getTracks().forEach((track) => {
                    track.stop();
                });
                stream = null;
            }
            this.sendDataChannel ? this.sendDataChannel.close() : '';
            this.sendBinaryDataChannel
                ? this.sendBinaryDataChannel.close()
                : '';
            this.p2pConnection.close();
            resolve();
        });
    }

    renegotiate() {
        return new Promise((resolve) => {
            this.p2pConnection.onnegotiationneeded = (e) => {
                console.log(e);
                resolve();
            };
        });
    }

    setOffer(offer) {
        this.offer = offer;
    }

    getOffer() {
        return this.offer;
    }

    setRemoteUserId(remoteUserId) {
        this.remoteUserId = remoteUserId;
    }

    answerReconnectOffer() {
        //TO DO: this route from old one one/apgar/apps/p2p/p2p.js
        const sub = this._http
            .post('/p2p/signal', {
                type: 'reconnect_answer',
                remoteUserId: this.remoteUserId,
                localUserId: this.userId,
                offer: this.p2pConnection.localDescription,
            })
            .pipe(take(1))
            .subscribe(() => {
                console.log('setupCall sent');
            });
        this._subscriptions.push(sub);
        return sub;
    }

    sendAddStreamOffer() {
        //TO DO: this route from old one one/apgar/apps/p2p/p2p.js
        const sub = this._http
            .post('/p2p/signal', {
                type: 'on_add_media_offer',
                remoteUserId: this.remoteUserId,
                localUserId: this.userId,
                offer: this.p2pConnection.localDescription,
            })
            .pipe(take(1))
            .subscribe(() => {
                console.log('add media offer sent');
            });
        this._subscriptions.push(sub);
        return sub;
    }

    sendRejectCall() {
        //TO DO: this route from old one one/apgar/apps/p2p/p2p.js
        const sub = this._http
            .post('/p2p/signal', {
                type: 'reject_call',
                remoteUserId: this.remoteUserId,
                localUserId: this.userId,
            })
            .pipe(take(1))
            .subscribe(() => {
                console.log('reject call sent');
            });
        this._subscriptions.push(sub);
        return sub;
    }

    sendChangeOffer() {
        //TO DO: this route from old one one/apgar/apps/p2p/p2p.js
        const sub = this._http
            .post('/p2p/signal', {
                type: 'on_add_change_media_offer',
                remoteUserId: this.remoteUserId,
                localUserId: this.userId,
                offer: this.p2pConnection.localDescription,
            })
            .pipe(take(1))
            .subscribe(() => {
                console.log('change media offer sent');
            });
        this._subscriptions.push(sub);
        return sub;
    }

    sendOffer() {
        //TO DO: this route from old one one/apgar/apps/p2p/p2p.js
        const sub = this._http
            .post('/p2p/signal', {
                type: 'on_offer',
                remoteUserId: this.remoteUserId,
                localUserId: this.userId,
                offer: this.p2pConnection.localDescription,
            })
            .pipe(take(1))
            .subscribe(() => {
                console.log('offer sent');
            });
        this._subscriptions.push(sub);
        return sub;
    }

    sendAnswer(answer) {
        //TO DO: this route from old one one/apgar/apps/p2p/p2p.js
        const sub = this._http
            .post('/p2p/signal', {
                type: 'on_answer',
                remoteUserId: this.remoteUserId,
                localUserId: this.userId,
                offer: this.p2pConnection.localDescription,
            })
            .pipe(take(1))
            .subscribe(() => {
                console.log('answer sent', answer);
            });

        this._subscriptions.push(sub);
        return sub;
    }

    changeStream(constraints) {
        return new Promise((resolve) => {
            this.readyToNegotiate.subscribe(() => {
                resolve();
            });

            navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                this.localStream = stream;
                this.p2pConnection.addStream(stream);
            });
        });
    }

    addStream(constraints) {
        return navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                this.localStream = stream;
                this.p2pConnection.addStream(stream);
            });
    }

    createDataChannel() {
        this.sendDataChannel = this.p2pConnection.createDataChannel(
            'sendDataChannel',
            {
                reliable: true,
                ordered: true,
            }
        );

        this.sendBinaryDataChannel = this.p2pConnection.createDataChannel(
            'sendBinaryDataChannel',
            {
                reliable: true,
                ordered: true,
            }
        );
        this.sendBinaryDataChannel.binaryType = 'arraybuffer';

        this.sendDataChannel.onopen = () => {
            if (this.video && this.video.label.toLowerCase().includes('ptz')) {
                // let capabilities = ipcRenderer.sendSync('ptz', {
                //     operations: 'getCapabilities'
                // })
                this.sendDataChannel.send(
                    JSON.stringify({
                        event: 'enable_ptz_controls',
                        data: {},
                    })
                );
            }
        };

        this.p2pConnection.ondatachannel = (event) => {
            const channel = event.channel;
            channel.onmessage = (event) => {
                this.dataChannel.next(event);
            };
        };

        return of();
    }

    setupConnection(config) {
        const iceConfig = {
            iceServers: config.iceServers,
        };
        this.p2pConnection = new RTCPeerConnection(iceConfig);

        this.readyToNegotiate = new Subject();

        this.p2pConnection.onnegotiationneeded = () => {
            this.readyToNegotiate.next();
        };

        this.p2pConnection.oniceconnectionstatechange = () => {
            console.log(
                'ice connection state',
                this.p2pConnection.iceConnectionState
            );
            if (this.p2pConnection.iceConnectionState === 'disconnected') {
                const video = document.getElementById(
                    'remotevideo'
                ) as HTMLMediaElement;
                const streams = this.p2pConnection.getLocalStreams();
                for (let stream of streams) {
                    this.p2pConnection.removeStream(stream);
                    stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                    stream = null;
                }
                video.srcObject = null;
                this.p2pConnection.close();
                this._router.navigate(['/']);
            }
        };

        this.p2pConnection.onaddstream = () => {
            const video = document.getElementsByTagName('video');
            const streams = this.p2pConnection.getRemoteStreams();
            let i = 0;
            for (const stream of streams) {
                video[i].srcObject = stream;
                i++;
            }
        };
        return of();
    }

    setLocalDescription(offer) {
        return this.p2pConnection.setLocalDescription(offer);
    }

    setRemoteDescription(offer) {
        return this.p2pConnection.setRemoteDescription(offer);
    }

    waitForAllIce() {
        return new Promise((resolve) => {
            this.p2pConnection.onicecandidate = (iceEvent) => {
                if (iceEvent.candidate === null) {
                    console.log('ice servers');
                    resolve();
                }
            };
        });
    }

    getIce() {
        //TO DO: this route from old one one/apgar/apps/p2p/p2p.js
        return this._http.post('/p2p/ice', {
            turn: 'twilio',
        });
    }

    acceptAnswer(answer) {
        return this.p2pConnection.setRemoteDescription(answer);
    }

    createAnswer() {
        return this.p2pConnection.createAnswer();
    }
    //TODO: No Usage at all, Do we need this ?
    waitForRenegotiate() {
        this.readyToNegotiate.subscribe(() => {
            return of();
        });
    }

    createOffer() {
        const offerConstraints = {
            iceRestart: false,
            offerToReceiveAudio: 1,
            offerToReceiveVideo: 1,
        };
        return this.p2pConnection.createOffer(offerConstraints);
    }
    //TODO: No Usage at all, Do we need this ?
    dumpStats(results) {
        let statsString = '';
        results.forEach((res) => {
            statsString += '<h3>Report type=';
            statsString += res.type;
            statsString += '</h3>\n';
            statsString += 'id ' + res.id + '<br>\n';
            statsString += 'time ' + res.timestamp + '<br>\n';
            Object.keys(res).forEach((k) => {
                if (k !== 'timestamp' && k !== 'type' && k !== 'id') {
                    statsString += k + ': ' + res[k] + '<br>\n';
                }
            });
        });
        console.log(statsString);
    }
}
