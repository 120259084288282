import { Queue } from '@/shared/queue/queue';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, observeOn, takeWhile } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';
import { FILE_PATH_OS } from '../../constants';
import { FleetLoggerService } from '../fleet-logger';

import {
    IPC_RESPONSE,
    SUB_SYSTEM_TYPES,
    SYSTEM_COMMANDS_TYPES,
} from '@/shared/constants/system-command';

@Injectable({ providedIn: 'root' })
export class LoggerService {
    logLevel = {
        ALL: 0,
        DEBUG: 1,
        INFO: 2,
        WARN: 3,
        ERROR: 4,
        FATAL: 5,
    };
    private _continue = false;
    private _loggerQueue = new Queue();
    private _isInitialized = false;
    private _alive = true;
    constructor(private _fleetLogger: FleetLoggerService) {
        this._continue = true;
    }

    init(): void {
        if (!this._isInitialized) {
            const observable$ = this._loggerQueue.getMessageQueue().pipe(
                takeWhile(() => this._alive),
                distinctUntilChanged(),
                observeOn(asyncScheduler, 3000)
            );
            observable$.subscribe((logObject) => {
                if (this._continue) {
                    this.sendLog(logObject);
                }
            });
            this._isInitialized = true;
        }
    }

    startQueue(): void {
        this._continue = true;
    }

    pauseQueue(): void {
        this._continue = false;
    }

    private registerInQueue(
        type: string,
        message: string,
        detailedInfo: any
    ): void {
        if (this.ignoreLog(message, detailedInfo)) {
            return;
        }
        const logObject = {
            type,
            level: this.logLevel[type],
            message,
            detailedInfo,
            createdAt: new Date().toString(),
        };
        //hide password in logs
        if (
            detailedInfo &&
            detailedInfo.network &&
            detailedInfo.network.password
        ) {
            detailedInfo.network.password = '********';
        }
        this._loggerQueue.sendMessageQueue(logObject);
    }

    private ignoreLog(message: string, detailedInfo: any) {
        switch (message) {
            case IPC_RESPONSE:
                if (typeof detailedInfo === 'string') {
                    try {
                        detailedInfo = JSON.parse(detailedInfo);
                    } catch (ex) {
                        return false;
                    }
                }

                if (
                    detailedInfo.file &&
                    detailedInfo.file.path === FILE_PATH_OS.APP_LOG
                ) {
                    return true;
                }
                if (
                    detailedInfo.network &&
                    detailedInfo.network.subsystem ===
                        SUB_SYSTEM_TYPES.NETWORK_MANAGER &&
                    detailedInfo.network.command ===
                        SYSTEM_COMMANDS_TYPES.GET_NETWORK_STATUS
                ) {
                    return true;
                }
                if (
                    detailedInfo.peripherals &&
                    detailedInfo.peripherals.subsystem ===
                        SUB_SYSTEM_TYPES.TV_CONTROL &&
                    detailedInfo.peripherals.command ===
                        SYSTEM_COMMANDS_TYPES.GET_STATUS
                ) {
                    return true;
                }
        }
        return false;
    }

    private sendLog(logObject: any): void {
        if (!logObject) {
            return;
        }

        const { type, message, detailedInfo } = logObject;
        this._sendLogToFleet(type, message, detailedInfo);
    }

    private _sendLogToFleet(type, message, detailedInfo) {
        const fleetLogType =
            type === 'ERROR' || type === 'FATAL' ? 'logs' : 'info';

        this._fleetLogger.registerInQueue(
            fleetLogType,
            message,
            JSON.stringify(detailedInfo)
        );
    }

    log(message: string, detailedInfo: any = {}): void {
        this.registerInQueue('ALL', message, detailedInfo);
    }

    debug(message: string, detailedInfo: any = {}): void {
        this.registerInQueue('DEBUG', message, detailedInfo);
    }

    info(message: string, detailedInfo: any = {}): void {
        this.registerInQueue('INFO', message, detailedInfo);
    }

    warn(message: string, detailedInfo: any = {}): void {
        this.registerInQueue('WARN', message, detailedInfo);
    }

    error(message: string, detailedInfo: any = {}): void {
        this.registerInQueue('ERROR', message, detailedInfo);
    }

    fatal(message: string, detailedInfo: any = {}): void {
        this.registerInQueue('FATAL', message, detailedInfo);
    }
}
