import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { IAuthenticationResponse } from '@interfaces/api';

@Injectable({ providedIn: 'root' })
export class UserService {
    public isAuthenticated = false;

    constructor(private _http: HttpClient) {}

    getUser(userId): Observable<any> {
        return this._http
            .get('v10/users/' + userId)
            .pipe(
                map((data: IAuthenticationResponse) => {
                    this.isAuthenticated = !!data;
                    return data;
                })
            )
            .pipe(catchError((err: HttpErrorResponse) => throwError(err)));
    }
}
