import { IDeviceInfo } from '@/shared/interfaces';
import { createSelector } from '@ngrx/store';

export const selectDeviceInfo = (state: any): IDeviceInfo => state.device;

export const selectDeviceOnScreenKeyboardOpened = createSelector(
    selectDeviceInfo,
    (device: IDeviceInfo) => device.onScreenKeyboardOpened
);

export const selectDeviceOnHostName = createSelector(
    selectDeviceInfo,
    (device: IDeviceInfo) => device.hostName
);

export const selectDeviceIsSharingVideo = createSelector(
    selectDeviceInfo,
    (device: IDeviceInfo) => device.sharingVideo
);
