import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsInternetConnected } from '@storage/selectors';
import { ERROR_MESSAGES } from '@/shared/constants';
import { catchError } from 'rxjs/operators';
import { authFailure } from '@/shared/storage/auth/auth.actions';

@Injectable()
export class InternetInterceptor implements HttpInterceptor {
    private _isInternetConnected = false;
    constructor(private _store: Store) {
        this._store
            .select(selectIsInternetConnected)
            .subscribe((isInternetConnected) => {
                this._isInternetConnected = isInternetConnected;
            });
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!this._isInternetConnected) {
            return throwError(
                new HttpErrorResponse({
                    error: ERROR_MESSAGES.HTTP_ERROR_NO_INTERNET,
                    url: request.url,
                    status: 500,
                    statusText: ERROR_MESSAGES.HTTP_ERROR_NO_INTERNET,
                })
            );
        } else {
            // else return the normal request
            return next.handle(request).pipe(
                catchError((err: any) => {
                    if (
                        err instanceof HttpErrorResponse &&
                        err.status === 401
                    ) {
                        this._store.dispatch(authFailure());
                    }
                    return throwError(err);
                })
            );
        }
    }
}
