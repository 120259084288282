import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import { Injectable } from '@angular/core';
import { IpcService } from './ipc.service';
import { IIpcResponseHandler } from '../../interfaces';
import { SessionService } from '../session';
import { IpcObserverService } from './ipc-observer.service';

@Injectable({ providedIn: 'root' })
export class UsbResponseService implements IIpcResponseHandler {
    constructor(
        private _sessionService: SessionService,
        private _observerService: IpcObserverService,
        private _ipcService: IpcService
    ) {}
    handleResponse(response): void {
        const { usb: usbResponse } = response;
        if (usbResponse.command === SYSTEM_COMMANDS_TYPES.GET_LIST) {
            this._sessionService.deviceInstance.usbDevices =
                usbResponse.usb_device_list;
            this._sessionService.deviceInstance.setCodecVersion();
            this._sessionService.deviceInstance.reenumerateDevice();
        } else if (
            usbResponse.command === SYSTEM_COMMANDS_TYPES.REENUMERATE_DEVICE
        ) {
            this._observerService.notifyChanges({
                command: SYSTEM_COMMANDS_TYPES.REENUMERATE_DEVICE,
                data: {
                    ...usbResponse,
                    success: usbResponse.success === 'true',
                },
            });
        } else if (usbResponse.event === SYSTEM_COMMANDS_TYPES.HOTPLUG) {
            this._ipcService.requestUsbList();
            this._ipcService.requestAudioControls();
        }
    }
}
