/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AppState } from '@/app.reducer';
import { IAuthenticationResponse } from '@/shared/interfaces';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export interface IAuthState {
    isAuthenticated: boolean;
    isWebsocketConnected: boolean;
    isInternetConnected: boolean;
    isFleetConnected?: boolean;
    authData: IAuthenticationResponse;
    consumer: any;
    provider: any;
    transferProvider: any;
}

export const authInitialState: IAuthState = {
    isAuthenticated: false,
    isWebsocketConnected: false,
    isInternetConnected: true,
    isFleetConnected: true,
    authData: null,
    consumer: null,
    provider: null,
    transferProvider: null,
};

const reducer = createReducer(
    authInitialState,
    on(AuthActions.authSuccess, (state, payload) => ({
        ...state,
        ...payload,
        isAuthenticated: true,
    })),
    on(AuthActions.authFailure, (state) => ({
        ...state,
        isAuthenticated: false,
        authData: null,
    })),
    on(AuthActions.authSetIsWebsocketConnected, (state, payload) => ({
        ...state,
        isWebsocketConnected: payload.isWebsocketConnected,
    })),
    on(AuthActions.authSetIsInternetConnected, (state, payload) => ({
        ...state,
        isInternetConnected: payload.isInternetConnected,
    })),
    on(AuthActions.authSetConsumer, (state, payload) => ({
        ...state,
        consumer: payload.consumer,
    })),
    on(AuthActions.authSetProvider, (state, payload) => ({
        ...state,
        provider: payload.provider,
    })),
    on(AuthActions.authSetTransferProvider, (state, payload) => ({
        ...state,
        transferProvider: payload.transferProvider,
    })),
    on(AuthActions.authSetIsFleetConnected, (state, payload) => ({
        ...state,
        isFleetConnected: payload.isFleetConnected,
    }))
);

export function AuthReducer(
    state: IAuthState | undefined,
    action: Action
): IAuthState {
    return reducer(state, action);
}

export const selectAuthState = (state: AppState): IAuthState => state.auth;

export const selectConsumer = createSelector(
    selectAuthState,
    (state: IAuthState) => state.consumer
);

export const selectProvider = createSelector(
    selectAuthState,
    (state: IAuthState) => state.provider
);

export const selectTransferProvider = createSelector(
    selectAuthState,
    (state: IAuthState) => state.transferProvider
);

export const selectAuthData = createSelector(
    selectAuthState,
    (state: IAuthState) => state.authData || {}
);
