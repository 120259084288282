import { Injectable } from '@angular/core';
import { SessionService } from '../session';
import { IIpcResponseHandler } from '../../interfaces';
import { StorageService } from '../storage';
import { MetricService } from '../metrics';
import { LoggerService } from '../logger';
import { IpcObserverService } from './ipc-observer.service';
import {
    SUB_SYSTEM_TYPES,
    SYSTEM_COMMANDS_TYPES,
} from '@/shared/constants/system-command';

@Injectable({ providedIn: 'root' })
export class SpineResponseService implements IIpcResponseHandler {
    constructor(
        private _storageService: StorageService,
        private _sessionService: SessionService,
        private _metricService: MetricService,
        private _loggerService: LoggerService,
        private _observerService: IpcObserverService
    ) {}
    handleResponse(response): void {
        const instance = this._sessionService.deviceInstance;
        switch (response.spine_comms.subsystem) {
            case SUB_SYSTEM_TYPES.SPINE_MANAGER:
                switch (response.spine_comms.module) {
                    case 'EVENT':
                        switch (response.spine_comms.event) {
                            case SYSTEM_COMMANDS_TYPES.HEADPHONES_DETECT:
                            case SYSTEM_COMMANDS_TYPES.LINE_IN_DETECT:
                            case SYSTEM_COMMANDS_TYPES.HEADSET_MIC_DETECT:
                                this._observerService.notifyChanges({
                                    command: response.spine_comms.event,
                                    data: {
                                        isConnected:
                                            response.spine_comms.event_data ===
                                            'true',
                                    },
                                });
                                break;
                            case SYSTEM_COMMANDS_TYPES.PERSON_DETECTED:
                                if (
                                    instance &&
                                    instance.standbyState &&
                                    this._sessionService.getConfig()
                                        .proximity_wake
                                ) {
                                    this._observerService.notifyChanges({
                                        command:
                                            SYSTEM_COMMANDS_TYPES.STANDBY_STATE,
                                        data: { state: false },
                                    });
                                }
                                break;
                            case SYSTEM_COMMANDS_TYPES.POWER_BUTTON:
                                if (
                                    response.spine_comms.event_data ===
                                        'SHORT_PRESS' &&
                                    !instance.onCall
                                ) {
                                    this._observerService.notifyChanges({
                                        command:
                                            SYSTEM_COMMANDS_TYPES.STANDBY_STATE,
                                        data: { state: !instance.standbyState },
                                    });
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case SYSTEM_COMMANDS_TYPES.NERVE:
                        if (response.spine_comms.success) {
                            const responseData =
                                response.spine_comms.response_data;
                            switch (response.spine_comms.command) {
                                case SYSTEM_COMMANDS_TYPES.NERVE_GET_DETECTED:
                                    if (
                                        responseData &&
                                        responseData.nerve_devices_list &&
                                        responseData.nerve_devices_list.includes(
                                            'RIPPLE'
                                        )
                                    ) {
                                        this._observerService.notifyChanges({
                                            command:
                                                SYSTEM_COMMANDS_TYPES.NERVE_GET_DETECTED,
                                        });
                                    } else {
                                        const powerStatus = {
                                            batteryConnected: false,
                                            acPowerConnected: true,
                                        };

                                        this._storageService.updateBatteryStatus(
                                            powerStatus
                                        );
                                        this._metricService.enqueue(
                                            powerStatus
                                        );
                                    }
                                    break;
                                case SYSTEM_COMMANDS_TYPES.RIPPLE_GET_AC_BATT_INFO:
                                    if (responseData) {
                                        this._storageService.updateBatteryStatus(
                                            this._sessionService.deviceInstance.handleBatteryResponse(
                                                responseData
                                            )
                                        );
                                    }
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            this._loggerService.error(
                                'ERROR_SPINE_NERVE_RESPONSE',
                                JSON.stringify(response)
                            );
                        }
                        break;
                    case SYSTEM_COMMANDS_TYPES.CONFIG:
                        if (
                            response.spine_comms.success &&
                            response.spine_comms.response_data
                        ) {
                            this._metricService.enqueue({
                                device_serial_number:
                                    response.spine_comms.response_data
                                        .serial_number,
                            });
                        }
                        break;
                }
        }
    }
}
