import { Injectable } from '@angular/core';
import { IIpcResponseHandler } from '../../interfaces';

@Injectable({ providedIn: 'root' })
export class DefaultResponseService implements IIpcResponseHandler {
    // constructor() {}
    handleResponse(response): any {
        return response;
    }
}
