<div [formGroup]="staticIpSettingForm">
    <aw-input
        label="IP Address"
        formControlName="address"
        styleClasses="js-keyboard-input js-non-capitalize"
        (ngModelChange)="onChangeConnectionSettings()"
    >
    </aw-input>
    <aw-input
        label="Gateway"
        formControlName="gateway"
        styleClasses="js-keyboard-input js-non-capitalize"
        (ngModelChange)="onChangeConnectionSettings()"
    >
    </aw-input>
    <aw-input
        label="Prefix"
        formControlName="prefix"
        styleClasses="js-keyboard-input js-non-capitalize"
        (ngModelChange)="onChangeConnectionSettings()"
    >
    </aw-input>
</div>
