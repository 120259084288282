import { AppState } from '@/app.reducer';
import { IVideoCall } from '@/shared/interfaces';
import { createSelector } from '@ngrx/store';

export const selectVideoCall = (state: AppState): IVideoCall => state.video;

export const selectIsSharing = createSelector(
    selectVideoCall,
    (video: IVideoCall) => video.isSharing
);

export const selectIsRecording = createSelector(
    selectVideoCall,
    (video: IVideoCall) => video.isRecording
);

export const selectTakeSnapshot = createSelector(
    selectVideoCall,
    (video: IVideoCall) => video.takeSnapshot
);

export const selectAllowSnapshot = createSelector(
    selectVideoCall,
    (video: IVideoCall) => video.allowSnapshot
);

export const selectAllowStethRecording = createSelector(
    selectVideoCall,
    (video: IVideoCall) => video.allowStethRecording
);

export const selectDeviceSharingInfo = createSelector(
    selectVideoCall,
    (video: IVideoCall) => video.sharingDevice
);
