import { Injectable, OnDestroy } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { StorageService } from '../storage';
import { map, take, takeWhile, withLatestFrom } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements OnDestroy {
    private authenticate$ = this._storageService.subscribeOnAuthIsAuthenticated(
        false
    ) as Observable<unknown>;

    constructor(private _storageService: StorageService) {
        interval(30 * 1000)
            .pipe(
                take(3),
                withLatestFrom(this.authenticate$),
                map((values) => values[1]),
                takeWhile((isAuthenticated) => !isAuthenticated)
            )
            .subscribe(
                () => {
                    this._storageService.authenticate();
                },
                // eslint-disable-next-line no-console
                (error) => console.error(error)
                // () => {
                //     console.log('..go to error generic 1')
                //     this._router.navigateByUrl('/error-generic', {
                //         state: { error: 'AUTH_ERROR' },
                //     })
                // }
            );
    }
    ngOnDestroy(): void {
        // this._subscriptions.forEach((s) => s.unsubscribe());
    }

    logout(): void {
        this._storageService.logout();
    }
}
