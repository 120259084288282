import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-lost-network-modal',
    templateUrl: './lost-network-modal.component.html',
})
export class LostNetworkModalComponent {
    constructor(public dialogRef: MatDialogRef<LostNetworkModalComponent>) {}
}
