import { BatteryAlertPopupInfo } from '../interfaces/battery';

export const BatteryAlertNone: BatteryAlertPopupInfo = {
    title: 'Shutdown',
    body: 'Your device has less than 5% battery. Please connect a power source immediately to continue using. <br><br>Your device will power down soon.',
    showFooter: false,
};

export const BatteryAlertCritical: BatteryAlertPopupInfo = {
    title: 'Critical Battery',
    body: 'Your device has less than 10% battery and is in danger of powering down. Please plug in your device immediately.',
    showFooter: true,
};

export const BatterAlertLow: BatteryAlertPopupInfo = {
    title: 'Low Battery',
    body: 'Your device has less than 30% battery. Please plug in your device now.',
    showFooter: true,
};
