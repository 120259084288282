import { IAuthState } from '@/shared/storage';

export const TEST_AUTH_DATA: IAuthState = {
    isAuthenticated: false,
    isWebsocketConnected: false,
    isInternetConnected: false,
    authData: undefined,
    consumer: undefined,
    provider: undefined,
    transferProvider: undefined,
};
