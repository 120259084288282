export const SUBSCRIBERS = {
    //     UPDATE_CONFIG: new Map(),
    //     GET_DEVICE_INFO: new Map(),
    //     GET_NETWORK_CONFIG: new Map(),
    //     GET_DNS_SERVERS: new Map(),
    //     SET_IP_CONFIG: new Map(),
    //     ADD_SERVER_ARRAY_TO_DNS: new Map(),
    //     SET_ETHERNET_IP_CONFIG: new Map(),
    //     GET_POWERSTATS_COMMAND: new Map(),
    //     AUDIO_CONNECTIONS_DATA: new Map(),
    //     GET_WIFI_LIST: new Map(),
    //     DELETE_NETWORK: new Map(),
    //     HEADPHONES_DETECT: new Map(),
    //     HEADSET_MIC_DETECT: new Map(),
    //     LINE_IN_DETECT: new Map(),
    //     AUDIO_VIDEO_CHANGE: new Map(),
    //     CANCEL_CASE: new Map(),
    //     ADD_DOMAIN_TO_DNS: new Map(),
    //     GET_SYSTEM_INFO: new Map(),
    //     NERVE_GET_DETECTED: new Map(),
    //     SET_HOSTNAME: new Map(),
    //     SET_LOCKED_BAND: new Map(),
    //     CONNECT_WIFI: new Map(),
    //     STANDBY_STAE: new Map(),
    //     TV_READY: new Map(),
    //     HOTPLUG: new Map(),
    //     MAKE_CALL: new Map(),
    //     MAKE_CALL_OBSERVER: new Map(),
    //     MAKE_CALL_INTERACTIVE: new Map(),
    //     UNLOCK_SETTINGS: new Map(),
    //     UNLOCK_SCREEN: new Map(),
    //     SET_ROAMING_THRESHOLD: new Map(),
};
