import { Injectable } from '@angular/core';
import { IIpcResponseHandler } from '../../interfaces';
import { SessionService } from '../session';
import { MetricService } from '../metrics';
import { AmwellTv } from '@/shared/device/amwell-tv';
import { IpcObserverService } from './ipc-observer.service';
import { StorageService } from '../storage';
import {
    SUB_SYSTEM_TYPES,
    SYSTEM_COMMANDS_TYPES,
} from '@/shared/constants/system-command';

@Injectable({ providedIn: 'root' })
export class PeripheralResponseService implements IIpcResponseHandler {
    constructor(
        private _observerService: IpcObserverService,
        private _sessionService: SessionService,
        private _metricsService: MetricService,
        private _storageService: StorageService
    ) {}
    handleResponse(response): void {
        const data = response.peripherals;
        switch (response.peripherals.subsystem) {
            case SUB_SYSTEM_TYPES.TV_CONTROL:
                switch (response.peripherals.command) {
                    case SYSTEM_COMMANDS_TYPES.EVENT:
                    case SYSTEM_COMMANDS_TYPES.GET_STATUS:
                        switch (this._sessionService.deviceInstance.type) {
                            case 'intmtvkit': // TV
                                const instance = this._sessionService
                                    .deviceInstance as AmwellTv;
                                instance.deviceStatus = response.peripherals;
                                this._metricsService.enqueue({
                                    tv_connection: data.attached,
                                    tv_power: data.power,
                                    tv_source: data.source,
                                    tv_volume: data.volume,
                                    tv_mute: data.mute,
                                    tv_valid_signal: data.valid_signal,
                                });
                                break;
                        }

                        break;
                    case SYSTEM_COMMANDS_TYPES.TURN_ON:
                        this._observerService.notifyChanges({
                            command: SYSTEM_COMMANDS_TYPES.TURN_ON,
                            data,
                        });
                        break;
                    case SYSTEM_COMMANDS_TYPES.SWITCH_TO_PRIMARY_SOURCE:
                        this._observerService.notifyChanges({
                            command:
                                SYSTEM_COMMANDS_TYPES.SWITCH_TO_PRIMARY_SOURCE,
                            data: { ...data, success: data.success === 'true' },
                        });
                        break;
                }
                break;
            case SUB_SYSTEM_TYPES.EMBRAVA_LIGHT:
                switch (response.peripherals.command) {
                    case SYSTEM_COMMANDS_TYPES.GET_STATUS:
                    case SYSTEM_COMMANDS_TYPES.EVENT:
                        this._metricsService.enqueue({
                            led_connection: data.attached,
                        });
                        break;
                    case SYSTEM_COMMANDS_TYPES.TURN_ON:
                    case SYSTEM_COMMANDS_TYPES.TURN_OFF:
                        this._metricsService.enqueue({
                            led_state: data.success,
                        });
                        break;
                }
                break;
        }
    }
}
