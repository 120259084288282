<div class="col s4 border-right">
    <div class="flex justify-between items-center m-b15">
        <h3 class="m-b0">Select Network</h3>
        <a class="txt-decoration-none linl-join" automation="joinAnotherNetwork" (click)="joinAnotherNetwork()">
            Join Another Network</a
        >
    </div>
    <div class="bordered">
        <div class="collection striped" *ngIf="networkManager">
            <a
                automation="selectWifiLink"
                class="collection-item ic-left"
                *ngFor="let wifi of networkManager?.network_list"
                (click)="onItemClick(wifi)"
                [class.active]="wifi.active"
            >
                <div *ngIf="wifi.signal >= 80 && wifi.signal <= 100">
                    <ng-container
                        *ngTemplateOutlet="
                            wifiLinkItem;
                            context: { extraClass: 'icon-baseline-signal_wifi_4_bar-24px' }
                        "
                    ></ng-container>
                </div>
                <div *ngIf="wifi.signal >= 60 && wifi.signal < 80">
                    <ng-container
                        *ngTemplateOutlet="
                            wifiLinkItem;
                            context: { extraClass: 'icon-baseline-signal_wifi_3_bar-24px' }
                        "
                    ></ng-container>
                </div>
                <div *ngIf="wifi.signal >= 40 && wifi.signal < 60">
                    <ng-container
                        *ngTemplateOutlet="
                            wifiLinkItem;
                            context: { extraClass: 'icon-baseline-signal_wifi_2_bar-24px' }
                        "
                    ></ng-container>
                </div>
                <div *ngIf="wifi.signal >= 20 && wifi.signal < 40">
                    <ng-container
                        *ngTemplateOutlet="
                            wifiLinkItem;
                            context: { extraClass: 'icon-baseline-signal_wifi_1_bar-24px' }
                        "
                    ></ng-container>
                </div>
                <div *ngIf="wifi.signal >= 0 && wifi.signal < 20">
                    <ng-container
                        *ngTemplateOutlet="
                            wifiLinkItem;
                            context: { extraClass: 'icon-baseline-signal_wifi_0_bar-24px' }
                        "
                    ></ng-container>
                </div>
                {{ wifi.ssid }}
                <i class="material-icons right check" *ngIf="selectedWifi && wifi.ssid === selectedWifi.ssid">check</i>
            </a>
        </div>
        <div class="btn-wrap">
            <aw-button
                automationValue="refreshNetworkButton"
                styleClasses="btn btn-primary waves-effect waves-light btn-large btn-fullwidth"
                [disabled]="refreshInProgress"
                (onClick)="refreshNetwork()"
            >
                <i class="material-icons">refresh</i>
                Refresh list
            </aw-button>
        </div>
    </div>
</div>
<div class="col s4" *ngIf="!joinAnotherNetworkClicked">
    <div class="flex justify-between items-center m-b15" *ngIf="!externalJoinStaticIPStatus">
        <h3 class="m-b2" automation="wifiSsid">{{ selectedWifi.ssid }}</h3>
    </div>
    <div class="scrollable-wifi-settings pl1" [class.scrollable-staticip-settings]="externalJoinStaticIPStatus">
        <div [formGroup]="wifiConfigForm">
            <div class="before-connect form" *ngIf="!externalJoinStaticIPStatus">
                <p class="gray-mid-text" *ngIf="showNetworkText">
                    <i class="material-icons">lock</i>Please enter network credentials
                </p>
                <div class="input-top-space-0">
                    <aw-input
                        automationValue="wifiFormUsername"
                        *ngIf="selectedWifi.needs_username && !selectedWifi.stored"
                        styleClasses="js-keyboard-input"
                        elementId="username"
                        label="Username"
                        type="text"
                        required="true"
                        formControlName="username"
                    >
                    </aw-input>
                </div>
            </div>
            <div class="input-top-space-0">
                <aw-input
                    automationValue="wifiFormPassword"
                    *ngIf="selectedWifi.needs_password && !selectedWifi.stored"
                    styleClasses="js-keyboard-input"
                    elementId="password"
                    label="Password"
                    type="password"
                    required="true"
                    formControlName="password"
                >
                </aw-input>
            </div>
            <!--            -->
            <aw-button
                automationValue="joinButton"
                *ngIf="!selectedWifi.active || (selectedWifi.active && isChanged)"
                styleClasses="btn btn-primary waves-effect waves-light btn-large btn-fullwidth"
                (onClick)="joinWifiNetwork()"
                [disabled]="!isValidToJoinWifi"
            >
                Join
            </aw-button>
            <aw-button
                automationValue="connectedButton"
                *ngIf="selectedWifi.active && !isChanged"
                styleClasses="btn btn-primary btn-large btn-fullwidth"
            >
                <i class="material-icons white-text">done</i>
                Connected
            </aw-button>
            <aw-button
                automationValue="forgetNetworkButton"
                elementId="forgetNetworkButton"
                *ngIf="selectedWifi.active"
                styleClasses="btn btn-simple btn-large btn-fullwidth m-t2 flex justify-center"
                (onClick)="onOpenForgotPopup()"
            >
                Forget Network
            </aw-button>

            <div class="coll s12 m12 pt3">
                <aw-expansion expansionType="'accordion'">
                    <aw-expansion-item>
                        <ng-container aw-expansion-title> Advanced Settings </ng-container>
                        <ng-container aw-expansion-body>
                            <div class="ip-config form relative">
                                <div class="row">
                                    <div class="col s12 m12 pb0">
                                        <aw-radio-button
                                            *ngFor="let cm of connectionMethods"
                                            (change)="onChangeConnectionMethod()"
                                            formControlName="connectionMethod"
                                            name="cm"
                                            [radioValue]="cm.value"
                                        >
                                            {{ cm.name }}
                                        </aw-radio-button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s12 m12 pt0">
                                        <div
                                            class="input-field m-t35"
                                            *ngIf="wifiConfigForm.value.connectionMethod === addressProtocolTypeStatic"
                                        >
                                            <app-static-ip-config
                                                [defaultStaticIpSetting]="defaultStaticIpSetting"
                                                (onChangeDetected)="onStaticIpSettingChange($event)"
                                            >
                                            </app-static-ip-config>
                                        </div>
                                        <app-dns-config
                                            [addDns]="addDNS"
                                            [dnsSettingsForm]="wifiConfigForm"
                                            (isDnsChanged)="isDnsChanged($event)"
                                        >
                                        </app-dns-config>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </aw-expansion-item>
                </aw-expansion>
            </div>
        </div>
        <!-- Wi-Fi Advanced Settings with Automatic/DHCP and Manual/Static Toggle -->
        <!-- begin advanced -->
        <div class="m-t3 pt2 border-top">
            <h3 class="m-b2">Network Information</h3>
            <ng-container
                *ngTemplateOutlet="
                    networkInformationItem;
                    context: { title: 'Security', automation: 'security', value: selectedWifi.security }
                "
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    networkInformationItem;
                    context: { title: 'BSSID', automation: 'bssid', value: selectedWifi.bssid }
                "
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    networkInformationItem;
                    context: {
                        title: 'IP Address',
                        automation: 'address',
                        value: wifiAdresess,
                        isWordBreak: true
                    }
                "
            ></ng-container>
            <div class="m-t3 pt2" *ngIf="selectedWifi.active && bandConfiguration.length >= 1">
                <h6 class="m-b2">Band</h6>
                <p class="band-text">
                    <em
                        >This setting will configure the wireless driver to only use a specific frequency band. Auto
                        should be chosen unless a specific band is recommended by your IT team.</em
                    >
                </p>
                <div class="row m-b1">
                    <div class="col s12 py0">
                        <div class="col s4" *ngFor="let band of bandConfiguration">
                            <aw-radio-button
                                automationValue="band{{ band.displayText }}"
                                [radioValue]="band.value"
                                [(ngModel)]="lockedBand"
                                [disabled]="disableBand"
                                (onClick)="setWifiBand(band.value)"
                                name="band"
                                ngDefaultControl
                            >
                                {{ band.displayText }}
                            </aw-radio-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p class="m0" *ngIf="joinWifiStatus" automation="joinWifiStatus">
        <i class="material-icons m-r2 success-text">check_circle</i>{{ joinWifiStatus }}
    </p>
    <!-- end advanced -->
</div>

<div class="col s4" *ngIf="joinAnotherNetworkClicked">
    <h3 class="m-b3">Join Another Network</h3>
    <div class="before-connect form" [class.scrollable-staticip-settings]="joinStaticIPStatus" style="overflow: scroll">
        <p class="gray-mid-text"><i class="material-icons">lock</i>Please enter network credentials</p>
        <div [class.isShowKeyboard]="isShowKeyboard" [formGroup]="anotherNetworkForm">
            <div class="input-top-space-0">
                <aw-input
                    automationValue="network"
                    styleClasses="js-keyboard-input"
                    id="hiddenSSID"
                    formControlName="hiddenSSID"
                    label="Network name"
                    type="text"
                    required="true"
                >
                </aw-input>
            </div>
            <div class="input-top-space-0">
                <aw-select
                    automationValue="encryption"
                    label="Choose encryption"
                    name="encryption"
                    formControlName="encryption"
                    [options]="encryptionOptions"
                    (onChange)="onChangeEncryption($event)"
                >
                </aw-select>
            </div>
            <div class="input-top-space-0">
                <aw-input
                    automationValue="networkFormUsername"
                    *ngIf="requireUsername"
                    (onBlur)="isShowKeyboard = false"
                    (onFocus)="isShowKeyboard = true"
                    styleClasses="js-keyboard-input"
                    elementId="username"
                    formControlName="username"
                    label="Username"
                    type="text"
                    required="true"
                >
                </aw-input>
            </div>
            <div class="input-top-space-0">
                <aw-input
                    automationValue="networkFormPassword"
                    (onBlur)="isShowKeyboard = false"
                    (onFocus)="isShowKeyboard = true"
                    *ngIf="requirePassword"
                    type="password"
                    label="Password"
                    required="true"
                    elementId="password"
                    styleClasses="js-keyboard-input"
                    formControlName="password"
                >
                </aw-input>
            </div>
        </div>
        <aw-button
            automationValue="joinNetworkButton"
            *ngIf="!externalJoinStaticIPStatus"
            styleClasses="btn btn-primary waves-effect waves-light btn-large btn-fullwidth m-b15 m-t15"
            [disabled]="!validateForm(true)"
            (onClick)="joinNetworkManually()"
        >
            Join
        </aw-button>
        <p class="m0" *ngIf="joinWifiStatus" automation="joinWifiStatus">
            <i class="material-icons m-r2 success-text">check_circle</i>{{ joinWifiStatus }}
        </p>
    </div>
</div>

<!-- RE-USED at Network Information -->
<ng-template
    #networkInformationItem
    let-title="title"
    let-automation="automation"
    let-value="value"
    let-isWordBreak="isWordBreak"
>
    <div class="row m-b1">
        <div class="col s12 m5 py0">{{ title }}</div>
        <div class="col s12 m7 py0 gray-mid-text" [class.word-break]="isWordBreak" automation="{{ automation }}">
            {{ value }}
        </div>
    </div>
</ng-template>

<!-- RE-USED at Select Network -->
<ng-template #wifiLinkItem let-extraClass="extraClass">
    <span class="icon layered {{ extraClass }}">
        <span class="path1"></span>
        <span class="path2"></span>
    </span>
</ng-template>
