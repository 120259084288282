<div class="modal-content-wrapper">
    <div class="modal-heading no-border px3 py3" *ngIf="data.title || data.icon">
        <h4 class="flex items-center">
            <i *ngIf="data.icon" class="material-icons large-material m-r2">{{ data.icon }}</i>
            {{ data.title }}
        </h4>
    </div>
    <div class="modal-content px3" [innerHTML]="data.body"></div>
    <div class="modal-footer pt2 pb3 px3">
        <aw-button [mat-dialog-close]="true" styleClasses="btn btn-danger m-l2">{{ data.confirmText }}</aw-button>
        <aw-button [mat-dialog-close] styleClasses="btn-flat btn-primary m-l2">{{ data.cancelText }}</aw-button>
    </div>
</div>
