import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@/shared/shared.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { WebsocketModule } from './websocket';
import { CoreModule } from './core';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';
import appConfig from 'src/assets/config/app-config.json';

import { AppComponent } from './app.component';

import {
    EndpointConfigEffects,
    MediaDevicesEffects,
    AuthEffects,
    LayoutEffects,
    BatteryEffects,
} from './shared/storage';

import * as fromAppReducer from './app.reducer';

import { AppInitService } from './shared/services/app-init/app-init.service';
import {
    EndPointService,
    FleetHandlerService,
    IpcResponseService,
    IpcService,
    SessionService,
    StorageService,
    ActivityMonitorService,
} from './shared/services';

import { LoggerService } from './shared/services/logger/logger.service';
import { MetricService } from './shared/services/metrics/metrics.service';
import { FleetLoggerService } from './shared/services/fleet-logger/fleet-logger.service';

import {
    AwButtonModule,
    AwDatePickerModule,
    AwExpansionModule,
    AwIndicatorModule,
    AwInputModule,
    AwKeyboardModule,
    AwMenuModule,
    AwRadioModule,
    AwRatingModule,
    AwSelectModule,
    AwSliderModule,
    AwSoundMeterModule,
    AwSpinnerModule,
    AwSwitchModule,
    AwTabsModule,
    AwTextareaModule,
    AwTimePickerModule,
    AwNotificationsCarouselModule,
} from '@aw-hospital/aw-components-lib';
import { ServiceConfig } from '@aw/vms-client';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CheckForUpdateService } from './shared/services/check-for-update/check-for-update.service';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        RouterModule,
        CoreModule,
        WebsocketModule.config({
            url: environment.ws,
        }),
        MatDialogModule,

        // Amwell Components
        AwButtonModule,
        AwDatePickerModule,
        AwExpansionModule,
        AwKeyboardModule,
        AwIndicatorModule,
        AwInputModule,
        AwMenuModule,
        AwRadioModule,
        AwRatingModule,
        AwSelectModule,
        AwSliderModule,
        AwSoundMeterModule,
        AwSpinnerModule,
        AwSwitchModule,
        AwTabsModule,
        AwTextareaModule,
        AwTimePickerModule,
        AwNotificationsCarouselModule,

        WebsocketModule.config({
            url: environment.ws,
        }),
        StoreModule.forRoot(fromAppReducer.appReducer),
        EffectsModule.forRoot([
            EndpointConfigEffects,
            MediaDevicesEffects,
            AuthEffects,
            LayoutEffects,
            BatteryEffects,
        ]),
        StoreDevtoolsModule.instrument({
            logOnly: environment.production,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.enabledAutoUpdate,
        }),
    ],
    providers: [
        {
            provide: MatDialogRef,
            useValue: {},
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitService: AppInitService) => () => {
                appInitService.bootStrapApp();
            },
            deps: [
                AppInitService,
                FleetHandlerService,
                SessionService,
                EndPointService,
                StorageService,
                FleetHandlerService,
                LoggerService,
                FleetLoggerService,
                MetricService,
                IpcService,
                IpcResponseService,
                ActivityMonitorService,
                Store,
            ],
            multi: true,
        },
        {
            provide: ServiceConfig,
            useFactory: () => {
                return {
                    endpoints: [
                        {
                            name: 'createOrUpdateParticipant',
                            url: `${appConfig.vmsServiceUrl}/api/participant`,
                            method: 'POST',
                        },
                        {
                            name: 'getLiteToken',
                            url: `${appConfig.authServiceUrl}/jwt/amwell/lite`,
                        },
                    ],
                };
            },
        },
    ],
    bootstrap: [AppComponent],
    exports: [BrowserModule, BrowserAnimationsModule, SharedModule],
})
export class AppModule {
    constructor(private _checkForUpdateService: CheckForUpdateService) {
        !!environment.enabledAutoUpdate && this._checkForUpdateService.init();
    }
}
