import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { selectDeviceOnScreenKeyboardOpened } from '@/shared/storage/selectors/device-selectors';

@Component({
    selector: 'app-settings-pin-modal',
    templateUrl: './settings-pin-modal.component.html',
})
export class SettingsPinModalComponent {
    public isKeyboardOpened$ = this._store.select(
        selectDeviceOnScreenKeyboardOpened
    );

    constructor(
        public dialogRef: MatDialogRef<SettingsPinModalComponent>,
        private _store: Store
    ) {}

    gotoDashboard(event: MouseEvent): void {
        event.stopPropagation();
        this.dialogRef.close();
    }

    handlerSuccess() {
        this.dialogRef.close(true);
        // TODO check how settings pin code works with Settings
    }
}
