import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import { AppInitService } from '@/shared/services/app-init/app-init.service';
import { of } from 'rxjs';
import { LoggerService } from '@/shared/services/logger';

@Injectable()
export class AuthEffects {
    constructor(
        private _action$: Actions,
        private _appInitService: AppInitService,
        private _loggerService: LoggerService
    ) {}

    authenticate$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthActions.authenticate),
            exhaustMap(() => {
                return this._appInitService.processAuthenticationRequest().pipe(
                    tap(() => this._appInitService.initializeServices()),
                    map((authResponse) => {
                        return AuthActions.authSuccess({
                            authData: authResponse[0],
                        });
                    }),
                    catchError((error) => {
                        this._loggerService.info(
                            'authentication failed',
                            error
                        );
                        return of(AuthActions.authFailure());
                    })
                );
            })
        )
    );
}
