<div class="empty-dash-screen overflow-hidden pb4 primary h100">
    <header class="navbar top0 left0 w100">
        <menu class="m-y0 pl0 pt2 pr2 flex justify-end"></menu>
    </header>
    <div class="flex items-center justify-center w100 visible h100 flex-column">
        <div
            class="
                white
                px35
                pt4
                pb35
                no-workflow-block
                text-center
                login-white
                training-room
                rel
                flex
                justify-center
                items-center
                flex-column
            "
        >
            <div class="flex pt4 m-b2">
                <span class="icon icon-desktop-cart-w-software-black lg-icon"></span>
            </div>
            <h1 class="m-b2" *ngIf="endpoint.name">{{ endpoint.name }}</h1>
            <h2 class="gray-mid-text m-b2">is ready to receive a call</h2>
            <div class="flex justify-center items-center w100 bottom0 left0 charge-block p0 m-t35">
                <span>
                    <span class="path1"></span>
                    <span class="path2"></span>
                </span>
                <span class="m-b0 m-l2 flex gray-mid-text" *ngIf="hasBattery">
                    <span><battery-manager [showPercentage]="false"></battery-manager></span>
                    <span class="m-l2 m-t1"><h4>Battery Charge</h4></span>
                    <span class="m-t1"><battery-manager [showBatteryIcon]="false"></battery-manager></span>
                </span>
            </div>
        </div>
        <div class="img-holder flex pt4 items-center">
            <span class="white-text m-r2"> Powered by </span>
            <img class="right powered-by" src="assets/images/Amwell-Gold.svg" />
        </div>
    </div>
</div>
