import { IBatteryStatus } from '../interfaces';

import { DeviceBase } from './device-base';
// eslint-disable-next-line import/no-extraneous-dependencies
import meanBy from 'lodash/meanBy';
// eslint-disable-next-line import/no-extraneous-dependencies
import find from 'lodash/find';

import { AUDIO_DEVICE_TYPE, FECC_DEVICES, FECC_POSITION } from '../constants';
import { SYSTEM_COMMANDS_TYPES } from '../constants/system-command';
export class AmwellCart250 extends DeviceBase {
    private _done;
    private _connectedVendorId;
    private _connectedProductId;
    private _tabletBatteryConnected;
    private _tabletBatteryResponse;
    private _acPowerConnected;
    private _tabletBattery;

    init(): void {
        if (this._done) {
            return;
        } // Onboot can be called more than once,  check already executed

        this._done = true;
        super.init();
        this.getSystemInfo();
    }

    getSystemInfo(): void {
        this._ipcService.requestPowerStatus();
        this._ipcService.requestAudioControls();
        this._ipcService.requestCradleStats();
    }

    resetState(): void {
        // go to privacy mode
        this._ptzService.setFeccPosition(FECC_POSITION.PRIVACY);
    }

    onConfigUpdate(): void {
        this.setCodecVersion();
    }

    getDefaultDevice(device, deviceType): any {
        let defaultDevice = null;
        if (typeof device === 'string') {
            defaultDevice = this.audioDevices.find(
                (a) => a.device_type === deviceType && a.description === device
            );
        } else {
            defaultDevice = this.audioDevices.find(
                (d) =>
                    d.vid === device.vendorId &&
                    d.pid === device.productId &&
                    d.device_type === deviceType
            );
        }

        return defaultDevice;
    }

    setMicGain(input): void {
        // This condition for RemoteAVSettings from Clinical Module.
        // We are taking the previously set Vendor & ProductId since we dont get it through fleet
        /*********/
        if (!input.vendorId) {
            input.vendorId = this._connectedVendorId;
        }
        if (!input.productId) {
            input.productId = this._connectedProductId;
        }
        /*********/

        const defaultDevice = this.getDefaultDevice(
            input,
            AUDIO_DEVICE_TYPE.SOURCE
        );
        if (defaultDevice) {
            defaultDevice.volume =
                input.volume >= 0
                    ? input.volume
                    : this._sessionService.getConfig().AudioInputDefaultGain !==
                      undefined
                    ? this._sessionService.getConfig().AudioInputDefaultGain
                    : 50;
            super.setMicGain(defaultDevice);
        }

        this._connectedVendorId = input.productId;
        this._connectedProductId = input.vendorId;
    }

    setSpeakerGain(input): void {
        // This condition for RemoteAVSettings from Clinical Module.
        // We are taking the previously set Vendor & ProductId since we dont get it through fleet
        /*********/
        if (!input.vendorId) {
            input.vendorId = this._connectedVendorId;
        }
        if (!input.productId) {
            input.productId = this._connectedProductId;
        }
        /*********/
        const defaultDevice = this.getDefaultDevice(
            input,
            AUDIO_DEVICE_TYPE.SINK
        );
        if (defaultDevice) {
            defaultDevice.volume = input.volume;
            super.setSpeakerGain(defaultDevice);
        }

        this._connectedProductId = input.productId;
        this._connectedVendorId = input.vendorId;
    }

    setAudioDefaultSink(device) {
        const defaultDevice = this.getDefaultDevice(
            device,
            AUDIO_DEVICE_TYPE.SINK
        );
        if (defaultDevice) {
            const { ctrl_name: ctrlName } = defaultDevice;
            this._ipcService.setAudioDefaultSink(ctrlName);
        }
    }

    setAudioDefaultSource(device) {
        const defaultDevice = this.getDefaultDevice(
            device,
            AUDIO_DEVICE_TYPE.SOURCE
        );
        if (defaultDevice) {
            const { ctrl_name: ctrlName } = defaultDevice;
            this._ipcService.setAudioDefaultSource(ctrlName);
        }
    }

    setCodecVersion(): void {
        if (!this._sessionService.getConfig().fecc_enabled) {
            this._metricService.enqueue({ camera_connected: +true });
            return;
        }
        const ptzDevice = {
            camera_connected: +false,
            codec_ver: null,
        };
        this._usbDevices.forEach((device) => {
            const feccCamera = FECC_DEVICES.find(
                (d) => d.productId === device.pid && d.vendorId === device.vid
            );
            if (feccCamera) {
                ptzDevice.camera_connected = +true;
                ptzDevice.codec_ver = feccCamera.codecVersion;
            }
        });
        this._metricService.enqueue(ptzDevice);
    }

    handleBatteryResponse(response): IBatteryStatus {
        const powerStatus: IBatteryStatus = {};
        const data = response.power_cradle || response.power;
        let batteryPercentage;

        if (
            data &&
            (data.event === SYSTEM_COMMANDS_TYPES.POWER_STATUS ||
                data.command === SYSTEM_COMMANDS_TYPES.GET_POWER_STATS)
        ) {
            if (data.batteries && data.batteries.length > 0) {
                // Take average of all batteries and round off
                batteryPercentage = Math.round(
                    meanBy(data.batteries, (battery: any) => {
                        if (battery.name) {
                            return parseInt(battery.percent_charge, 0);
                        }
                    })
                );

                // Round off battery 97% and above to 100%

                if (batteryPercentage > 96) {
                    if (batteryPercentage > 96) {
                        batteryPercentage = 100;

                        batteryPercentage = 100;
                    } else if (batteryPercentage >= 70) {
                        batteryPercentage = Math.round(
                            ((batteryPercentage - 70) * 5) / 30 + 95
                        );
                    } else if (batteryPercentage >= 30) {
                        batteryPercentage = Math.round(
                            ((batteryPercentage - 30) * 90) / 40 + 5
                        );
                    } else {
                        batteryPercentage = Math.round(
                            (5 * batteryPercentage) / 30
                        );
                    }
                }
                this._tabletBatteryConnected = 1;
            } else {
                this._tabletBatteryConnected = 0;
            }
            const adapter = find(data.adapters, {
                state: 'ATTACHED',
            });

            if (adapter) {
                this._acPowerConnected = powerStatus.acPowerConnected = true;
            } else {
                this._acPowerConnected = powerStatus.acPowerConnected = false;
            }

            // Response contains data for the tablet battery (batteries).
            powerStatus.batteryConnected = this._tabletBatteryConnected;
            powerStatus.tabletBatteryLevel = batteryPercentage;
            powerStatus.level = batteryPercentage;

            this._metricService.enqueue({
                ac_power_connected: Number(powerStatus.acPowerConnected),
                battery_connected: Number(powerStatus.batteryConnected),
                tablet_battery: powerStatus.tabletBatteryLevel,
                battery: powerStatus.level,
            });

            return powerStatus;
        }
    }

    changeCodecVersion(device): void {
        const feccCamera = FECC_DEVICES.find(
            (d) =>
                parseInt(d.productId, 16) === parseInt(device.productId, 16) &&
                parseInt(d.vendorId, 16) === parseInt(device.vendorId, 16) &&
                device.label.toLowerCase().indexOf(d.v4lName.toLowerCase()) > -1
        );
        if (feccCamera) {
            const ptzDevice = {
                camera_connected: +true,
                codec_ver: feccCamera.codecVersion,
            };
            this._metricService.enqueue(ptzDevice);
            this._ptzService.changeCamera(feccCamera);
        }
    }
}
