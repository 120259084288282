import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class VideoService {
    public room: any = null;
    public uploadStep: any = null;
    public devices: any = null;
    public fileName = '';
    private _callLeavingTime: number;

    constructor(private _http: HttpClient) {}

    setRoom(room) {
        this.room = room.data;
    }

    getRoom() {
        return this.room;
    }

    removeRoom() {
        this.room = null;
    }

    createRoom() {
        Promise.all([
            this._http.post('/p2p/ice', {
                turn: 'twilio',
            }),
            this._http.post('/video/room', {}),
        ]).then(([ice, room]) => {
            this.room = room;
            this.room.ice = ice;
        });
    }
    invite(input) {
        const obj = {
            room: this.room,
            email: [],
            users: [],
            sip: [],
            sms: [],
            endpoints: [],
        };

        switch (input.tp) {
            case 'email':
                obj.email = [input.value];
                break;
            case 'person':
                obj.users = [input.userId];
                break;
            case 'sip':
                obj.sip = [input.value];
                break;
            case 'sms':
                obj.sms = [input.value];
                break;
            case 'endpoint':
                obj.endpoints = [input.value];
                break;
            case 'phone':
                obj.sip = [input.value];
                break;
        }
    }

    getApgarAutoHangupSetting() {
        return this._http.get('v10/autoHangup');
    }

    updateParticipantStatus(uuid) {
        return this._http.post(`v10/update_participant_status/${uuid}`, {});
    }

    setHomePosition() {
        return this._http.post(`v10/setHomePosition`, {});
    }

    setUploadStep(step) {
        this.uploadStep = step;
    }

    getUploadStep() {
        return this.uploadStep;
    }

    setFileName(filename) {
        this.fileName = filename;
    }

    getFileName() {
        return this.fileName;
    }

    public get callLeavingTime(): number {
        return this._callLeavingTime;
    }

    public set callLeavingTime(leavingTime: number) {
        this._callLeavingTime = leavingTime;
    }

    isConvergeCallRoom(): boolean {
        const twilioRoom = this.getRoom();
        if (twilioRoom) {
            const { conferenceVendorId } = twilioRoom;
            return conferenceVendorId?.toLowerCase() === 'twilio';
        }
        return false;
    }
}
