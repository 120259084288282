import { IEndpointSettings } from '@shared/interfaces';
import { EndpointTypes } from '@shared/enums';

export const TEST_ENDPOINT_SETTINGS_DATA: IEndpointSettings = {
    activated_on: null,
    configuration: null,
    created_at: null,
    created_by: null,
    description: 'test',
    direct_sip: null,
    enable_fleet_timeout: true,
    endpoint_device_id: null,
    endpoint_id: null,
    endpoint_photo_url: '/endpoint/photo/url/value/just/for/testing',
    endpoint_serial_number: null,
    endpoint_type_id: EndpointTypes.C500,
    enterprise_code: null,
    face_enabled: null,
    facility_id: null,
    facility_name: null,
    fecc_enabled: false,
    is_activated: false,
    is_favorite: false,
    lookandfeel: null,
    name: 'Test Name',
    phone_number: null,
    photo_id: null,
    protocol_handler: null,
    receive_alerts: false,
    rect_enabled: false,
    room_id: null,
    room_name: null,
    settings: null,
    sip_address: null,
    updated_at: null,
    updated_by: null,
    vidyo_address: null,
    worksurface_lights: null,
    fleet_url: '',
};
