import { KIND_OF_MEDIA_DEVICE } from '@/shared/constants';
import {
    IMediaDevices,
    IEndpointConfiguration,
    IEndpointSettings,
    IDeviceInfo,
} from '@shared/interfaces';

export const selectMediaDevices = (state: any): IMediaDevices =>
    state.mediaDevices;
export const selectConfigs = (state: any): IEndpointConfiguration =>
    state.endpointConfig;
export const fSelectEndpoint = (state: any): IEndpointSettings =>
    state.endpoint;

export const fSelectMediaDevicesAudioOutput = (mediaDevices: IMediaDevices) =>
    mediaDevices.devices.filter(
        (device) =>
            device.kind === KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT &&
            !device.isPeripheral
    );

export const fSelectMediaDevicesAudioInput = (mediaDevices: IMediaDevices) =>
    mediaDevices.devices.filter(
        (device) =>
            device.kind === KIND_OF_MEDIA_DEVICE.AUDIO_INPUT &&
            !device.isPeripheral
    );

export const fSelectMediaDevicesVideoInput = (mediaDevices: IMediaDevices) =>
    mediaDevices.devices.filter(
        (device) =>
            device.kind === KIND_OF_MEDIA_DEVICE.VIDEO_INPUT &&
            !device.isPeripheral
    );

export const fSelectMediaDevicesPeripheralInput = (
    mediaDevices: IMediaDevices
) =>
    mediaDevices.devices.filter(
        (device) =>
            (device.kind === KIND_OF_MEDIA_DEVICE.VIDEO_INPUT ||
                device.kind === KIND_OF_MEDIA_DEVICE.AUDIO_INPUT) &&
            device.isPeripheral
    );

export const selectDeviceInfo = (state: any): IDeviceInfo => state.device;
