import { IEndpointSettings } from '@/shared/interfaces';
import { rejoinConvergeCall } from '@/shared/storage/layout/layout.actions';
import { selectEndpoint } from '@/shared/storage/selectors/endpoint.selectors';
import { selectIsBatteryConnected } from '@/shared/storage/selectors/battery.selectors';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, timer } from 'rxjs';

@Component({
    selector: 'core-empty-dashboard',
    templateUrl: './empty-dashboard.component.html',
})
export class EmptyDashboardLayout implements OnInit, OnDestroy {
    public endpoint: IEndpointSettings;
    public hasBattery: boolean;
    private _subscriptions: Subscription[] = [];

    constructor(private _store: Store) {}

    ngOnInit() {
        this._subscriptions.push(
            this._store
                .select(selectEndpoint)
                .subscribe((endpoint: IEndpointSettings) => {
                    this.endpoint = endpoint;
                })
        );

        this._subscriptions.push(
            this._store
                .select(selectIsBatteryConnected)
                .subscribe((isConnected: boolean) => {
                    this.hasBattery = isConnected;
                })
        );

        this._subscriptions.push(
            timer(5000).subscribe(() => {
                this._store.dispatch(rejoinConvergeCall());
            })
        );
    }

    ngOnDestroy() {
        this._subscriptions.forEach((sub) => sub && sub.unsubscribe());
    }
}
