import { createSelector } from '@ngrx/store';
import { PERIPHERALS } from '@shared/constants';

import { IMediaDevices } from '@shared/interfaces';

import {
    selectMediaDevices,
    fSelectMediaDevicesPeripheralInput,
    fSelectMediaDevicesAudioOutput,
    fSelectMediaDevicesAudioInput,
    fSelectMediaDevicesVideoInput,
} from './universal-selectors';

// TODO remove commented code in AU-543
// export const selectAllowSnapshots = createSelector(
//     selectMediaDevices,
//     (mediaDevices: IMediaDevices) => mediaDevices.allowSnapshots
// );

// export const selectAllowStethRecording = createSelector(
//     selectMediaDevices,
//     (mediaDevices: IMediaDevices) => mediaDevices.allowStethRecording
// );

export const selectIsUploadingFromDevice = createSelector(
    selectMediaDevices,
    (mediaDevices: IMediaDevices) => mediaDevices.isUploadingFromDevice
);

export const selectMediaDeviceStore = createSelector(
    selectMediaDevices,
    (mediaDevices: IMediaDevices) => mediaDevices
);

export const selectMediaDevicesList = createSelector(
    selectMediaDevices,
    (mediaDevices: IMediaDevices) => mediaDevices?.devices
);

export const selectMediaDevicesSeparateList = createSelector(
    selectMediaDevices,
    (mediaDevices: IMediaDevices) => ({
        video_input_options: JSON.stringify(
            fSelectMediaDevicesVideoInput(mediaDevices)
        ),
        audio_input_options: JSON.stringify(
            fSelectMediaDevicesAudioInput(mediaDevices)
        ),
        audio_output_options: JSON.stringify(
            fSelectMediaDevicesAudioOutput(mediaDevices)
        ),
    })
);

export const getSelectedVideoInput = createSelector(
    selectMediaDevices,
    (mediaDevices: IMediaDevices) => mediaDevices.selectedVideoInput
);

export const getCameraZoomLevel = createSelector(
    selectMediaDevices,
    (mediaDevices: IMediaDevices) => mediaDevices.zoomLevel
);

export const selectWithDefaultPlay = createSelector(
    selectMediaDevices,
    (mediaDevices: IMediaDevices) =>
        mediaDevices.devices.find((f) => f.defaultPlay)
);

export const selectHeadPhoneDetect = createSelector(
    selectMediaDevices,
    (mediaDevices: IMediaDevices) =>
        (mediaDevices && mediaDevices.headPhoneDetect) || false
);

export const selectHeadSetMicDetect = createSelector(
    selectMediaDevices,
    (mediaDevices: IMediaDevices) =>
        (mediaDevices && mediaDevices.headSetMicDetect) || false
);

export const selectStethLineDetect = createSelector(
    selectMediaDevices,
    (mediaDevices: IMediaDevices) =>
        (mediaDevices && mediaDevices.stethLineDetect) || false
);

export const selectAudioOutputs = createSelector(
    selectMediaDevices,
    fSelectMediaDevicesAudioOutput
);

export const selectAudioInputs = createSelector(
    selectMediaDevices,
    fSelectMediaDevicesAudioInput
);

export const selectVideoInputs = createSelector(
    selectMediaDevices,
    fSelectMediaDevicesVideoInput
);

export const selectPeripheralInputs = createSelector(
    selectMediaDevices,
    fSelectMediaDevicesPeripheralInput
);

export const selectPeripherals = createSelector(
    selectMediaDevices,
    (mediaDevices: IMediaDevices) =>
        fSelectMediaDevicesPeripheralInput(mediaDevices)
            .map((item) =>
                PERIPHERALS.filter((PERIPHERAL) => {
                    return item.label.indexOf(PERIPHERAL.getLabel()) >= 0;
                }).map((itemPeripheral) => ({
                    ...itemPeripheral,
                    mediaDevice: item,
                }))
            )
            .reduce((a, b) => a.concat(b), [])
);

export const selectRipleyBuiltInDevices = createSelector(
    selectMediaDevices,
    (mediaDevices: IMediaDevices) => mediaDevices.ripleyBuiltInDevices
);
