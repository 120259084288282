<div class="white-box">
    <div class="col s4">
        <aw-button
            automationValue="wifiButton"
            styleClasses="cc-button m-b2 pt1 {{ currentNetwork === NetworkTypes.WIFI && 'selected' }} {{
                connectedWifi ? 'status-success' : 'status-danger'
            }}"
            (onClick)="onNetworkClick(NetworkTypes.WIFI)"
        >
            <span class="icon icon-wifi-general-black"></span>
            Wi-Fi
            <aw-indicator styleClasses="success" *ngIf="connectedWifi"> Connected</aw-indicator>
            <aw-indicator styleClasses="danger" *ngIf="!connectedWifi"> Not Connected</aw-indicator>
        </aw-button>
        <aw-button
            automationValue="wiredButton"
            styleClasses="cc-button m-b2 pt1 {{ currentNetwork === NetworkTypes.ETHERNET && 'selected' }}"
            (onClick)="onNetworkClick(NetworkTypes.ETHERNET)"
        >
            <span class="icon icon-ethernet-general-black"></span>
            Wired
            <aw-indicator styleClasses="success" *ngIf="ethernetConnected">Wired</aw-indicator>
            <aw-indicator styleClasses="danger" *ngIf="!ethernetConnected">Not Wired</aw-indicator>
        </aw-button>
        <aw-button
            automationValue="myDeviceButton"
            styleClasses="cc-button align-left items-left {{ currentNetwork === NetworkTypes.CUSTOM && 'selected' }}"
            [class.selected]="currentNetwork === NetworkTypes.CUSTOM"
            (onClick)="onNetworkClick(NetworkTypes.CUSTOM)"
        >
            My Device
        </aw-button>
    </div>

    <!-- Wifi blocks -->
    <app-wifi
        *ngIf="currentNetwork === NetworkTypes.WIFI"
        [networkManager]="networkManager"
        [wifiAdresess]="wifiParams?.wifiAdresess"
        [externalJoinStaticIPStatus]="wifiParams?.joinStaticIPStatus"
        [wifi]="selectedWifi"
        [joinAnotherNetworkClicked]="joinAnotherNetworkClicked"
        (selectWifi)="onSelectedWifi($event)"
    >
    </app-wifi>
    <!-- End Wi-Fi -->
    <!-- Wired Start -->
    <app-wired
        *ngIf="currentNetwork === NetworkTypes.ETHERNET"
        [ethernetStatuses]="ethernetStatuses"
        [ethernetStatus]="ethernetStatus"
    >
    </app-wired>
    <!-- Wired End -->

    <!-- Device -->
    <app-device *ngIf="currentNetwork === NetworkTypes.CUSTOM"> </app-device>
    <!-- Device End -->
</div>
