import { VideoActions } from '@/shared/enums/video-action.enum';
import { IVideoCall, IVideoCallSharingDevice } from '@/shared/interfaces/video';
import { createAction, props } from '@ngrx/store';
const UPDATE_VIDEO_INFO = 'UPDATE_VIDEO_INFO';
const CLEAR_VIDEO_INFO = 'CLEAR_VIDEO_INFO';

export const updateVideoInfo = createAction(
    UPDATE_VIDEO_INFO,
    props<{ videoInfo: IVideoCall }>()
);

export const startSharing = createAction(
    VideoActions.StartSharing,
    props<{ sharingDevice: IVideoCallSharingDevice }>()
);

export const endSharing = createAction(VideoActions.EndSharing);

export const startRecording = createAction(VideoActions.StartRecording);

export const endRecording = createAction(VideoActions.EndRecording);

export const TakeSnapshot = createAction(VideoActions.TakeSnapshot);

export const EndShapshot = createAction(VideoActions.EndSnapshot);

export const allowSnapshot = createAction(
    VideoActions.AllowSnapshot,
    props<{ allowSnapshot: boolean }>()
);

export const allowStethRecording = createAction(
    VideoActions.AllowStethRecording,
    props<{ allowStethRecording: boolean }>()
);

export const clearVideoCallInfo = createAction(CLEAR_VIDEO_INFO);
