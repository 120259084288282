import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ILowBatteryModalData } from '@shared/interfaces';

@Component({
    selector: 'low-battery-modal',
    templateUrl: './low-battery-modal.component.html',
})
export class LowBatteryModalComponent {
    public title: string;
    public body: string;
    public showFooter: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ILowBatteryModalData,
        public dialogRef: MatDialogRef<LowBatteryModalComponent>
    ) {
        this.title = data.title;
        this.body = data.body;
        this.showFooter = data.showFooter;
    }

    close(): void {
        this.dialogRef.close();
    }
}
