<div class="col s8 form">
    <h3>My Device</h3>
    <p>Network Adapters</p>
    <div class="gray-light a30 px15">
        <div class="row m-b0">
            <div class="col s6 m4 l3">Ethernet</div>
            <div class="col s6 m8 l9 gray-mid-text" automation="ethHwAddr">
                {{ deviceInfo.ethHwAddress }}
            </div>
        </div>
    </div>
    <div class="gray-light a30 border-top px15">
        <div class="row m-b0">
            <div class="col s6 m4 l3">Wifi</div>
            <div class="col s6 m8 l9 gray-mid-text" automation="wifiHwAddr">
                {{ deviceInfo.wifiHwAddress }}
            </div>
        </div>
    </div>
    <p class="m-t3">Change the device name appearance on the network</p>
    <div class="input-frame">
        <aw-input
            automationValue="hostName"
            styleClasses="js-keyboard-input"
            elementId="host"
            label="Host Name"
            type="text"
            required="true"
            [(ngModel)]="deviceInfo.hostName"
        >
        </aw-input>
    </div>
    <div class="input-frame">
        <aw-select
            automationValue="roamingThreshold"
            label="Wi-Fi Roaming Aggression"
            elementId="roamingThreshold"
            name="roamingThreshold"
            [(ngModel)]="roamingThreshold"
            [options]="roamingThresholdTypes"
        >
        </aw-select>
    </div>

    <div class="clearfix m-t25">
        <aw-button
            automationValue="saveButton"
            elementId="setHostNameButton"
            styleClasses="btn btn-primary waves-effect waves-light btn-large right"
            (onClick)="onSaveMyDevice()"
        >
            Save
        </aw-button>
    </div>
</div>
