import { createAction, props } from '@ngrx/store';
import { IDeviceInfo } from '@interfaces/device/device-info.interface';
import { DeviceActions } from '@shared/enums';
const UPDATE_DEVICE_INFO = 'UPDATE_DEVICE_INFO';
const UPDATE_DEVICE_INFO_BY_KEY = 'UPDATE_DEVICE_INFO_BY_KEY';

export const updateDeviceInfo = createAction(
    UPDATE_DEVICE_INFO,
    props<{ deviceInfo: IDeviceInfo }>()
);

export const updateDeviceInfoByKey = createAction(
    UPDATE_DEVICE_INFO_BY_KEY,
    props<{ key: string; value: any }>()
);

export const startVideoShare = createAction(
    DeviceActions.StartVideoShare,
    props<{ deviceId: string | number; title: string }>()
);

export const endVideoShare = createAction(
    DeviceActions.EndVideoShare,
    props<{ deviceId: string | number; title: string }>()
);

export const stopRecording = createAction(
    DeviceActions.StopRecording,
    props<{ productId: string | number; deviceInfo: any }>()
);

// TODO remove it in AU-543
// export const startRecording = createAction(
//     DeviceActions.StartRecording,
//     props<{ productId: string | number }>()
// );

export const onScreenKeyboardChanged = createAction(
    DeviceActions.OnScreenKeyboardChanged,
    props<{ onScreenKeyboardOpened: boolean }>()
);

export const shareDeviceError = createAction(DeviceActions.SHARE_DEVICE_ERROR);

export const takeSnapShot = createAction(DeviceActions.TakeSnapShot);

export const sharedDeviceChanged = createAction(
    DeviceActions.SHARE_DEVICE_CHANGED,
    props<{ sharedDeviceId: number }>()
);
