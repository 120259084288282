import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { NavigationEnd, Router } from '@angular/router';

import {
    combineLatest,
    fromEvent,
    merge,
    Observable,
    ReplaySubject,
    Subscription,
    timer,
} from 'rxjs';
import { takeUntil, tap, throttleTime } from 'rxjs/operators';

import {
    selectIsScreenSaverEnabled,
    selectIsTurnOffDisplayEnabled,
    selectScreenSaverTimeout,
    selectTurnOffDisplayTimeout,
} from '@/shared/storage/selectors';
import { SessionService } from '../session';
import { IpcService } from '../ipc';
import { DeviceState } from '@shared/enums';
import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import {
    closeAllModal,
    navigateToMain,
} from '@/shared/storage/layout/layout.actions';

@Injectable({ providedIn: 'root' })
export class ActivityMonitorService implements OnDestroy {
    private _destroy$: ReplaySubject<number> = new ReplaySubject<number>(1);
    private activityEvents$: Observable<any>;
    private _screenSaverEnabled: boolean;
    private _turnOffDisplayEnabled: boolean;
    private _isRouteAllowedForScreenSaver: boolean;
    private _isRouteAllowedForTurnOff: boolean;
    private _screenSaverTimeout: number;
    private _turnOffDisplayTimeout: number;
    private _screenSaverSub: Subscription;
    private _turnOffDisplaySub: Subscription;
    private _standBySub: Subscription;
    private _screenSaverRoutes = [
        'video',
        'waiting-room',
        'lock',
        'lost-connection',
        'converge-video',
    ];
    private _displayOffRoutes = [
        'video',
        'waiting-room',
        'lost-connection',
        'converge-video',
    ];
    private _currentUrl: string;
    private _displayState: string = DeviceState.ON;

    constructor(
        private _store: Store,
        private _router: Router,
        private zone: NgZone,
        private _sessionService: SessionService,
        private _ipcService: IpcService
    ) {
        this._setupActivityEvents();
        this._setupConfigData();
        this._setupStandByStatus();
    }

    private _setupStandByStatus() {
        this._ipcService
            .subscribeOnStandByState()
            .pipe(takeUntil(this._destroy$))
            .subscribe(({ state }) => {
                this.updateStandbyState(state);
                this.setStandByState(state);
            });
    }

    private _setupActivityEvents() {
        this.activityEvents$ = merge(
            fromEvent(window, 'click'),
            fromEvent(window, 'keydown'),
            fromEvent(window, 'mousemove')
        );
    }

    private _setupConfigData() {
        combineLatest([
            this._store.select(selectIsScreenSaverEnabled),
            this._store.select(selectIsTurnOffDisplayEnabled),
            this._store.select(selectScreenSaverTimeout),
            this._store.select(selectTurnOffDisplayTimeout),
        ])
            .pipe(takeUntil(this._destroy$))
            .subscribe(
                ([
                    isScreenSaverEnabled,
                    isTurnOffDisplayEnabled,
                    screenSaverTimeout,
                    turnOffDisplayTimeout,
                ]) => {
                    this._screenSaverEnabled = isScreenSaverEnabled;
                    this._turnOffDisplayEnabled = isTurnOffDisplayEnabled;
                    this._screenSaverTimeout = screenSaverTimeout;
                    this._turnOffDisplayTimeout = turnOffDisplayTimeout;
                }
            );
    }

    private _listenRouteChanges() {
        this._router.events
            .pipe(takeUntil(this._destroy$))
            .subscribe((val: any) => {
                if (val instanceof NavigationEnd) {
                    this._currentUrl = val.url;
                    this._isRouteAllowedForScreenSaver =
                        this.enableMonitorForRoute(this._screenSaverRoutes);
                    this._isRouteAllowedForTurnOff = this.enableMonitorForRoute(
                        this._displayOffRoutes
                    );

                    if (
                        this._isRouteAllowedForScreenSaver ||
                        this._isRouteAllowedForTurnOff
                    ) {
                        this.resetActivityTimer();
                    } else {
                        this._removeAllSub();
                    }
                }
            });
    }

    private _listenActivityChanges() {
        this.activityEvents$
            .pipe(
                throttleTime(700),
                takeUntil(this._destroy$),
                tap(() => {
                    if (
                        this._isRouteAllowedForScreenSaver ||
                        this._isRouteAllowedForTurnOff
                    ) {
                        this.resetActivityTimer();
                    }
                })
            )
            .subscribe();
    }

    private _listenRemoteUnlockEvent() {
        this._ipcService
            .listener(SYSTEM_COMMANDS_TYPES.UNLOCK_SCREEN)
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this._store.dispatch(navigateToMain());
            });
    }

    init(): void {
        if (this._screenSaverEnabled || this._turnOffDisplayEnabled) {
            this._listenRouteChanges();
            this._listenActivityChanges();
            this._listenRemoteUnlockEvent();
        }
    }

    resetActivityTimer() {
        this.zone.runOutsideAngular(() => {
            if (
                this._screenSaverEnabled &&
                this._screenSaverTimeout &&
                this._isRouteAllowedForScreenSaver
            ) {
                this.triggerScreenSaverMonitor();
            }

            if (
                this._turnOffDisplayEnabled &&
                this._turnOffDisplayTimeout &&
                this._isRouteAllowedForTurnOff
            ) {
                this.triggerTurnOffDisplay();
            }
        });
    }

    private _removeAllSub() {
        this._removeScreenSaverSub();
        this._removeTurnOffDisplaySub();
        this._removeStandBySub();
    }

    private _removeScreenSaverSub() {
        this._screenSaverSub?.unsubscribe();
    }

    private _removeTurnOffDisplaySub() {
        this._turnOffDisplaySub?.unsubscribe();
    }

    private _removeStandBySub() {
        this._standBySub?.unsubscribe();
    }

    triggerScreenSaverMonitor() {
        this._removeScreenSaverSub();

        this._screenSaverSub = timer(
            // setting timeout in minutes based on config value
            this._screenSaverTimeout * 1000 * 60
        ).subscribe(() => {
            this._store.dispatch(closeAllModal());
            this._router.navigateByUrl('/lock/home');
        });
    }

    triggerTurnOffDisplay() {
        if (this._turnOffDisplaySub) {
            this._removeTurnOffDisplaySub();

            if (this._displayState == DeviceState.OFF) {
                this.setStandByState(false);
                this._sessionService.standbyState = false;
                this._displayState = DeviceState.ON;
            }
        }

        this._removeStandBySub();

        if (this._displayState !== DeviceState.OFF) {
            this._turnOffDisplaySub = timer(
                this._turnOffDisplayTimeout * 1000 * 60
            ).subscribe(() => {
                this.setStandByState(true);
                this._setStandByStateTimer(true);
            });
        }
    }

    setStandByState(state: boolean) {
        this._sessionService.deviceInstance.standbyState = state;
        switch (state) {
            case true:
                this._ipcService.setDisplayTurnOff();
                this._ipcService.requestChangeLedRingColor(DeviceState.STANDBY);
                this._ipcService.setLEDWorkSurfaceOff();
                break;
            case false:
                this._ipcService.setDisplayTurnOn();
                this._ipcService.setLEDRingOn();
                this._sessionService.deviceInstance.setWorksurfaceLights();
                break;
        }
    }

    private _setStandByStateTimer(state: boolean) {
        this._removeStandBySub();
        this._standBySub = timer(4000).subscribe(() => {
            // setting display state to off by the time standby state is set
            this._displayState = state ? DeviceState.OFF : DeviceState.ON;
            this._sessionService.standbyState = state;
        });
    }

    updateStandbyState(state: boolean) {
        this._setStandByStateTimer(state);

        if (!state) {
            this.triggerTurnOffDisplay();
        }
    }

    enableMonitorForRoute(monitorRoutes) {
        const find = monitorRoutes.find((item) => {
            return this._currentUrl && this._currentUrl.indexOf(item) >= 0;
        });
        return find ? false : true;
    }

    ngOnDestroy() {
        this._removeAllSub();

        this._destroy$.next();
        this._destroy$.complete();
    }
}
