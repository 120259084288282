// TODO replace it to constants in AU-543
export enum VideoActions {
    CameraChanged = 'CAMERA_CHANGED',
    MicrophoneChanged = 'MICROPHONE_CHANGED',
    ShareDeviceChanged = 'SHARE_DEVICE_CHANGED',
    ShareDeviceError = 'SHARE_DEVICE_ERROR',
    StartSharing = 'START_SHARING',
    EndSharing = 'END_SHARING',
    StartRecording = 'START_RECORDING',
    EndRecording = 'END_RECORDING',
    TakeSnapshot = 'TAKE_SHAPSHOT',
    EndSnapshot = 'END_SNAPSHOT',
    AllowSnapshot = 'ALLOW_SNAPSHOT',
    AllowStethRecording = 'ALLOW_STETH_RECORDING',
}
