import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'logo',
    templateUrl: './logo.component.html',
})
export class LogoComponent {
    @Input() styleClass: NgClass;
    @Input() url: string;
}
