export const AW_BEARER_TOCKEN = 'aw-bearer-token';
export const TWILIO = 'TWILIO';

export const CALL_MUTE_STATE = {
    call_state: '0',
    mute_state: '0',
};

export const AUDIO_INPUT_CONSTRAINT = {
    mandatory: {
        googAutoGainControl: true,
        googNoiseSuppression: true,
        googNoiseSuppression2: true,
        googEchoCancellation: true,
        googHighpassFilter: true,
        sourceId: null,
    },
};

export const AUDIO_INPUT_CONSTRAINT_DEFAULT = {
    mandatory: {
        googAutoGainControl: false,
        googNoiseSuppression: false,
        googNoiseSuppression2: false,
        googEchoCancellation: false,
        googHighpassFilter: false,
        sourceId: null,
    },
};

export const VIDEO_INPUT_CONSTRAINT = {
    deviceId: null,
    width: {
        min: 320,
        ideal: 1920,
        max: 4096,
    },
    height: {
        min: 320,
        ideal: 1080,
        max: 1280,
    },
    frameRate: {
        min: 15,
        max: 60,
    },
    aspectRatio: 1.7777777778,
};

export const CUSTOM_SETTINGS = {
    messages: {
        en: {
            settings: 'Settings',
            settings_details: 'Select the source for your inputs.',
            settings_camera_list_header: 'Camera',
            settings_microphone_list_header: 'Microphone',
            settings_speaker_list_header: 'Speaker',
            settings_default: 'Default',
            apply_button: 'Apply',
            settings_video_list_header: 'Video Input',
        },
        es: {
            settings: 'Ajustes',
            settings_details: 'Seleccione la fuente de sus entradas.',
            settings_camera_list_header: 'Cámara',
            settings_microphone_list_header: 'Micrófono',
            settings_speaker_list_header: 'Bocina',
            settings_default: 'Defecto',
        },
    },
    appendTo: 'bottomPaneCenterContainer',
};
