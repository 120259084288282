<div class="modal-content-wrapper">
    <aw-button
        aria-label="Dismiss"
        styleClasses="mat-dialog-close icon-link gray-mid-text custom-btn"
        (onClick)="closeModal('icon')"
        automationValue="close"
    >
        <i class="material-icons">close</i>
    </aw-button>
    <div class="modal-heading no-border py3">
        <h4 automation="title">
            <i *ngIf="data.icon" class="material-icons" automation="icon">{{ data.icon }}</i
            >{{ data.title }}
        </h4>
    </div>
    <div class="modal-content">
        <p automation="content">{{ data.content }}</p>
    </div>
    <div class="modal-footer py2">
        <aw-button
            styleClasses="btn btn-{{ confirmBtnColor }} m-y1 m-l2"
            automationValue="yesBtn"
            (onClick)="closeModal('yes')"
        >
            {{ data.yesText || 'Yes' }}
        </aw-button>
        <aw-button styleClasses="btn-flat btn-primary m-y1" automationValue="noBtn" (onClick)="closeModal('no')">
            {{ data.noText || 'No' }}
        </aw-button>
    </div>
</div>
