export const AUDIO_DEVICE_TYPE = {
    SINK: 'Sink',
    SOURCE: 'Source',
};

export const AUDIO_DEVICES = {
    JABRA_SPEAKER_A: {
        pid: '0410',
        vid: '0b0e',
        device_type: AUDIO_DEVICE_TYPE.SINK,
    },
    JABRA_MICROPHONE_A: {
        pid: '0410',
        vid: '0b0e',
        device_type: AUDIO_DEVICE_TYPE.SOURCE,
    },
    JABRA_SPEAKER_B: {
        pid: '0412',
        vid: '0b0e',
        device_type: AUDIO_DEVICE_TYPE.SINK,
    },
    JABRA_MICROPHONE_B: {
        pid: '0412',
        vid: '0b0e',
        device_type: AUDIO_DEVICE_TYPE.SOURCE,
    },
    SHURE_MICROPHONE: {
        pid: '29b6',
        vid: '14ed',
        device_type: AUDIO_DEVICE_TYPE.SOURCE,
    },
    SHURE_DEVICE_NAME: 'X2u Adapter Analog Stereo',
    JABRA_DEVICE_NAME: 'Jabra SPEAK',
    XMOS_SPEAKER: {
        pid: '0011',
        vid: '20b1',
        device_type: AUDIO_DEVICE_TYPE.SINK,
    }, // Built-in Speaker
    XMOS_MICROPHONE: {
        pid: '0011',
        vid: '20b1',
        device_type: AUDIO_DEVICE_TYPE.SOURCE,
    }, // Built-in Microphone
    PCM2912_SPEAKER: {
        pid: '2912',
        vid: '08bb',
        device_type: AUDIO_DEVICE_TYPE.SINK,
    }, // 3.5mm Headphone Output
    PCM2912_MICROPHONE: {
        pid: '2912',
        vid: '08bb',
        device_type: 'Source',
    }, // 3.5mm Mic Input
};
