export const RnkStethoscope = [
    `
    <div class="hslide-top items-center steth-image">
        <span class="material-icons large-material">usb</span>
    </div>
    <div class="hslide-bottom">
        <h3>Connect your stethoscope via USB</h3>
        <span class="hslide-step">1 of 6</span>
    </div>
    `,
    `
    <div class="hslide-top items-center steth-image">
    <img class="" src="/assets/images/peripherals-devices/share-icon.svg" alt="">
    </div>
    <div class="hslide-bottom">
        <h3>Click Share to start sharing your stethoscope</h3>
        <span class="hslide-step">2 of 6</span>
    </div>
    `,
    `
    <div class="hslide-top items-center steth-image">
    <img class="" src="/assets/images/peripherals-devices/volume-icon.svg" alt="">
    </div>
    <div class="hslide-bottom">
        <h3>To adjust your stethoscopes volume, click Adjust Stethoscope Controls button above.</h3>
        <span class="hslide-step">3 of 6</span>
    </div>
    `,
    `
    <div class="hslide-top items-center steth-image">
    <img class="" src="/assets/images/peripherals-devices/equalizer-icon.svg" alt="">
    </div>
    <div class="hslide-bottom">
        <h3>Use the different filter presets to control what frequency your stethoscope is using.</h3>
        <span class="hslide-step">4 of 6</span>
    </div>
    `,
    `
    <div class="hslide-top items-center steth-image">
    <img class="" src="/assets/images/peripherals-devices/info-icon.svg" alt="">
    </div>
    <div class="hslide-bottom">
        <h3>
            For more info on what each filter does, click the info button in the top right of your stethoscope
            controls area.
        </h3>
        <span class="hslide-step">5 of 6</span>
    </div>
    `,
    `
    <div class="hslide-top items-center steth-image">
    <img class="" src="/assets/images/peripherals-devices/stop-share-icon.svg" alt="">
    </div>
    <div class="hslide-bottom">
        <h3>When you are done sharing your stethoscope, click End Share to stop sharing.</h3>
        <span class="hslide-step">6 of 6</span>
    </div>
    `,
];
