import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import {
    ConfirmAction,
    ConfirmDialogComponent,
} from '@/main/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { ReplaySubject, Subscription } from 'rxjs';
import { navigateToMain, selectServiceLine } from '@shared/storage';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StorageService } from '@shared/services';
import { StatisticCollector } from '@/shared/services/statistic-collector/statistic-collector.service';

const waitingRoom = 'waitingRoom';
const selectProvider = 'selectProvider';
const providers = 'providers';
@Component({
    selector: 'sticky-footer',
    templateUrl: './sticky-footer.component.html',
})
export class StickyFooterComponent implements OnInit, OnDestroy {
    @Input() cancelDisabled = false;
    @Input() nextDisabled = false;
    @Input() previousDisabled = false;
    @Input() isNextProgressButton = false;
    @Input() set parentPage(value: string) {
        if (value === waitingRoom) {
            this._parentPage = 'Waiting room';
        }
        if (value === selectProvider) {
            this._parentPage = 'Who should be assigned';
        }
        if (value === providers) {
            this._parentPage = 'Next_Who should be assigned';
        }
    }

    get parentPage() {
        return this._parentPage;
    }

    @Output() cancelCase = new EventEmitter<void>();
    @Output() nextCase = new EventEmitter<void>();

    public serviceLine;
    public serviceLineName;
    public serviceLineTypeId;

    private _parentPage = 'Intake';
    private _subscriptions: Subscription[] = [];

    private _destroy$: ReplaySubject<number> = new ReplaySubject<number>(1);

    constructor(
        private _location: Location,
        private _matDialog: MatDialog,
        private _router: Router,
        private _store: Store,
        private _storageService: StorageService,
        private _statisticCollector: StatisticCollector
    ) {}

    ngOnInit(): void {
        this._store
            .select(selectServiceLine)
            .pipe(takeUntil(this._destroy$))
            .subscribe((val) => (this.serviceLine = val));

        const { name = '', service_line_type_id: serviceLineTypeId = '' } =
            this.serviceLine || {};
        this.serviceLineName = name;
        this.serviceLineTypeId = serviceLineTypeId;
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) =>
            subscription.unsubscribe()
        );

        this._destroy$.next();
        this._destroy$.complete();
    }

    cancel(): void {
        this._statisticCollector.saveEvent(
            'Case Management_Case Creation',
            'Initiate cancel case',
            `Initiate cancel case via button Cancel_${this.parentPage} page`
        );
        const subscription = this._matDialog
            .open(ConfirmDialogComponent, {
                data: {
                    icon: 'assignment',
                    title: 'Cancel Case',
                    content: `Are you sure you would like to cancel this new ${this.serviceLineName} case?`,
                    yesText: 'Cancel Case',
                    noText: 'Continue Working',
                    confirmBtnColor: 'danger',
                },
                panelClass: ['aw-modal', 'modal-lg'],
            })
            .afterClosed()
            .subscribe((action: ConfirmAction) => {
                if (action === ConfirmAction.Yes) {
                    this._storageService.updateServiceLine({
                        ...this.serviceLine,
                        is_cancelling: true,
                    });
                    this._store.dispatch(navigateToMain());
                    this.cancelCase.emit();
                    this._statisticCollector.saveEvent(
                        'Case Management_Case Creation',
                        'Cancel case',
                        `Cancel case via button Cancel case_Cancel case modal dialog_${this.parentPage} page`
                    );
                    return;
                }
                this._statisticCollector.saveEvent(
                    'Case Management_Case Creation',
                    'Discard case cancelation',
                    `Discard case cancelation via button Continue working_Cancel case modal dialog_${this.parentPage} page`
                );
                if (this.serviceLine.is_transfer) {
                    this._router.navigateByUrl('waiting-room-next');
                }
            });
        this._subscriptions.push(subscription);
    }

    previous(): void {
        this._location.back();
    }

    next(): void {
        this.nextCase.emit();
        this._statisticCollector.saveEvent(
            'Case Management_Case Creation',
            'Create case',
            'Create case via button Next_Intake page'
        );
    }
}
