import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IDevice } from '@shared/interfaces';
import { SessionService } from '@shared/services/session/session.service';
import { Store } from '@ngrx/store';
import { selectMediaDevicesList } from '@/shared/storage/selectors';
import { takeUntil } from 'rxjs/operators';
import { LogService } from '../log';
import { KIND_OF_MEDIA_DEVICE } from '@/shared/constants';

@Injectable({ providedIn: 'root' })
export class MediaDevicesService {
    protected _devices: IDevice[] = [];
    // private devices$ = new Subject();

    public destroy$ = new Subject();

    constructor(
        protected _sessionService: SessionService,
        protected _store: Store,
        protected _logService: LogService
    ) {
        // TODO: it is just a boilerplate
        // should be done at AU-301
        // navigator.mediaDevices
        //     .enumerateDevices()
        //     .then((devices: MediaDeviceInfo[]) => {
        //         this._devices = devices;
        //         this.devices$.next(devices);
        //     });

        this._store
            .select(selectMediaDevicesList)
            .pipe(takeUntil(this.destroy$))
            .subscribe((devices) => {
                this._devices = devices;
            });
    }

    get devicesPromise() {
        return this._store.select(selectMediaDevicesList);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getBuiltInAudioOutput(): IDevice {
        return this._devices.find((f) => f.defaultPlay);
    }

    getDevices(): Array<IDevice> {
        return this._devices;
    }

    audioInput(): Array<IDevice> {
        return this._devices.filter((device) => {
            return (
                device.kind === KIND_OF_MEDIA_DEVICE.AUDIO_INPUT &&
                !device.isPeripheral
            );
        });
    }

    audioOutput(): Array<IDevice> {
        return this._devices.filter((device) => {
            return (
                device.kind === KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT &&
                !device.isPeripheral
            );
        });
    }

    getCameraInput(): Array<IDevice> {
        return this._devices.filter((device) => {
            return (
                device.kind === KIND_OF_MEDIA_DEVICE.VIDEO_INPUT &&
                !device.isPeripheral
            );
        });
    }

    defaultVideoInput(): IDevice | { deviceId: number } {
        let isExists;
        const userSelectedVideoInput = this._sessionService.videoInput;
        const videoInputs = this.getCameraInput();

        if (videoInputs && videoInputs.length > 0) {
            isExists = videoInputs.find((items) => items.isFeccEnabled);

            if (userSelectedVideoInput) {
                isExists = videoInputs.find((device) => {
                    return device.label.indexOf(userSelectedVideoInput) >= 0;
                });
            }

            return isExists || videoInputs[0];
        }
        return {
            deviceId: null,
        };
    }

    defaultAudioOutput(): IDevice {
        const audioOutputs = this.audioOutput();
        const userSelectedAudioOutput = this._sessionService.audioOutput;
        const deviceOrder: Array<string | number> = ['Phnx MT202'];

        if (userSelectedAudioOutput) {
            deviceOrder.push(userSelectedAudioOutput);
        }

        deviceOrder.push('PCM2704C');
        deviceOrder.push('Built-in');

        if (audioOutputs && audioOutputs.length > 0) {
            deviceOrder.push(audioOutputs[0].label);
        }

        for (const label of deviceOrder) {
            const item = audioOutputs.find((input) => {
                return input.label.indexOf(label as string) >= 0;
            });

            if (item) {
                return item;
            }
        }
    }

    defaultAudioInput(): IDevice {
        const audioInputs = this.audioInput();
        const userSelectedAudioInput = this._sessionService.audioInput;
        const deviceOrder: Array<string | number> = ['Phnx MT202'];
        if (userSelectedAudioInput) {
            deviceOrder.push(userSelectedAudioInput);
        }
        deviceOrder.push('08bb:2912');
        deviceOrder.push('PCM2912A');
        deviceOrder.push('Built-in');

        if (audioInputs && audioInputs.length > 0) {
            deviceOrder.push(audioInputs[0].label);
        }

        for (const label of deviceOrder) {
            const item = audioInputs.find((input) => {
                return input.label.indexOf(label as string) >= 0;
            });

            if (item) {
                return item;
            }
        }
    }

    onAudioOutputChange(selectedDevice): IDevice {
        return this._devices.filter((device) => {
            return (
                device.kind === KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT &&
                !device.isPeripheral &&
                selectedDevice.deviceId === device.deviceId
            );
        })[0];
    }
}
