import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionService } from '@/shared/services';
import appConfig from 'src/assets/config/app-config.json';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private _sessionService: SessionService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const { invitationServiceUrl } = appConfig;
        if (
            request.url.includes('amazon') ||
            request.url.includes('converge/token')
        ) {
            return next.handle(request);
            // AuthHeader for Ptz axis camera requests
        } else if (request.url.includes(invitationServiceUrl)) {
            return this.handleConvergeApi(request, next);
        }

        return next.handle(
            request.clone({
                setHeaders: {
                    Authorization: this._sessionService.accessToken,
                },
            })
        );
    }

    handleConvergeApi(request: HttpRequest<any>, next: HttpHandler) {
        const excludedRoutes = ['amwell/lite'];
        const excluded = excludedRoutes.some((ex) => request.url.includes(ex));
        if (excluded) {
            return next.handle(request);
        }

        // check if request is authenticated then add bearer token and session id
        let headers = request.headers;
        const token = sessionStorage.getItem('aw-bearer-token');
        if (token) {
            headers = headers.append('Authorization', `Bearer ${token}`);
        }

        return next.handle(request.clone({ headers }));
    }
}
