export const FECC_DEVICE_NAMES = {
    BOLIN_201: 'Bolin_201',
    BOLIN_202: 'Bolin_202',
    PTZ_OPTICS_CAMERA: 'PTZOptics Camera',
    SONY_CAMERA: 'Sony Camera',
    MINRRAY_CAMERA: 'Minrray Camera',
    EPTZ_CAMERA: 'EPTZ Camera',
};

export const FECC_DEVICES = [
    {
        name: FECC_DEVICE_NAMES.BOLIN_201,
        v4lName: 'USB 3.0 Video Camera',
        vendorId: '0x2cb3',
        productId: '0x0201',
        codecVersion: 'vid_2cb3&pid_0201',
        privacy: '-90,-30,1',
        home: '0,0,1',
        displayName: 'Bolin 10x PTZ Camera',
        label: '2cb3:0201',
    }, // Bolin_201
    {
        name: FECC_DEVICE_NAMES.BOLIN_202,
        v4lName: 'USB 3.0 Video Camera',
        vendorId: '0x2cb3',
        productId: '0x0202',
        codecVersion: 'vid_2cb3&pid_0202',
        privacy: '-90,-30,1',
        home: '0,0,1',
        displayName: 'Bolin 10x PTZ Camera',
        label: '2cb3:0202',
    }, // Bolin_202
    {
        name: FECC_DEVICE_NAMES.PTZ_OPTICS_CAMERA,
        v4lName: 'PTZOptics',
        vendorId: '0x2e7e',
        productId: '0x0600',
        codecVersion: 'vid_2e7e&pid_0600',
        privacy: '-90,-30,1',
        home: '0,0,1',
        displayName: 'PTZOptics Camera',
        label: '2e7e:0600',
    }, // PTZOptics Camera
    {
        name: FECC_DEVICE_NAMES.SONY_CAMERA,
        v4lName: 'SRG-120DU',
        vendorId: '0x054c',
        productId: '0x0b7f',
        codecVersion: 'vid_054c&pid_0b7f',
        privacy: '-4550,-1280,0',
        home: '0,0,0',
        displayName: 'Sony 12x PTZ Camera',
        label: '054c:0b7f',
    }, // Sony Camera
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: 'Cyt-FX4',
        vendorId: '0x04b4',
        productId: '0x00f9',
        codecVersion: 'vid_04b4&pid_00f9',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '20x PTZ Camera',
        label: 'Cyt-FX4 (04b4:04f2)',
    }, // Minrray Camera old v4l name
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: 'minrray20',
        vendorId: '0x04b4',
        productId: '0x00f9',
        codecVersion: 'vid_04b4&pid_00f9',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '20x PTZ Camera',
        label: 'minrray20 (04b4:00f9)',
    }, // Minrray Camera 20X
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: 'minrray10',
        vendorId: '0x04b4',
        productId: '0x00f9',
        codecVersion: 'vid_04b4&pid_00f9_10',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '10x PTZ Camera',
        label: 'minrray10 (04b4:00f9)',
    },
    {
        name: FECC_DEVICE_NAMES.EPTZ_CAMERA,
        v4lName: 'See3CAM_CU135',
        vendorId: '0x2560',
        productId: '0xc1d1',
        codecVersion: 'vid_2560&pid_c1d1',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '4x PTZ Camera (Internal)',
        label: 'See3CAM_CU135',
    },
];

export const FECC_POSITION = {
    PRIVACY: 'privacy',
    HOME: 'home',
};
