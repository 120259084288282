import { Store } from '@ngrx/store';
import { Component } from '@angular/core';
import * as selectors from '@storage/selectors';
import { selectDeviceOnScreenKeyboardOpened } from '@/shared/storage/selectors/device-selectors';

@Component({
    selector: 'full-layout',
    templateUrl: './full.component.html',
})
export class FullLayoutComponent {
    public logoUrl$ = this._store.select(selectors.selectEndpointByKey, {
        key: 'endpoint_photo_url',
    });
    public isKeyboardOpened$ = this._store.select(
        selectDeviceOnScreenKeyboardOpened
    );

    constructor(private _store: Store) {}
}
