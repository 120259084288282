import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Queue } from '@/shared/queue/queue';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IpcObserverService {
    private _queueInstance = new Queue();

    addSubscription(command: string): Observable<any> {
        const observable$ = this._queueInstance.getMessageQueue();
        return observable$.pipe(
            filter((message: any) => message.command === command),
            map((filteredMsg: any) => filteredMsg.data)
        );
    }

    notifyChanges(message: any): void {
        if (!!message) {
            this._queueInstance.sendMessageQueue(message);
        }
    }
}
