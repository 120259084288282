import { INetwork, INetworkListResponse } from '@shared/interfaces';

export const NETWORK_LIST: INetworkListResponse = {
    device_list: [
        {
            general: {
                type: 'modem',
                hw_addr: 'asd eee ** sdf 23000 -09',
                mtu: '55-0we-24*',
                state: 'qwerty',
                connection: '1234 ipv-23',
                connection_path: 'bbb-213',
            },
            ipv4: {
                gateway: 'aw:ww:22:132',
                address: 'localhost',
                dns: ['', ''],
                domain: ['', ''],
            },
            ipv6: {
                gateway: '',
                address: ['', ''],
            },
        },
    ],
    network_list: [
        {
            ssid: '1',
            ssid_hex: '',
            bssid: '',
            mode: '',
            channel: 1,
            frequency: 12,
            rate: 22,
            signal: 60,
            bars: 2,
            security: '',
            wpa_flags: '',
            rsn_flags: '',
            device: 'tp-link',
            active: true,
            in_use: true,
            dbus_path: '',
            needs_username: false,
            needs_password: false,
            stored: false,
            locked_band: '',
            available_band: '',
        },
    ],
    active_wifi_network: {
        ssid: '1',
        ssid_hex: '',
        bssid: '',
        mode: '',
        channel: 1,
        frequency: 12,
        rate: 22,
        signal: 60,
        bars: 2,
        security: '',
        wpa_flags: '',
        rsn_flags: '',
        device: 'tp-link',
        active: true,
        in_use: true,
        dbus_path: '',
        needs_username: false,
        needs_password: false,
        stored: false,
        locked_band: '',
        available_band: '',
    },
};

export const defaultNetworkItem: INetwork = {
    ssid: '',
    ssid_hex: '',
    bssid: '',
    mode: '',
    channel: 0,
    frequency: 0,
    rate: 0,
    signal: 0,
    bars: 0,
    security: '',
    wpa_flags: '',
    rsn_flags: '',
    device: '',
    active: false,
    in_use: false,
    dbus_path: '',
    needs_username: false,
    needs_password: false,
    stored: false,
    locked_band: '',
    available_band: 'BAND_AUTO',
};
