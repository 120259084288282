import { Injectable } from '@angular/core';
import { SessionService } from '..';

declare global {
    interface Window {
        _paq: any;
    }
}

@Injectable({ providedIn: 'root' })
export class StatisticCollector {
    private offlineEvents = [];

    constructor(private sessionService: SessionService) {}

    public saveEvent(
        category: string,
        action: string,
        optionalName: string,
        optionalValue = 0
    ) {
        window._paq.push([
            'trackEvent',
            category,
            action,
            optionalName,
            optionalValue,
        ]);
    }

    public saveOfflineEvent(
        category: string,
        action: string,
        optionalName: string,
        optionalValue = 0
    ) {
        this.offlineEvents.push([
            'trackEvent',
            category,
            action,
            optionalName,
            optionalValue,
        ]);
    }

    public executeOfflineEvents() {
        this.offlineEvents.forEach((item) => {
            window._paq.push([...item]);
        });
        this.offlineEvents = [];
    }

    /**
     * Send tracking request to Matomo
     */
    public trackPageView() {
        const _paq = window._paq;
        const userId = this.generateUserId();
        const setSiteId = this.sessionService.trackingCode;
        let currentUrl = location.href;
        setSiteId && _paq.push(['setSiteId', setSiteId]);
        userId && _paq.push(['setUserId', userId]);
        _paq.push(['setReferrerUrl', currentUrl]);
        currentUrl = '' + window.location.hash.substr(1);
        _paq.push(['setCustomUrl', currentUrl]);
        _paq.push(['setDocumentTitle', document.domain + '/' + document.title]);
        _paq.push(['setCookieDomain', '*.avizia.com']);
        // remove all previously assigned custom variables, requires Piwik 3.0.2
        _paq.push(['deleteCustomVariables', 'page']);
        _paq.push(['setGenerationTimeMs', 0]);
        _paq.push(['trackPageView']);
        // make Piwik aware of newly added content
        const content = document.getElementById('appBody');
        _paq.push(['MediaAnalytics::scanForMedia', content]);
        _paq.push(['FormAnalytics::scanForForms', content]);
        _paq.push(['trackContentImpressionsWithinNode', content]);
        _paq.push(['enableLinkTracking']);
    }

    private generateUserId(): string | null {
        const endpoint = this.sessionService.getEndpoint();
        const facility_name = endpoint.facility_name || '';
        const name = endpoint.name || '';
        let hardware;
        switch (endpoint.endpoint_type_id) {
            case 'APGARCODEC':
                hardware = 'American Well 250';
                break;
            case 'INTMTVKIT':
                hardware = 'American Well TV Kit';
                break;
            default:
                hardware = '';
                break;
        }
        const generatedUserId = `${name} - ${facility_name} - ${hardware}`;
        return generatedUserId || null;
    }
}
