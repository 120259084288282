import { ERROR_MESSAGES, NETWORK_ERRORS } from '@/shared/constants';
import { IEndpointConfiguration } from '@/shared/interfaces';
import { Queue } from '@/shared/queue/queue';
import {
    selectIsAuthenticated,
    selectIsFleetConnected,
    selectIsInternetConnected,
} from '@/shared/storage/selectors';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    asyncScheduler,
    combineLatest,
    Subscription,
    throwError,
    TimeoutError,
    timer,
} from 'rxjs';
import {
    observeOn,
    take,
    takeWhile,
    timeout,
    distinctUntilChanged,
    catchError,
    filter,
} from 'rxjs/operators';
import { LoggerService } from '../logger/logger.service';
import { SessionService } from '../session';

@Injectable({ providedIn: 'root' })
export class MetricService implements OnDestroy {
    // TODO can be deleted -> no used
    _isInitialized = false;
    _messageQueue = new Queue();
    config: IEndpointConfiguration;
    private _continue = true;
    private _alive = true;
    private _subscriptions: Subscription[] = [];
    private _isFleetApiOnline: boolean;
    private _isAuthenticated: boolean;
    private _prevErr: string;

    constructor(
        private _http: HttpClient,
        private _sessionService: SessionService,
        private _loggerService: LoggerService,
        private _store: Store
    ) {
        this._subscriptions.push(
            combineLatest([
                this._store.select(selectIsAuthenticated),
                this._store.select(selectIsFleetConnected),
            ])
                .pipe(distinctUntilChanged())
                .subscribe(([isAuthenticated, isFleetConnected]) => {
                    this._isAuthenticated = isAuthenticated;
                    this._isFleetApiOnline = isFleetConnected;

                    this._isAuthenticated && this._isFleetApiOnline
                        ? !this._continue && this.startQueue()
                        : this.pauseQueue();
                })
        );
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((s) => s && s.unsubscribe());
        this._alive = false;
    }

    startQueue(): void {
        this._continue = true;
        this.init();
    }

    pauseQueue(): void {
        this._continue = false;
    }

    enqueue(item: any): void {
        this._messageQueue.sendMessageQueue(item);
    }

    sendDefaultMetrics(): void {
        const metrics = {
            call_state: +false,
            mute_state: +false,
            codec_registration: +true,
            endpoint_serial_number: this._sessionService.deviceInfo.deviceId,
            os_version: this._sessionService.deviceInfo.osVersion,
            app_version: this._sessionService.deviceInfo.appVersion,
            application_update_status: 'IDLE',
            os_update_status: 'IDLE',
        };
        this.sendMetrics(metrics);
    }

    init(): void {
        if (this._continue) {
            this.sendDefaultMetrics();
            const observable$ = this._messageQueue.getMessageQueue();
            this._subscriptions.push(
                observable$
                    .pipe(
                        takeWhile(() => this._alive),
                        filter(() => this._continue),
                        observeOn(asyncScheduler)
                    )
                    .subscribe((metricObj) => {
                        this.sendMetrics(metricObj);
                    })
            );
        }
    }

    sendMetrics(deviceMetrics): void {
        if (deviceMetrics && !!this._sessionService.deviceId) {
            deviceMetrics = Object.assign(
                { device_id: this._sessionService.deviceId },
                deviceMetrics
            );

            this._http
                .post(
                    `${this._sessionService.fleetUrl}${'v1/metrics/send'}`,
                    deviceMetrics,
                    {
                        responseType: 'json',
                    }
                )
                .pipe(
                    take(1),
                    timeout(1000 * 60 * 2),
                    catchError((error) => {
                        // Handle timeout
                        if (error instanceof TimeoutError) {
                            return throwError(ERROR_MESSAGES.TIMEOUT_ERROR);
                        }
                        // Return other errors
                        return throwError(error);
                    })
                )
                .subscribe({
                    error: (sendMetricsErr) => {
                        const { status = '', statusText } = sendMetricsErr;
                        const stringifiedErr = JSON.stringify(sendMetricsErr);

                        if (
                            this._prevErr !== stringifiedErr &&
                            statusText !== ERROR_MESSAGES.TIMEOUT_ERROR &&
                            statusText !== ERROR_MESSAGES.UNKNOWN_ERROR &&
                            status !== 0 &&
                            status !== 401
                        ) {
                            this._loggerService.error(
                                'sendMetrics:error',
                                sendMetricsErr
                            );
                        }

                        this._prevErr = stringifiedErr;

                        if (
                            statusText === ERROR_MESSAGES.HTTP_ERROR_NO_INTERNET
                        ) {
                            this._store
                                .select(selectIsInternetConnected)
                                .pipe(
                                    takeWhile(() => this._alive),
                                    distinctUntilChanged()
                                )
                                .subscribe((isInternetConnectedFlag) => {
                                    if (
                                        this._isAuthenticated &&
                                        isInternetConnectedFlag &&
                                        this._isFleetApiOnline
                                    ) {
                                        this.startQueue();
                                    } else {
                                        this.pauseQueue();
                                    }
                                });
                        } else {
                            if (NETWORK_ERRORS.includes(status.toString())) {
                                timer(30000).subscribe(() => {
                                    this.retrySendMetrics(deviceMetrics);
                                });
                            }
                        }
                    },
                });
        }
    }

    retrySendMetrics(deviceMetrics): void {
        if (!deviceMetrics.retry) {
            this._loggerService.info(
                'metrics.sendMetrics:retry',
                'Retrying sendMetrics'
            );
            deviceMetrics.retry = true;
            this.enqueue(deviceMetrics);
        }
    }
}
