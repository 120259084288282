import { createSelector } from '@ngrx/store';

import { IEndpointConfiguration } from '@shared/interfaces';

import { selectConfigs } from './universal-selectors';

export const selectConfig = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) => config
);

export const selectSpeakerVolumeLevel = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) =>
        (config && config.AudioOutputDefaultVolume) || 50
);

export const selectShowKeyboard = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) =>
        (config && config.onscreen_keyboard) || false
);

export const selectIsSpeakerMuted = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) =>
        (config && config.isSpeakerMuted) || false
);

export const selectIsMicMuted = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) => (config && config.isMicMuted) || false
);

export const selectIsCameraOff = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) => (config && config.isCameraOff) || false
);

export const selectUpdateType = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) => (config && config.update_type) || false
);

export const selectIsScreenSaverEnabled = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) =>
        (config && config.enable_screensaver) || false
);

export const selectScreenSaverTimeout = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) =>
        (config && config.screensaver_timeout) || 10
);

export const selectIsTurnOffDisplayEnabled = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) =>
        (config && config.enable_turn_off_display) || false
);

export const selectTurnOffDisplayTimeout = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) =>
        (config && config.turn_off_display_timeout) || 10
);

export const selectIsSettingsPinEnabled = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) =>
        (config && config.enable_settings_pin) || false
);

export const selectSettingsPin = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) => config && config.settings_pin
);

export const selectScreenSaverPin = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) => config && config.screensaver_pin
);

export const selectAudioInput = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) => config && config.AudioInput
);

export const selectAudioOutput = createSelector(
    selectConfigs,
    (config: IEndpointConfiguration) => config && config.AudioOutput
);
