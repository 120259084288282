import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IConfirmDialog {
    icon?: string;
    title: string;
    content: string;
    noText?: string;
    yesText?: string;
    confirmBtnColor?: string;
}

export enum ConfirmAction {
    Icon = 'icon',
    Yes = 'yes',
    No = 'no',
}

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements OnInit {
    public confirmBtnColor = 'simple';

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IConfirmDialog
    ) {}

    ngOnInit() {
        const { confirmBtnColor = 'simple' } = this.data;
        this.confirmBtnColor = confirmBtnColor;
    }

    closeModal(action: ConfirmAction | string): void {
        this.dialogRef.close(action);
    }
}
