<div class="header-icons px2 dashboard-connection">
    <div *ngIf="isWifiConnected && wifiSignal >= 80 && wifiSignal <= 100">
        <span class="normal-size icon icon-baseline-signal_wifi_4_bar-24px layered" style="color: black">
            <span class="path1"></span>
            <span class="path2"></span>
        </span>
    </div>
    <div *ngIf="isWifiConnected && wifiSignal >= 60 && wifiSignal < 80">
        <span class="normal-size icon icon-baseline-signal_wifi_3_bar-24px layered" style="color: black">
            <span class="path1"></span>
            <span class="path2"></span>
        </span>
    </div>
    <div *ngIf="isWifiConnected && wifiSignal >= 40 && wifiSignal < 60">
        <span class="normal-size icon icon-baseline-signal_wifi_2_bar-24px layered" style="color: black">
            <span class="path1"></span>
            <span class="path2"></span>
        </span>
    </div>
    <div *ngIf="isWifiConnected && wifiSignal >= 20 && wifiSignal < 40">
        <span class="normal-size icon icon-baseline-signal_wifi_1_bar-24px layered" style="color: black">
            <span class="path1"></span>
            <span class="path2"></span>
        </span>
    </div>
    <div *ngIf="isWifiConnected && wifiSignal >= 0 && wifiSignal < 20">
        <span class="normal-size icon icon-baseline-signal_wifi_0_bar-24px layered" style="color: black">
            <span class="path1"></span>
            <span class="path2"></span>
        </span>
    </div>
    <div *ngIf="isEthernetConnected">
        <span class="normal-size icon icon-ethernet" style="color: black"></span>
    </div>
</div>
