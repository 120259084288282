import { createSelector } from '@ngrx/store';

import {
    IDevice,
    IEndpointConfiguration,
    IMediaDevices,
} from '@shared/interfaces';

import {
    fSelectMediaDevicesAudioInput,
    fSelectMediaDevicesAudioOutput,
    fSelectMediaDevicesPeripheralInput,
    fSelectMediaDevicesVideoInput,
    selectConfigs,
    selectMediaDevices,
} from './universal-selectors';

import { PERIPHERALS } from '@shared/constants';

const defaultAudioOutput = (
    mediaDevices: IMediaDevices,
    config: IEndpointConfiguration
): IDevice => {
    const audioOutputs = fSelectMediaDevicesAudioOutput(mediaDevices);
    const deviceOrder = [
        'Phnx MT202', // NYP, UVM need to default Phnx first
        'PCM2704C',
        'Built-in',
    ];

    if (config && config.AudioOutput) {
        deviceOrder.push(config.AudioOutput);
    }

    if (audioOutputs && audioOutputs.length > 0) {
        deviceOrder.push(audioOutputs[0].label);
    }

    return (
        audioOutputs.find((val) => deviceOrder.indexOf(val.label) >= 0) || null
    );
};

const defaultAudioInput = (
    mediaDevices: IMediaDevices,
    config: IEndpointConfiguration
): IDevice => {
    const audioInputs = fSelectMediaDevicesAudioInput(mediaDevices);
    const deviceOrder = [
        'Phnx MT202', // NYP, UVM need to default Phnx first
        'PCM2704C',
        'Built-in',
    ];

    if (config && config.AudioInput) {
        deviceOrder.push(config.AudioInput);
    }

    if (audioInputs && audioInputs.length > 0) {
        deviceOrder.push(audioInputs[0].label);
    }

    return (
        audioInputs.find((val) => deviceOrder.indexOf(val.label) >= 0) || null
    );
};

const defaultVideoInput = (
    mediaDevices: IMediaDevices,
    config: IEndpointConfiguration
) => {
    let isExists;
    const videoInputs = fSelectMediaDevicesVideoInput(mediaDevices);

    if (videoInputs && videoInputs.length > 0) {
        isExists = videoInputs.find((vi) => vi.isFeccEnabled);

        if (config && config.VideoInput) {
            isExists = videoInputs.find(
                (device) => device.label.indexOf(config.VideoInput) >= 0
            );
        }

        return isExists || videoInputs[0];
    }
    return {
        deviceId: null,
    };
};

export const selectSelectedAudioOutput = createSelector(
    selectMediaDevices,
    selectConfigs,
    (mediaDevices: IMediaDevices, config: IEndpointConfiguration): IDevice => {
        return (
            fSelectMediaDevicesAudioOutput(mediaDevices).find(
                (val) => val.label === config.AudioOutput
            ) || defaultAudioOutput(mediaDevices, config)
        );
    }
);

export const selectDefaultAudioOutput = createSelector(
    selectMediaDevices,
    selectConfigs,
    defaultAudioOutput
);

export const selectSelectedAudioInput = createSelector(
    selectMediaDevices,
    selectConfigs,
    (mediaDevices: IMediaDevices, config: IEndpointConfiguration): IDevice => {
        return (
            fSelectMediaDevicesAudioInput(mediaDevices).find(
                (val) => val.label === config.AudioInput
            ) || defaultAudioInput(mediaDevices, config)
        );
    }
);

export const selectDefaultAudioInput = createSelector(
    selectMediaDevices,
    selectConfigs,
    defaultAudioInput
);

export const selectSelectedVideoInput = createSelector(
    selectMediaDevices,
    selectConfigs,
    (mediaDevices: IMediaDevices, config: IEndpointConfiguration) => {
        return (
            fSelectMediaDevicesVideoInput(mediaDevices).find(
                (val) => val.label === config.VideoInput
            ) || defaultVideoInput(mediaDevices, config)
        );
    }
);

export const selectDefaultVideoInput = createSelector(
    selectMediaDevices,
    selectConfigs,
    defaultVideoInput
);

export const selectAllConfigs = createSelector(
    selectConfigs,
    (state: IEndpointConfiguration) => state
);

export const selectDefaultDevices = createSelector(
    selectMediaDevices,
    selectConfigs,
    (mediaDevices: IMediaDevices, config: IEndpointConfiguration) => ({
        audio: defaultAudioInput(mediaDevices, config),
        video: defaultVideoInput(mediaDevices, config),
    })
);

export const selectAllDevices = createSelector(
    selectMediaDevices,
    selectConfigs,
    (mediaDevices: IMediaDevices, config: IEndpointConfiguration) => {
        return {
            deviceSource: fSelectMediaDevicesPeripheralInput(mediaDevices)
                .map((item) =>
                    PERIPHERALS.filter((PERIPHERAL) => {
                        return item.label.indexOf(PERIPHERAL.getLabel()) >= 0;
                    })
                )
                .reduce((a, b) => a.concat(b), []),
            cameraSource: fSelectMediaDevicesVideoInput(mediaDevices),
            microphoneSource: fSelectMediaDevicesAudioInput(mediaDevices),
            speakerSource: fSelectMediaDevicesAudioOutput(mediaDevices),

            selectedAudioOutput:
                fSelectMediaDevicesAudioOutput(mediaDevices).find(
                    (val) => val.deviceId === config.AudioOutput
                ) || defaultAudioOutput(mediaDevices, config),

            selectedAudioInput:
                fSelectMediaDevicesAudioInput(mediaDevices).find(
                    (val) => val.deviceId === config.AudioInput
                ) || defaultAudioInput(mediaDevices, config),

            selectedVideoInput:
                fSelectMediaDevicesVideoInput(mediaDevices).find(
                    (val) => val.deviceId === config.VideoInput
                ) || defaultVideoInput(mediaDevices, config),
        };
    }
);
