export const DEFAULT_OPTIONS = {
    camera: {
        id: 'Select Camera',
        name: 'Select Camera',
        disabled: true,
    },
    microphone: {
        id: 'Select Microphone',
        name: 'Select Microphone',
        disabled: true,
    },
    speaker: {
        id: 'Select Speaker',
        name: 'Select Speaker',
        disabled: true,
    },
};

export const FECC_ZOOM_DEFAULTS = {
    tooltips: false,
    step: 1,
    start: 0,
    startValue: 1,
    resetValue: 0,
    range: {
        min: 0,
        max: 20,
    },
};
