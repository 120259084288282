/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { IEndpointConfiguration } from '@shared/interfaces';
const UPDATE_ENDPOINT_CONFIG = 'UPDATE_ENDPOINT_CONFIG';
const RELOAD_ENDPOINT_CONFIG = 'RELOAD_ENDPOINTCONFIG';
const GET_ENDPOINT_CONFIG = 'GET_ENDPOINT_CONFIG';

export const updateEndpointConfig = createAction(
    UPDATE_ENDPOINT_CONFIG,
    props<{ key: string; value: any; skipSettings?: boolean }>()
);
export const reloadEndpointConfig = createAction(
    RELOAD_ENDPOINT_CONFIG,
    props<{ endpointConfig: IEndpointConfiguration }>()
);

export const getEndpointConfig = createAction(GET_ENDPOINT_CONFIG);
