export enum SettingsTabs {
    'General' = 'general',
    'Audio/Video' = 'av',
    'Connectivity' = 'connectivity',
}

export enum SettingsTabsMini {
    'General' = 'general',
    'Connectivity' = 'connectivity',
}

export enum AudioVideoAllowedDevices {
    ApgarCodec = 'APGARCODEC',
    C500 = 'C500',
}

export enum RoamingThresholdTypes {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    MIN = 'MIN',
    MAX = 'MAX',
}

export enum NetworkTypes {
    WIFI = 'WIFI',
    ETHERNET = 'ETHERNET',
    CUSTOM = 'CUSTOM',
}

export const ConnectionMethodsTypes = {
    automatic: { type: 'automatic' },
    manual: { type: 'manual' },
};

export enum AddressProtocolType {
    DHCP = 'DHCP',
    STATIC = 'STATIC',
}

export enum StatusTypes {
    CONNECTED = 'CONNECTED',
}
