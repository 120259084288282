import { Injectable } from '@angular/core';
import { IIpcResponseHandler } from '../../interfaces';

@Injectable({ providedIn: 'root' })
export class FileResponseService implements IIpcResponseHandler {
    handleResponse(response): void {
        const { file: fileResponse } = response;
        const { command } = fileResponse;
        switch (command) {
            default:
                break;
        }
    }
}
