import { Injectable } from '@angular/core';
import { StorageService } from '../storage';
import { IIpcResponseHandler } from '../../interfaces';
import { SessionService } from '../session';
import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';

@Injectable({ providedIn: 'root' })
export class PowerResponseService implements IIpcResponseHandler {
    constructor(
        private _storageService: StorageService,
        private _sessionService: SessionService
    ) {}
    handleResponse(response): void {
        if (!this._sessionService.deviceInstance) {
            return;
        }
        if (this._sessionService.deviceInstance.type.toLowerCase() !== 'c500') {
            const data = response.power_cradle || response.power;

            if (
                data &&
                (data.event === SYSTEM_COMMANDS_TYPES.POWER_STATUS ||
                    data.command === SYSTEM_COMMANDS_TYPES.GET_POWER_STATS)
            ) {
                this._storageService.updateBatteryStatus(
                    this._sessionService.deviceInstance.handleBatteryResponse(
                        response
                    )
                );
            }
        }
    }
}
