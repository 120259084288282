import { Injectable, OnDestroy } from '@angular/core';
import { LoggerService } from '../logger';
import { MetricService } from '../metrics';
// eslint-disable-next-line import/no-extraneous-dependencies
import find from 'lodash/find';
// eslint-disable-next-line import/no-extraneous-dependencies
import each from 'lodash/each';
import { IpcService } from '../ipc';
import { interval, of, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PTZAxisService implements OnDestroy {
    private _host = 'https://localhost:1440';
    private _username = 'root';
    private _pasword = 'iEnU0RjI';
    private _cameraDetected = false;
    private _previousState = false;
    private _axisCameraPollingStatus: Subscription = null;

    ngOnDestroy(): void {
        throw new Error('Method not implemented.');
    }

    constructor(
        private _loggerService: LoggerService,
        private _metricService: MetricService,
        private _ipcService: IpcService,
        private _httpClient: HttpClient
    ) {}

    init(): void {
        this._loggerService.info('ptzaxis:init', 'init');
        this.startPollingAxisCameraStatus();
    }

    move(position) {
        const cmd = `${this._host}/axis-cgi/com/ptz.cgi?move=${position}&camera=1`;
        this._httpClient.get(cmd);
        if (position === 'home') {
            this.getHomeZoom();
        }
    }

    movePosition(position) {
        const cmd = `${this._host}/axis-cgi/com/ptz.cgi?center=${position[1]},${position[2]}&imagewidth=${position[3]}&imageheight=${position[4]}&camera=1`;
        this._httpClient.get(cmd);
    }
    zoom(zoom) {
        const cmd = `${this._host}/axis-cgi/com/ptz.cgi?zoom=${zoom}&camera=1`;
        this._httpClient.get(cmd);
    }

    panTiltZoom(ptzValues: any): void {
        let path;
        // the string we get from fleet right now is - Need to Revisit
        // home: ptz,null,null,null,null,home
        // click: ptz,1069,892,1834,1031
        // zoom: ptz,undefined,undefined,8420

        if (ptzValues.length === 6) {
            this.move('home');
        } else if (ptzValues.length === 5) {
            path =
                '/axis-cgi/com/ptz.cgi?center=' +
                ptzValues[1] +
                ',' +
                ptzValues[2] +
                '&imagewidth=' +
                ptzValues[3] +
                '&imageheight=' +
                ptzValues[4] +
                '&camera=1';
        } else if (ptzValues.length === 4) {
            path = '/axis-cgi/com/ptz.cgi?zoom=' + ptzValues[3] + '&camera=1';
        }

        this._httpClient
            .get(`${this._host}:80${path}`)
            .pipe(
                catchError((err) => {
                    this._loggerService.log('ptzaxis:err', err);
                    return of([]);
                })
            )
            .subscribe((response) => {
                this._loggerService.info(
                    'ptzaxis:panTiltZoom response',
                    JSON.stringify(response)
                );
                return response;
            });
    }

    isCameraConnected(): void {
        this._loggerService.info(
            'ptzaxis:isCameraConnected',
            'isCameraConnected'
        );
        this._httpClient
            .get(
                `${this._host}:80/axis-cgi/param.cgi?action=list&group=root.Brand.ProdShortName`
            )
            .pipe(
                timeout(5000),
                catchError((err) => {
                    this._cameraDetected = false;
                    this._metricService.enqueue({
                        camera_connected: +false,
                        codec_ver: null,
                    });
                    this.logCameraStatus({
                        message: err.message,
                        stack: err.stack,
                    });
                    return of([]);
                })
            )
            .subscribe((response: any) => {
                this._cameraDetected = true;
                const [, cameraType] = response.body
                    ? response.body.trim().split('=')
                    : [null, null];
                this._metricService.enqueue({
                    camera_connected: +true,
                    codec_ver: 'axis_big',
                    camera_type: cameraType,
                });
                this.logCameraStatus(response);
            });
    }

    logCameraStatus(status): void {
        if (this._cameraDetected !== this._previousState) {
            this._loggerService.info(
                'ptzaxis:logCameraStatus',
                'Camera Detected: ' +
                    this._cameraDetected +
                    ' status: ' +
                    JSON.stringify(status)
            );
            this._previousState = this._cameraDetected;
        }
    }

    setCameraHomePosition() {
        this._httpClient
            .get(
                `${this._host}/axis-cgi/com/ptzconfig.cgi?setserverpresetname=Apgar&home=yes`
            )
            .pipe(
                catchError((err) => {
                    this._loggerService.log(
                        'ptzaxis:setCameraHomePosition:err',
                        err
                    );
                    return of([]);
                })
            )
            .subscribe((response) => {
                this._loggerService.info(
                    'ptzaxis:setCameraHomePosition',
                    'Successfully set Camera Home Position'
                );
                return response;
            });
    }

    stopPollingAxisCameraStatus(): void {
        if (this._axisCameraPollingStatus) {
            this._axisCameraPollingStatus.unsubscribe();
            this._axisCameraPollingStatus = null;
        }
    }

    startPollingAxisCameraStatus(): void {
        if (!this._axisCameraPollingStatus) {
            //TODO Change interval
            this.getUsers();
            this.getCameraFirmware();
            this.getCameraInfo();
            this._axisCameraPollingStatus = interval(60 * 100).subscribe(() => {
                this.pingCamera();
            });
        }
    }

    pingCamera() {
        const path = `${this._host}/axis-cgi/pingtest.cgi?ip=192.168.0.2`;
        this._httpClient.get(path).subscribe(
            () => {
                this._cameraDetected = true;
            },
            () => {
                this._cameraDetected = true;
            }
        );
        this._metricService.enqueue({
            camera_connected: this._cameraDetected,
            codec_ver: 'axis_big',
        });
    }

    restart() {
        this._httpClient.get(this._host + '/axis-cgi/restart.cgi').subscribe(
            () => {
                this._loggerService.info(
                    'ptzaxis:restart()',
                    'camera restarted'
                );
            },
            (error) => {
                this._loggerService.log('ptzaxis:restart()', error);
            }
        );
    }

    getUsers() {
        this._httpClient
            .get(this._host + '/axis-cgi/admin/pwdgrp.cgi?action=get', {
                responseType: 'json',
            })
            .subscribe((response: any) => {
                if (response) {
                    const result = response.split('\r\n');
                    result.forEach((element) => {
                        if (element.indexOf('users') >= 0) {
                            const [key, value] = element.split('=');
                            const metrics = {};
                            metrics[key] = value;
                            this._metricService.enqueue(metrics);
                        }
                    });
                }
            });
    }

    getCameraInfo() {
        this._httpClient
            .post(this._host + '/axis-cgi/basicdeviceinfo.cgi', {
                apiVersion: '1.0',
                method: 'getAllProperties',
            })
            .subscribe((response: any) => {
                const type =
                    (response.body.data &&
                        response.body.data.propertyList &&
                        response.body.data.propertyList.ProdShortName) ||
                    null;
                this._metricService.enqueue({
                    camera_type: type,
                });
            });
    }

    getCameraFirmware() {
        this._httpClient
            .post(this._host + '/axis-cgi/basicdeviceinfo.cgi', {
                apiVersion: '1.0',
                method: 'getAllProperties',
            })
            .subscribe((response: any) => {
                const firmWareObject = response.body.data;
                const metrics = {
                    camera_firmware_version:
                        firmWareObject['activeFirmwareVersion'],
                    camera_firmware_part: firmWareObject['activeFirmwarePart'],
                };
                this._metricService.enqueue(metrics);
            });
    }

    createTeleHealthUser() {
        this._httpClient
            .get(
                '/axis-cgi/admin/pwdgrp.cgi?action=add&user=telehealth&pwd=telehealth&grp=users&sgrp=operator:viewer:ptz'
            )
            .subscribe((response) => {
                if (response) {
                    // const result = response..split('\n');
                    // result.forEach((element) => {
                    //     if (element.indexOf('users') >= 0) {
                    //         const [key, value] = element.split('=');
                    //         const metrics = {};
                    //         metrics[key] = value;
                    //         this._metricService.enqueue(metrics);
                    //     }
                    // });
                }
            });
    }
    deleteTeleHealthUser() {
        this._httpClient
            .get('/axis-cgi/admin/pwdgrp.cgi?action=remove&user=telehealth')
            .subscribe(() => {
                // if (response) {
                //     const result = response.body.split('\n');
                //     result.forEach((element) => {
                //         if (element.indexOf('users') >= 0) {
                //             const [key, value] = element.split('=');
                //             const metrics = {};
                //             metrics[key] = value;
                //             this._metricService.enqueue(metrics);
                //         }
                //     });
                // }
            });
    }
    // Get all preset home positions. Find the home position which is named Apgar
    // and extract the Zoom value and send it to Apgar web
    getHomeZoom(): void {
        this._httpClient
            .get(
                `${this._host}/axis-cgi/param.cgi?action=list&group=root.PTZ.Preset.P0.Position`
            )
            .pipe(
                catchError((err) => {
                    this._loggerService.log('getHomeZoom:err', err);
                    return of([]);
                })
            )
            .subscribe((response: any) => {
                const positions = response.body
                    ? response.body.trim().split('\n')
                    : null;
                let zoomLevel;
                each(positions, (position) => {
                    if (position.indexOf('Name=Apgar') > -1) {
                        const substring = position.replace(
                            'Name=Apgar',
                            'Data'
                        );
                        let homePosition = find(positions, (p) => {
                            return p.indexOf(substring) > -1;
                        });
                        homePosition = homePosition.split(':');
                        const zoom = find(homePosition, (p) => {
                            return p.indexOf('zoom=') > -1;
                        });
                        [, zoomLevel] = zoom ? zoom.split('=') : ['zoom', 0];
                    }
                });

                if (zoomLevel) {
                    // zoom from camera ranges from 1-9999. send Zoom to Apgar Web in percentage
                    this._metricService.enqueue({
                        zoom_level: zoomLevel / 100,
                    });
                    this._ipcService.notify('zoom_changed', {
                        zoom_level: zoomLevel / 100,
                    });
                }
            });
    }
}
