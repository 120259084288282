import { createSelector } from '@ngrx/store';
import { AppState } from '@/app.reducer';
import { IBatteryStatus } from '@/shared/interfaces';

export const selectBatteryState = (state: AppState): IBatteryStatus =>
    state.battery;

export const selectIsAcPowerConnected = createSelector(
    selectBatteryState,
    (batteryState: IBatteryStatus) => batteryState?.acPowerConnected
);

export const selectBatteryLevel = createSelector(
    selectBatteryState,
    (batteryState: IBatteryStatus) => batteryState?.level
);

export const selectIsBatteryConnected = createSelector(
    selectBatteryState,
    (batteryState: IBatteryStatus) => batteryState?.batteryConnected
);
