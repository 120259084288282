import { Injectable, OnDestroy } from '@angular/core';
import { LoggerService } from '../logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import find from 'lodash/find';

import { IpcService } from '../ipc';
import { FECC_DEVICES, FECC_POSITION } from '@/shared/constants';
import { first, switchMap, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PTZService implements OnDestroy {
    private _camera;
    private _capabilities;
    private _feccCamera;
    private _zoom = 0;
    private _executeAfterChangeCamera: () => void;

    ngOnDestroy(): void {
        throw new Error('Method not implemented.');
    }

    constructor(
        private _loggerService: LoggerService,
        private _ipcService: IpcService
    ) {
        this.init().subscribe(() => {
            this.setFeccPosition(FECC_POSITION.PRIVACY);
        });
    }

    init(): Observable<any> {
        return this._ipcService.discoverPtzDevices().pipe(
            first(),
            tap(({ camera_list: devices = [] }) => {
                this._loggerService.info(
                    'ptz:v4l:init',
                    JSON.stringify(devices)
                );
            }),
            switchMap(({ camera_list: devices = [] }) => {
                if (devices.length === 0) {
                    return of(null);
                }

                let ptzDevice: { path: string };
                for (const device of devices) {
                    this._feccCamera = FECC_DEVICES.find(
                        (d) =>
                            device.name
                                .toLowerCase()
                                .indexOf(d.v4lName.toLowerCase()) > -1
                    );

                    if (this._feccCamera) {
                        ptzDevice = device;
                        // check other FECC cameras if it needs to override EPTZ Camera
                        if (this._feccCamera.name !== 'EPTZ Camera') {
                            break;
                        }
                    }
                }

                if (!ptzDevice) {
                    this._camera = undefined;
                    this._capabilities = undefined;
                    return throwError('no v4l camera found');
                }
                this._camera = ptzDevice;
                return this._ipcService
                    .getPTZCapabilities(ptzDevice.path)
                    .pipe(first());
            }),
            tap((capabilities) => {
                this._capabilities = capabilities;
            })
        );
    }

    absolutePanTiltZoom(
        pan: number,
        tilt: number,
        zoom: number,
        position: string
    ): void {
        if (!this._camera) {
            this._loggerService.error(
                'ptz:v4l:absolutePanTilt',
                'Not found camera path'
            );
            return;
        }
        if (position && this._feccCamera) {
            const ptzValue = this._feccCamera[position];
            this._loggerService.info(
                'ptz:v4l:absolutePanTilt',
                'Setting FECC Camera to ' + position + ' position ' + ptzValue
            );
            [pan, tilt, zoom] = ptzValue.split(',');
        }
        // pan tilt adjustment needed for fecc movements.
        this._ipcService
            .setAbsolutePanTiltZoom(
                pan * 3600,
                tilt * 3600,
                !!zoom ? zoom : this._zoom,
                this._camera.path
            )
            .pipe(first())
            .subscribe(() => {
                this._ipcService
                    .getPTZCurrenntPosition(this._camera.path)
                    .pipe(first())
                    .subscribe((currPosition: any) => {
                        this._zoom = currPosition.zoom;
                    });
            });
    }

    setFeccPosition(position: string): void {
        this.absolutePanTiltZoom(null, null, null, position);
    }

    setFeccAfterChangeCamera(position: string): void {
        this._executeAfterChangeCamera = this.absolutePanTiltZoom.bind(
            this,
            null,
            null,
            null,
            position
        );
    }

    changeCamera(camera): void {
        this._ipcService
            .discoverPtzDevices()
            .pipe(
                first(),
                switchMap(({ camera_list: devices = [] }) => {
                    const feccCamera = find(devices, (d) => {
                        return (
                            d.name
                                .toLowerCase()
                                .indexOf(camera.v4lName.toLowerCase()) > -1
                        );
                    });
                    if (feccCamera) {
                        feccCamera.privacy = camera.privacy;
                        feccCamera.home = camera.home;
                        this._camera = feccCamera;
                    }
                    return feccCamera?.path
                        ? this._ipcService.getPTZCapabilities(feccCamera.path)
                        : of(null);
                })
            )
            .subscribe((capabilities) => {
                this._capabilities = capabilities;
                !!this._executeAfterChangeCamera &&
                    this._executeAfterChangeCamera();
                this._executeAfterChangeCamera = null;
            });
    }
}
