import { selectIsAuthenticated } from '@/shared/storage/selectors/auth.selector';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private _isAuthenticated = false;
    constructor(private _store: Store) {}

    canActivate(): boolean {
        this._store
            .select(selectIsAuthenticated)
            .subscribe((isAuthenticated: boolean) => {
                this._isAuthenticated = isAuthenticated;
            });
        return this._isAuthenticated;
    }
}
