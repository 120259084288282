export const Stethoscope = [
    `
        <div class="hslide-top items-start steth-image">
        <img class="" src="/assets/images/peripherals-devices/steth1.png" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>
            Ensure your stethoscope is connected to the Stethoscope<br />
            Adapter's microphone input.
        </h3>
        <span class="hslide-step">1 of 7</span>
    </div>
    `,
    `
    <div class="hslide-top items-start steth-image">
        <img class="" src="./assets/images/peripherals-devices/steth2.png" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>
            Hold any button the ThinkLabs Stethoscope for<br />
            2 seconds to power it on.
        </h3>
        <span class="hslide-step">2 of 7</span>
    </div>
    `,
    `
    <div class="hslide-top items-start steth-image">
        <img class="" src="./assets/images/peripherals-devices/steth3.png" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>If it is out of batteries, charge it with the white USB -> <br />3.5mm charging cable.</h3>
        <span class="hslide-step">3 of 7</span>
    </div>
    `,
    `
    <div class="hslide-top items-start steth-image">
        <img class="" src="./assets/images/peripherals-devices/steth4.png" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>
            Click the Share button to share the stethoscope audio<br />
            into the call.
        </h3>
        <span class="hslide-step">4 of 7</span>
    </div>
    `,
    `
    <div class="hslide-top items-start steth-image">
        <img class="" src="./assets/images/peripherals-devices/steth5.png" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>
            Change the filter mode by holding the top left button for<br />
            two seconds.
        </h3>
        <span class="hslide-step">5 of 7</span>
    </div>
    `,
    `
    <div class="hslide-top items-start steth-image">
        <img class="" src="./assets/images/peripherals-devices/steth6.png" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>
            Adjust the volume using the bottom<br />
            two buttons.
        </h3>
        <span class="hslide-step">6 of 7</span>
    </div>
    `,
    `
    <div class="hslide-top items-start steth-image">
        <img class="" src="./assets/images/peripherals-devices/steth8.png" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>
            To list to the steth audio locally, connect headphones to<br />
            the headphone output of the USB Adapter.
        </h3>
        <span class="hslide-step">7 of 7</span>
    </div>
    `,
];

export const StethoscopeC500 = {
    step0: `
    <!-- Ripley -->
    <div class="hslide-top items-start steth-image">
        <img class="m-t2" src="/assets/images/peripherals-devices/steth1.svg" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>
            Ensure your stethoscope is connected to your device's<br />
            Steth input port on the right side of the device.
        </h3>
        <span class="hslide-step">1 of 7</span>
    </div>
    `,
    step6: `
    <!-- Ripley -->
    <div class="hslide-top items-start steth-image">
        <img class="m-t2" src="assets/images/peripherals-devices/steth8.svg" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>
            To list to the steth audio locally, connect a headset<br />
            to the headset output on the right side of the device.
        </h3>
        <span class="hslide-step">7 of 7</span>
    </div>
    `,
};
