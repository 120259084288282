import { VALIDATORS } from '@/shared/constants';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
    selector: 'app-static-ip-config',
    templateUrl: './static-ip-config.component.html',
})
export class StaticIpConfigComponent {
    public staticIpSettingForm: FormGroup;

    @Output() onChangeDetected: EventEmitter<FormGroup> =
        new EventEmitter<FormGroup>();

    @Input() set defaultStaticIpSetting(formValue) {
        if (!!formValue) {
            const { address, gateway, prefix } = formValue;
            this.staticIpSettingForm.controls['address'].setValue(address);
            this.staticIpSettingForm.controls['gateway'].setValue(gateway);
            this.staticIpSettingForm.controls['prefix'].setValue(prefix);
        }
        this.onChangeDetected.emit(this.staticIpSettingForm);
    }

    constructor(private _formBuilder: FormBuilder) {
        this.staticIpSettingForm = this._formBuilder.group({
            address: [
                '',
                [
                    Validators.required,
                    Validators.pattern(VALIDATORS.IP_VALIDATOR),
                ],
            ],
            gateway: [
                '',
                [
                    Validators.required,
                    Validators.pattern(VALIDATORS.IP_VALIDATOR),
                ],
            ],
            prefix: [
                '',
                [
                    Validators.required,
                    Validators.pattern(VALIDATORS.PREFIX_VALIDATOR),
                ],
            ],
        });
    }
    // Utilities
    onChangeConnectionSettings() {
        this.onChangeDetected.emit(this.staticIpSettingForm);
    }
}
