export const BAND_CONFIGURATION = [
    {
        value: 'BAND_AUTO',
        displayText: 'Auto',
    },
    {
        value: 'BAND_5G',
        displayText: '5 GHz',
    },
    {
        value: 'BAND_2G',
        displayText: '2.4 GHz',
    },
];
