import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    distinctUntilChanged,
    map,
    switchMap,
    take,
    withLatestFrom,
} from 'rxjs/operators';
import { IEndpointConfiguration } from '@shared/interfaces';
import { EndPointService } from '@shared/services/endpoint';
import * as EndpointConfigActions from './endpoint-config.actions';
import * as fromAppReducer from '../../../app.reducer';
import { SessionService } from '@/shared/services';
import { selectAudioInput, selectAudioOutput } from '../selectors';

@Injectable()
export class EndpointConfigEffects {
    constructor(
        private _action$: Actions,
        private _appStore: Store<fromAppReducer.AppState>,
        private _endpointService: EndPointService,
        private _sessionService: SessionService
    ) {
        this._appStore
            .select(selectAudioInput)
            .pipe(distinctUntilChanged())
            .subscribe((selectAudioInput) => {
                this._sessionService?.deviceInstance?.setAudioDefaultSource(
                    selectAudioInput
                );
            });

        this._appStore
            .select(selectAudioOutput)
            .pipe(distinctUntilChanged())
            .subscribe((selectAudioOutput) => {
                this._sessionService?.deviceInstance?.setAudioDefaultSink(
                    selectAudioOutput
                );
            });
    }

    getEndpointConfig = createEffect(
        () =>
            this._action$.pipe(
                ofType(EndpointConfigActions.getEndpointConfig),
                switchMap(() => {
                    const endpointConfigData =
                        this._endpointService.getConfiguration();
                    return endpointConfigData;
                }),
                map((endpointConfigData: IEndpointConfiguration) => {
                    return this._appStore.dispatch(
                        EndpointConfigActions.reloadEndpointConfig({
                            endpointConfig: endpointConfigData,
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    saveEndpointConfig = createEffect(
        () =>
            this._action$.pipe(
                ofType(EndpointConfigActions.updateEndpointConfig),
                withLatestFrom(this._appStore.select('endpointConfig')),
                map(([endpointConfigData, endpointConfigState]) => {
                    const currentEndpointConfig = { ...endpointConfigState };
                    currentEndpointConfig[endpointConfigData.key] =
                        endpointConfigData.value;

                    !endpointConfigData.skipSettings &&
                        this._endpointService
                            .saveConfiguration(currentEndpointConfig)
                            .pipe(take(1))
                            .subscribe(
                                () => {
                                    // fire and forget settings
                                },
                                (err) => console.log('HTTP Error', err)
                            );

                    return EndpointConfigActions.reloadEndpointConfig({
                        endpointConfig: currentEndpointConfig,
                    });
                })
            )
        // { dispatch: false }
    );
}
