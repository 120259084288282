import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeviceService } from '@services/device';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private _deviceService: DeviceService,
        private _router: Router
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                // this._localService.sendLogs({
                //     type: 'error',
                //     module: err.url,
                //     details: `Status: ${err.status}: ${err.statusText}`,
                // });

                // TODO: Need to identify when to redirect.
                // Identify the state before redirecting
                // this._router.navigateByUrl('/error-generic', {
                //     state: { error: JSON.stringify(err) },
                // });

                return throwError(err);
            })
        );
    }
}
