import { VALIDATORS } from '@/shared/constants';
import { IpcService } from '@/shared/services';
import { LoggerService } from '@/shared/services/logger';

import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, first, takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-dns-config',
    templateUrl: './dns-config.component.html',
})
export class DnsConfigComponent implements OnInit, OnDestroy, OnChanges {
    @Input() addDns: boolean;
    @Input() dnsSettingsForm: FormGroup;
    @Output() isDnsChanged: EventEmitter<any> = new EventEmitter<any>();
    public currentDNSServers: string[];
    private _alive = true;

    constructor(
        private _ipcService: IpcService,
        private _loggerService: LoggerService
    ) {}

    ngOnInit() {
        this.dnsSettingsForm.controls['dns1'].setValue('8.8.8.8');
        this.dnsSettingsForm.controls['dns2'].setValue('8.8.4.4');
        this.dnsSettingsForm.controls['dns3'].setValue('');
        this.getDnsServers();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.addDns.currentValue) {
            this.addServersToDNS();
        }
    }

    getDnsServers(): void {
        this._ipcService
            .requestGetDNSServers()
            .pipe(takeWhile(() => this._alive))
            .pipe(catchError(this.handleError))
            .subscribe((response) => {
                this.onDnsServersDataReceived(response);
            });
    }

    onDnsServersDataReceived(res): void {
        const { dns_servers = [] } = res;
        const dns_1 = dns_servers[0] || '8.8.8.8';
        const dns_2 = dns_servers[1] || '8.8.4.4';
        const dns_3 = dns_servers[2] || '';
        this.dnsSettingsForm.controls['dns1'].setValue(dns_1);
        this.dnsSettingsForm.controls['dns2'].setValue(dns_2);
        this.dnsSettingsForm.controls['dns3'].setValue(dns_3);
        this.currentDNSServers = dns_servers;
    }

    addServersToDNS() {
        if (this.isDefaultDnsChanged()) {
            const dnsServersReq = [
                this.dnsSettingsForm.controls['dns1'].value,
                this.dnsSettingsForm.controls['dns2'].value,
                this.dnsSettingsForm.controls['dns3'].value,
            ];
            this._ipcService
                .requestAddServerArrayToDNS(dnsServersReq)
                .pipe(first())
                .subscribe((data) => {
                    const { success = 'true' } = data;
                    if (success === 'true') {
                        this.currentDNSServers = dnsServersReq;
                    }
                });
        }
    }

    isDefaultDnsChanged(): boolean {
        if (!this.currentDNSServers) return false;

        const filteredDnsServersReq = [
            this.dnsSettingsForm.controls['dns1'].value,
            this.dnsSettingsForm.controls['dns2'].value,
            this.dnsSettingsForm.controls['dns3'].value,
        ].filter((item) => item);
        const filteredCurrentDnsServers = this.currentDNSServers.filter(
            (item) => item
        );
        const dnsChangeDetected = filteredDnsServersReq.filter(
            (value: string) => filteredCurrentDnsServers.includes(value)
        ).length;

        return dnsChangeDetected != filteredDnsServersReq.length;
    }

    // Utilities
    onChangeDNS() {
        const controls = this.dnsSettingsForm.controls;
        if (controls['dns2'].value === '' && controls['dns3'].value === '') {
            controls['dns1'].setValidators([
                Validators.required,
                Validators.pattern(VALIDATORS.IP_VALIDATOR),
            ]);
            controls['dns1'].updateValueAndValidity();
        } else {
            controls['dns1'].setValidators([
                Validators.pattern(VALIDATORS.IP_VALIDATOR),
            ]);
            controls['dns1'].updateValueAndValidity();
        }
        this.isDnsChanged.emit(this.isDefaultDnsChanged());
    }

    handleError(err): Observable<any> {
        this._loggerService.error('DnsConfigComponent', err);
        return of({});
    }

    ngOnDestroy() {
        this._alive = false;
    }
}
