import {
    setAppUpdateAvailable,
    waitForAppUpdate,
} from '@/shared/storage/layout/layout.actions';
import {
    IAppUpdate,
    selectAppUpdate,
    selectIntervalToCheckAppUpdate,
} from '@/shared/storage/layout/layout.reducer';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { interval } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
    waitForTriggerUpdateList = ['video', 'waiting-room', 'converge-video'];

    constructor(
        private _updates: SwUpdate,
        private _store: Store,
        protected _router: Router
    ) {
        if (!environment.enabledAutoUpdate) {
            return;
        }
        this._store
            .select(selectIntervalToCheckAppUpdate)
            .pipe(
                switchMap((updateCheckInterval) =>
                    interval(updateCheckInterval)
                )
            )
            .subscribe(() => {
                this._updates.isEnabled && this._updates.checkForUpdate();
            });

        //Log current build time - Help to check auto update application
        console.log(
            `%c Current Version - ${environment['version']}`,
            `%c Current Build Time - ${environment['currentBuildTime']}`,
            `background: green; 
                color: #FFF;
                font-size:16px;
                background-color: green;
                border: 2px blue solid;
                padding: 15px;`
        );
    }

    init() {
        this._updates.available.subscribe((event) => {
            const update = {
                currentVersion: event.available.hash.toString(),
                previousVersion: event.current.hash.toString(),
                isAppUpdateAvailable: true,
            } as IAppUpdate;

            this._store.dispatch(setAppUpdateAvailable({ appUpdate: update }));

            // After having update then it will refresh application.
            // if video call/waiting room/converge video is  inprogress then will be waiting to finish
            // check in every 60s to reload app untill video call/waiting room/converge video is getting finish
            this._store
                .select(selectAppUpdate)
                .pipe(
                    switchMap(() => interval(60 * 1000)),
                    withLatestFrom(this._store.select(selectAppUpdate))
                )
                .subscribe(([, appUpdate]) => {
                    if (
                        appUpdate.isAppUpdateAvailable &&
                        !appUpdate.waitForAppUpdate
                    ) {
                        this._updates.activateUpdate().then(() => {
                            window.location.replace('/');
                        });
                    }
                });
        });

        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const wListUrl = this.waitForTriggerUpdateList.filter(
                    (wList) => {
                        return event.url.includes(wList);
                    }
                );
                this._store.dispatch(
                    waitForAppUpdate({
                        waitForAppUpdate: wListUrl.length > 0 ? true : false,
                    })
                );
            }
        });
    }
}
