import { EndpointTypes } from '@/shared/enums';
import { IEndpointConfiguration } from '@/shared/interfaces';

export const TEST_ENDPOINT_CONFIGURATION_DATA: IEndpointConfiguration = {
    update_type: 'simple',
    auto_answer: true,
    onscreen_keyboard: false,
    disable_peripheral_snapshots: false,
    peripheral_recordings: true,
    enable_error_logs: true,
    enable_info_logs: true,
    fecc_enabled: false,
    rect_enabled: false,
    AudioOutputDefaultVolume: 30.43,
    AudioInputDefaultGain: 50,
    disable_case_creation: false,
    enable_turn_off_display: true,
    turn_off_display_timeout: 10,
    enable_screensaver: false,
    enable_settings_pin: true,
    screensaver_timeout: 10,
    screensaver_pin: '',
    settings_pin: '1111',
    RingtoneDefaultVolume: 20,
    fleet_url: 'https://fleet-api-devripley.avizia.com/',
    endpoint_type_id: EndpointTypes.ApgarCodec,
    turn_off_display: false,
};
