import { IPeripherals } from '@shared/interfaces';
import {
    Dermatoscope,
    horusScope2,
    Otoscope,
    Stethoscope,
    ExamCamera,
    RnkStethoscope,
} from './peripherals-steps';

// WARNING!
// For local development only
const localLabelReplacement = {
    fireflyOtoscope: '', // From mac usb label - USB 2.0 Camera (21cd:0802)
    fireflyDermatoscope: '',
    fireflyExamCamera: '',
    horusScope: '',
    stethoscope1: '', // Should be label of audioinput device
    stethoscope2: '',
    stethoscopeRNK: '',
};

export const PERIPHERALS: IPeripherals[] = [
    {
        productName: 'uvc',
        title: 'Firefly Otoscope',
        productId: 24635,
        vendorId: 8653,
        image: '/assets/images/peripherals-devices/avizia_firefly_otoscope.png',
        canTakeSnapshots: true,
        getLabel(): string {
            return (
                localLabelReplacement.fireflyOtoscope ||
                `${this.vendorId.toString(16)}:${this.productId
                    .toString(16)
                    .padStart(4, '0')}`
            );
        },
        helpSteps: Otoscope,
    },
    {
        productName: 'uvc',
        title: 'Firefly Dermatoscope',
        productId: 28731,
        vendorId: 8653,
        image: 'assets/images/peripherals-devices/avizia_firefly_dermatoscope.png',
        canTakeSnapshots: true,
        getLabel(): string {
            return (
                localLabelReplacement.fireflyDermatoscope ||
                `${this.vendorId.toString(16)}:${this.productId
                    .toString(16)
                    .padStart(4, '0')}`
            );
        },
        helpSteps: Dermatoscope,
    },
    {
        productName: 'uvc',
        title: 'Firefly Exam Camera',
        productId: 2050,
        vendorId: 8653,
        image: 'assets/images/peripherals-devices/avizia_firefly_exam_camera.png',
        canTakeSnapshots: true,
        getLabel() {
            return (
                localLabelReplacement.fireflyDermatoscope ||
                `${this.vendorId.toString(16)}:${this.productId
                    .toString(16)
                    .padStart(4, '0')}`
            );
        },
        helpSteps: ExamCamera,
    },
    {
        productName: 'Camera',
        title: 'Horus scope',
        productId: 258,
        vendorId: 7531,
        getLabel(): string {
            return (
                localLabelReplacement.horusScope ||
                `${this.vendorId.toString(16)}:${this.productId
                    .toString(16)
                    .padStart(4, '0')}`
            );
        },
        helpSteps: horusScope2,
        image: 'assets/images/peripherals-devices/avizia_horus_scope_series_2.png',
        canTakeSnapshots: true,
    },
    {
        productName: 'Stethoscope',
        title: 'Stethoscope',
        productId: 3468,
        vendorId: 32,
        getLabel(): string {
            // return '0d8c:0020';
            // Temp fix - enumeratedevice does not show pidvid for this device.
            return localLabelReplacement.stethoscope1 || 'ATR2USB';
        },
        helpSteps: Stethoscope,
        image: 'assets/images/peripherals-devices/avizia_stethoscope_device.png',
        recording: true,
    },
    {
        productName: 'Stethoscope',
        title: 'Stethoscope',
        productId: 10688,
        vendorId: 2235,
        getLabel(): string {
            return localLabelReplacement.stethoscope2 || 'PCM2900';
        },
        helpSteps: Stethoscope,
        image: 'assets/images/peripherals-devices/avizia_stethoscope_device.png',
        recording: true,
    },
    {
        productName: 'RNK Steth',
        title: 'Stethoscope',
        vendorId: 3468,
        productId: 308,
        getLabel() {
            return localLabelReplacement.stethoscopeRNK || 'PCP-USB';
        },
        helpSteps: RnkStethoscope,
        image: 'assets/images/peripherals-devices/rnk-steth.png',
        recording: true,
    },
];
