import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import { Injectable } from '@angular/core';
import { IIpcResponseHandler } from '../../interfaces';
import { SessionService } from '../session';

@Injectable({ providedIn: 'root' })
export class AudioResponseService implements IIpcResponseHandler {
    constructor(private _sessionService: SessionService) {}
    handleResponse(response): void {
        const { audio: audioResponse } = response;

        if (
            audioResponse.command === SYSTEM_COMMANDS_TYPES.AUDIO_GET_CONTROLS
        ) {
            this._sessionService.deviceInstance.audioDevices =
                audioResponse.play_capture_devices;
        } else if (
            response.audio.command === SYSTEM_COMMANDS_TYPES.AUDIO_MUTE_CONTROL
        ) {
            // no action required
        } else if (
            response.audio.command ===
            SYSTEM_COMMANDS_TYPES.AUDIO_CHANGE_CONTROL_VOLUME
        ) {
            // no action required
        }
    }
}
