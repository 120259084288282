export const ExamCamera = [
    `
    <div class="hslide-top">
        <i class=" slide-icon rotate180 material-icons">forward</i>
    </div>
    <div class="hslide-bottom">
        <h3>Click Share to start sharing the video</h3>
        <span class="hslide-step">1 of 6</span>
    </div>
    `,
    `
    <div class="long-descr hslide-top items-start">
    <img class="" src="/assets/images/peripherals-devices/avizia_firefly_exam_camera_brightness.png" alt="">
    </div>
    <div class="hslide-bottom">
        <h3>Click the brightness icon to select light intensity</h3>
        <span class="hslide-step">2 of 6</span>
    </div>
    `,
    `
    <div class="long-descr hslide-top items-start">
    <img class="" src="/assets/images/peripherals-devices/avizia_firefly_exam_camera_focus.png" alt="">
    </div>
    <div class="hslide-bottom">
        <h3>Turn the metallic knob to the left and right
            <br>to focus the image
        </h3>
        <span class="hslide-step">3 of 6</span>
    </div>
    `,
    `
    <div class="long-descr hslide-top items-start">
    <img class="" src="/assets/images/peripherals-devices/avizia_firefly_exam_camera_polarization.png" alt="">
    </div>
    <div class="hslide-bottom">
        <h3>Rotate the polarization ring to adjust the
            <br>polarization on the device
        </h3>
        <span class="hslide-step">4 of 6</span>
    </div>
    `,
    `
    <div class="long-descr hslide-top items-start">
    </div>
    <div class="hslide-bottom">
        <h3>Click the Snapshot button on the interface
            <br>to upload a screenshot to this case
        </h3>
        <span class="hslide-step">5 of 6</span>
    </div>
    `,
    `
    <div class="long-descr hslide-top items-start">
    </div>
    <div class="hslide-bottom">
        <h3>Click End Share to stop sharing</h3>
        <span class="hslide-step">6 of 6</span>
    </div>
    `,
];
