import { Injectable } from '@angular/core';
import { SnackbarService } from '@aw-hospital/aw-components-lib/src/services/snackbar';

@Injectable({
    providedIn: 'root',
})
export class SnackbarNotificationService {
    constructor(private _snackbarService: SnackbarService) {}

    show(options) {
        this._snackbarService.show(options);
    }

    showDanger(message, options: any = {}) {
        if (!options.style) {
            options.style = 'danger';
        }
        options.description = message;
        this.show(options);
    }
}
