import { DeviceBase } from './device-base';
import {
    AUDIO_DEVICES,
    AUDIO_DEVICE_TYPE,
    FECC_DEVICES,
    FECC_POSITION,
} from '../constants';
import { IBatteryStatus } from '../interfaces';

// eslint-disable-next-line import/no-extraneous-dependencies
import meanBy from 'lodash/meanBy';

export class AmwellCart500 extends DeviceBase {
    private _connectedVendorId: '';
    private _connectedProductId: '';
    private _done: any;

    init(): void {
        if (this._done) {
            return;
        } // Onboot can be called more than once,  check already executed
        this._done = true;
        super.init();
        this.setWorksurfaceLights();
        this.getSystemInfo();
    }

    getSystemInfo(): void {
        this._ipcService.requestGetSerailNumber();
        this._ipcService.setLEDRingOn();
        this._ipcService.requestNerveGetDetected();
        this._ipcService.pollAudioJackStatus();
    }

    setWorksurfaceLights(): void {
        if (this._sessionService.getConfig().worksurface_lights) {
            if (
                this._sessionService.getConfig().worksurface_lights_selected ===
                'Automatic'
            ) {
                this._ipcService.setLEDWorkSurfaceAuto();
            } else if (
                this._sessionService.getConfig().worksurface_lights_selected ===
                'Always On'
            ) {
                this._ipcService.setLEDWorkSurfaceOn();
            }
        } else {
            this._ipcService.setLEDWorkSurfaceOff();
        }
    }

    onConfigUpdate(): void {
        // Config Changed
        // ignore if cart in in standby state
        if (!this._standbyState) {
            this.setWorksurfaceLights();
        }
        this.setCodecVersion();
    }

    resetState(): void {
        // go to privacy mode
        this._ptzService.setFeccPosition(FECC_POSITION.PRIVACY);
    }

    setCodecVersion(): void {
        let eptz = FECC_DEVICES.find((d) => d.name === 'EPTZ Camera');
        const ptzDevice = {
            camera_connected: +false,
            codec_ver: eptz.codecVersion,
        };

        for (const device of this._usbDevices) {
            if (
                this._sessionService.getConfig() &&
                this._sessionService.getConfig().VideoInput &&
                this._sessionService
                    .getConfig()
                    .VideoInput.indexOf(device.product) > -1
            ) {
                const feccCamera = FECC_DEVICES.find(
                    (d) =>
                        d.productId === device.pid &&
                        d.vendorId === device.vid &&
                        d.v4lName.indexOf(device.product) > -1
                );
                if (feccCamera) {
                    ptzDevice.camera_connected = +true;
                    ptzDevice.codec_ver = feccCamera.codecVersion;
                    eptz = feccCamera;
                    break;
                }
            }
        }

        this._ptzService.changeCamera(eptz);
        this._metricService.enqueue(ptzDevice);
        this._loggerService.info('setCodecVersion', JSON.stringify(ptzDevice));
    }

    getDefaultDevice(device, deviceType): any {
        let defaultDevice = null;
        if (typeof device === 'string') {
            defaultDevice = this.audioDevices.find(
                (a) => a.device_type === deviceType && a.description === device
            );
        } else {
            defaultDevice = this.audioDevices.find(
                (d) =>
                    d.vid === device.vendorId &&
                    d.pid === device.productId &&
                    d.device_type === deviceType
            );
        }

        return defaultDevice;
    }

    setMicGain(input): void {
        // This condition for RemoteAVSettings from Clinical Module.
        // We are taking the previously set Vendor & ProductId since we dont get it through fleet
        /*********/
        if (!input.vendorId) {
            input.vendorId = this._connectedVendorId;
        }
        if (!input.productId) {
            input.productId = this._connectedProductId;
        }
        /*********/

        const defaultDevice = this.getDefaultDevice(
            input,
            AUDIO_DEVICE_TYPE.SOURCE
        );
        if (defaultDevice) {
            defaultDevice.volume =
                input.volume >= 0
                    ? input.volume
                    : this._sessionService.getConfig().AudioInputDefaultGain !==
                      undefined
                    ? this._sessionService.getConfig().AudioInputDefaultGain
                    : 50;
            super.setMicGain(defaultDevice);
        }

        this._connectedProductId = input.productId;
        this._connectedVendorId = input.vendorId;
    }

    setSpeakerGain(input): void {
        // This condition for RemoteAVSettings from Clinical Module.
        // We are taking the previously set Vendor & ProductId since we dont get it through fleet
        /*********/
        if (!input.vendorId) {
            input.vendorId = this._connectedVendorId;
        }
        if (!input.productId) {
            input.productId = this._connectedProductId;
        }
        /*********/
        const defaultDevice = this.getDefaultDevice(
            input,
            AUDIO_DEVICE_TYPE.SINK
        );
        if (defaultDevice) {
            if (
                AUDIO_DEVICES.XMOS_SPEAKER.pid === input.productId &&
                AUDIO_DEVICES.XMOS_SPEAKER.vid === input.vendorId
            ) {
                defaultDevice.volume = Math.round(input.volume * 0.56); // scale down volume
            } else {
                defaultDevice.volume = input.volume; // scale down volume
            }
            super.setSpeakerGain(defaultDevice);
        }

        this._connectedProductId = input.productId;
        this._connectedVendorId = input.vendorId;
    }

    setAudioDefaultSink(device) {
        const defaultDevice = this.getDefaultDevice(
            device,
            AUDIO_DEVICE_TYPE.SINK
        );
        if (defaultDevice) {
            const { ctrl_name: ctrlName } = defaultDevice;
            this._ipcService.setAudioDefaultSink(ctrlName);
        }
    }

    setAudioDefaultSource(device) {
        const defaultDevice = this.getDefaultDevice(
            device,
            AUDIO_DEVICE_TYPE.SOURCE
        );
        if (defaultDevice) {
            const { ctrl_name: ctrlName } = defaultDevice;
            this._ipcService.setAudioDefaultSource(ctrlName);
        }
    }

    handleBatteryResponse(response): IBatteryStatus {
        const powerStatus: IBatteryStatus = {};
        if (response.batteries && response.batteries.length > 0) {
            // take average of all batteries and round off
            powerStatus.level = Math.round(
                meanBy(response.batteries, (battery: any) => {
                    if (battery.name) {
                        return parseInt(battery.percent_charge, 0);
                    }
                })
            );
            // round off battery 97% and above to 100%
            if (powerStatus.level > 96) {
                powerStatus.level = 100;
            }
            powerStatus.batteryConnected = true;
        } else {
            powerStatus.batteryConnected = false;
        }

        powerStatus.acPowerConnected =
            response.ac_connected === 'true' ? true : false;

        this._metricService.enqueue({
            ac_power_connected: Number(powerStatus.acPowerConnected),
            battery_connected: Number(powerStatus.batteryConnected),
            battery: powerStatus.level,
        });
        return powerStatus;
    }

    changeCodecVersion(device): void {
        const eptz = FECC_DEVICES.find((d) => d.name === 'EPTZ Camera');
        const ptzDevice = {
            camera_connected: +true,
            codec_ver: eptz.codecVersion,
        };

        // converting hexadecimal and compare
        const feccCamera = FECC_DEVICES.find(
            (d) =>
                parseInt(d.productId, 16) === parseInt(device.productId, 16) &&
                parseInt(d.vendorId, 16) === parseInt(device.vendorId, 16) &&
                device.label.toLowerCase().indexOf(d.v4lName.toLowerCase()) > -1
        );
        if (feccCamera) {
            ptzDevice.codec_ver = feccCamera.codecVersion;
            this._ptzService.changeCamera(feccCamera);
        }
        this._metricService.enqueue(ptzDevice);
    }
}
