/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, createReducer, on } from '@ngrx/store';
import { IBatteryStatus } from '@shared/interfaces';
import * as BatteryActions from './battery.actions';

export const batteryInitialState: IBatteryStatus = null;

const reducer = createReducer(
    batteryInitialState,
    on(BatteryActions.updateBatteryStatus, (state, payload) => ({
        ...state,
        ...payload.batteryStatus,
    }))
);

export function BatteryReducer(
    state: IBatteryStatus | undefined,
    action: Action
): IBatteryStatus {
    return reducer(state, action);
}
